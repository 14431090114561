import { Tab, Tabs } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import Row from 'src/components/Row';
import { Body } from 'src/components/UI';
import { useYourFiles } from 'src/lib/contexts/YourFilesContext';

interface IFileTabProps {
    countData: { docsCount: number; photoCount: number };
}

function FileTypeTabs({ countData }: IFileTabProps) {
    const { selectedFileTypeTab, handleTabChange, fileListLoading } =
        useYourFiles();

    const fileTypeTabs = [
        {
            text: 'Document',
            count: countData.docsCount,
        },
        {
            text: 'Photo',
            count: countData.photoCount,
        },
    ];
    // console.log('countData()', countData());
    return (
        <Tabs
            value={selectedFileTypeTab}
            onChange={(e, newValue) => handleTabChange(newValue)}
            aria-label="offer tab"
        >
            {fileTypeTabs.map((tab, index) => (
                <Tab
                    key={tab.text}
                    label={
                        <Row gap="5px">
                            <>
                                <Body size={14} weight="bold">
                                    {tab.text}
                                </Body>
                                {fileListLoading ? (
                                    <Skeleton
                                        variant="circle"
                                        width={20}
                                        height={20}
                                    />
                                ) : (
                                    <Body size={14} weight="bold">
                                        {`(${tab.count})`}
                                    </Body>
                                )}
                            </>
                        </Row>
                    }
                    style={{
                        width: '50%',
                        ...(selectedFileTypeTab !== index && {
                            borderBottom: '3px solid white',
                        }),
                    }}
                />
            ))}
        </Tabs>
    );
}

export default FileTypeTabs;
