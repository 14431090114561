import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import COLORS from 'src/lib/colors';
import IconInterface from './IconInterface';

function IconUploadFile({
    width = 54,
    height = 40,
    fillColor = COLORS.linkText,
}: IconInterface) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 54 40"
            fill="none"
        >
            <path
                d="M10.5429 13.3625L10.8371 13.2955L10.9149 13.0039C11.9101 9.27381 13.8947 6.26423 16.8742 3.95788C19.8547 1.65082 23.2242 0.5 27 0.5C31.653 0.5 35.5844 2.14459 38.8239 5.44405C42.0637 8.74387 43.6818 12.7525 43.6818 17.5V17.9444L44.1232 17.9965C46.8223 18.3153 49.0506 19.4947 50.8303 21.5464C52.6117 23.6001 53.5 25.9938 53.5 28.75C53.5 31.7468 52.4756 34.2765 50.4216 36.3685C48.368 38.4601 45.8887 39.5 42.9545 39.5H29.4545C28.2412 39.5 27.2134 39.0661 26.3443 38.181C25.4749 37.2955 25.0455 36.2443 25.0455 35V22.125V20.9292L24.1943 21.7691L20.6238 25.292L17.8825 22.5L27 13.2137L36.1175 22.5L33.3762 25.292L29.8057 21.7691L28.9545 20.9292V22.125V35V35.5H29.4545H42.9545C44.8047 35.5 46.3862 34.8435 47.6681 33.5378C48.9497 32.2325 49.5909 30.6261 49.5909 28.75C49.5909 26.8739 48.9497 25.2675 47.6681 23.9622C46.3862 22.6565 44.8047 22 42.9545 22H39.7727V17.5C39.7727 13.9125 38.526 10.8382 36.0397 8.30595C33.5532 5.77335 30.5306 4.5 27 4.5C23.4694 4.5 20.4468 5.77335 17.9603 8.30595C15.5903 10.7198 14.3466 13.6261 14.2355 17H13.5C10.9917 17 8.83855 17.9091 7.06822 19.7122C5.29819 21.515 4.40909 23.7037 4.40909 26.25C4.40909 28.7963 5.29819 30.985 7.06822 32.7878C8.83855 34.5909 10.9917 35.5 13.5 35.5H19.1364V39.5H13.5C9.9094 39.5 6.85551 38.2398 4.31059 35.708C1.76825 33.1788 0.5 30.0989 0.5 26.4375C0.5 23.2941 1.4272 20.5046 3.28285 18.0517C5.13451 15.6039 7.54851 14.0447 10.5429 13.3625Z"
                fill={fillColor}
                stroke="white"
            />
        </svg>
    );
}

export default IconUploadFile;
