import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconCamera({
    width = 16,
    height = 16,
    fillColor = 'white',
}: IconInterface) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
        >
            <path
                d="M8.95224 12.6882C6.81853 12.6882 5.08228 10.9524 5.08228 8.81821C5.08228 6.68406 6.81809 4.94824 8.95224 4.94824C11.0864 4.94824 12.8222 6.68406 12.8222 8.81821C12.8222 9.06091 12.6252 9.25788 12.3825 9.25788C12.1398 9.25788 11.9429 9.06091 11.9429 8.81821C11.9429 7.16945 10.6014 5.82758 8.95224 5.82758C7.30304 5.82758 5.96161 7.16901 5.96161 8.81821C5.96161 10.4674 7.30304 11.8088 8.95224 11.8088C9.19494 11.8088 9.39191 12.0058 9.39191 12.2485C9.39191 12.4912 9.19494 12.6882 8.95224 12.6882Z"
                fill="white"
                stroke="white"
                strokeWidth="0.25"
            />
            <path
                d="M12.0348 13.816C11.7921 13.816 11.5951 13.619 11.5951 13.3763V10.4437C11.5951 10.201 11.7921 10.004 12.0348 10.004C12.2775 10.004 12.4744 10.201 12.4744 10.4437V13.3763C12.4744 13.619 12.2775 13.816 12.0348 13.816Z"
                fill="white"
                stroke="white"
                strokeWidth="0.25"
            />
            <path
                d="M13.501 12.3497H10.5685C10.3258 12.3497 10.1288 12.1527 10.1288 11.91C10.1288 11.6673 10.3258 11.4703 10.5685 11.4703H13.501C13.7437 11.4703 13.9407 11.6673 13.9407 11.91C13.9407 12.1527 13.7437 12.3497 13.501 12.3497Z"
                fill="white"
                stroke="white"
                strokeWidth="0.25"
            />
            <path
                d="M14.5818 16.0371H3.41818C2.08466 16.0371 1 14.9525 1 13.6189V4.91349C1 3.57998 2.08466 2.49531 3.41818 2.49531H5.14256L6.18722 1.45066C6.47784 1.16004 6.86431 1 7.2754 1H10.7242C11.1353 1 11.5217 1.16004 11.8123 1.45066L12.857 2.49531H14.5814C15.9149 2.49531 16.9996 3.57998 16.9996 4.91349V13.6189C16.9996 14.9525 15.9149 16.0371 14.5814 16.0371H14.5818ZM3.41818 3.37465C2.56962 3.37465 1.87934 4.06493 1.87934 4.91349V13.6189C1.87934 14.4675 2.56962 15.1578 3.41818 15.1578H14.5818C15.4304 15.1578 16.1207 14.4675 16.1207 13.6189V4.91349C16.1207 4.06493 15.4304 3.37465 14.5818 3.37465H12.8574C12.6227 3.37465 12.4019 3.2832 12.2357 3.11701L11.1907 2.07191C11.0662 1.94749 10.9005 1.8789 10.7242 1.8789H7.2754C7.09909 1.8789 6.93377 1.94749 6.80891 2.07191L5.76426 3.11657C5.59806 3.28276 5.37735 3.37421 5.14256 3.37421H3.41818V3.37465Z"
                fill="white"
                stroke="white"
                strokeWidth="0.25"
            />
        </svg>
    );
}

export default IconCamera;
