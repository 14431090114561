/* eslint-disable max-len */
const baseURL: string = (() => {
    // {baseURL}
    if (process.env.REACT_APP_PUBLIC_API_URL) {
        return process.env.REACT_APP_PUBLIC_API_URL;
    }
    // If base url is not found we force an error
    throw new Error('Base URL not found');
})();

// USER
const userBaseURL = `${baseURL}/cfma-user/v1`;
export const userAppVersionCheckURL = `${baseURL}/userexternal/v1/extappversion/pwa`;
export const rewardsUserEmailLookupURL = `${baseURL}/userexternal/v1/profilelookup/{email}`;
export const userGetCurrentInfoURL = `${userBaseURL}/me`;
// wallet
export const walletUserChamberMemberURL = `${userBaseURL}/customerPriceTypes/%rewardsNumber%`;
export const walletReportFraudURL = `${userBaseURL}/report-fraud-cta`;
// application Pop Up API
export const userAppMessageURL = `${userBaseURL}/appmessage/PWA`;
export const userUpdateAppMessageURL = `${userBaseURL}/appmessageaction`;
// gathering Firebase token
export const userFirebaseTokenURL = `${userBaseURL}/fcm-token`;
export const userValidateFirebaseTokenURL = `${userBaseURL}/fcm-validatetoken/%token%`;
// home screen carousel
export const userHomeCarouselsURL = `${userBaseURL}/carousels/PWA`;
// protection Plans
export const userProtectionPlanURL = `${userBaseURL}/getespdata`;
// Shipping Services
export const userShippingRatesURL = `${userBaseURL}/shipmentrates`;
export const userPartialShipmentURL = `${userBaseURL}/partialshipment`;
export const userShipmentAddressValidationURL = `${userBaseURL}/addressValidation`;
export const userShipmentListDetailsURL = `${userBaseURL}/shipmentlist`;
// rewards enrollment
export const userEnrollmentOnboardingDoneURL = `${userBaseURL}/isonboardingcoachscreen`;

// REWARDS
const rewardsBaseURL = `${baseURL}/cfma-rewards/v1`;
export const rewardsMaskedAccountURL = `${rewardsBaseURL}/classroom/account`;
export const rewardsGetUserEmailURL = `${rewardsBaseURL}/rewardsemail`;
export const rewardsLinkAccountURL = `${rewardsBaseURL}/classroom/me/link`;
export const rewardsSignUpURL = `${rewardsBaseURL}/classroom/rewards/account`;
export const rewardsDonationInstitutionURL = `${rewardsBaseURL}/classroom/donation/institution`;
export const rewardsDonationEducatorURL = `${rewardsBaseURL}/classroom/donation/educator`;
export const rewardsClassroomActivityURL = `${rewardsBaseURL}/classroom/activity`;
export const rewardsGetFavoritesURL = `${rewardsBaseURL}/classroom/favorites`;
export const rewardsPostFavoritesURL = `${rewardsBaseURL}/classroom/favorite`;
export const rewardsPostLoyaltyAccountURL = `${rewardsBaseURL}/rewards/loyaltyaccount`;
export const rewardsUpdateUserAccountTypeURL = `${rewardsBaseURL}/rewards/updateaccounttype`;
// export const rewardsGetLoyaltyOffersURL = `${rewardsBaseURL}/loyaltyoffers/%offset%/%limit%`;
export const rewardsGetLoyaltyOffersURL = `${rewardsBaseURL}/loyaltyalloffers`;
export const rewardsGetFeaturedLoyaltyOffersURL = `${rewardsBaseURL}/featuredloyaltyoffers`;
export const rewardsActivateLoyaltyOffersURL = `${rewardsBaseURL}/loyaltyactivateoffers`;
export const rewardsGetCouponDataURL = `${rewardsBaseURL}/usercoupons/%rewardsnumber%`;
// purchase history
export const rewardsGetPurchaseHistory = `${rewardsBaseURL}/purchasedetails`;
export const rewardsGetTransactionDetails = `${rewardsBaseURL}/purchasetransdetails`;
// wallet
export const walletAccountTypeURL = `${rewardsBaseURL}/accounttype`;
export const walletGetStaplesRewardsByIdURL = `${rewardsBaseURL}/usercoupons/%rewardId%`;
export const walletLinkAccountURL = `${rewardsBaseURL}/classroom/rewards/link`;
export const walletSignUpURL = `${rewardsBaseURL}/classroom/rewards/enroll`;
// Loyalty enroll
export const rewardsEnrollURL = `${rewardsBaseURL}/rewards/loyaltyenroll`;
// digital coupons
export const rewardsSaveCouponURL = `${rewardsBaseURL}/coupons/save`;
export const rewardsSaveScannedCouponURL = `${rewardsBaseURL}/scancoupon`;
// favorite categories (SLP)
export const rewardsGetFavoritesOffersURL = `${rewardsBaseURL}/selectedfavorites`;
export const rewardsManageFavoriteOffersURL = `${rewardsBaseURL}/offer/managefavorite`;
export const rewardsTermsAndConditionsUpdateURL = `${rewardsBaseURL}/rewards/updatetandc`;

// LOCATION
export const locationBaseURL = `${baseURL}/cfma-location/v1`;
export const locationSchoolSearchURL = `${locationBaseURL}/school/search`;
export const locationSchoolDetailsURL = `${locationBaseURL}/school/%schoolId%`;
export const locationSchoolEducatorsURL = `${locationBaseURL}/school/%schoolId%/educators`;
export const locationClaimSchoolURL = `${locationBaseURL}/school/claim`;
export const locationZipCodeValidation = `${locationBaseURL}/zipcode/%zipCode%`;

// LISTS
export const listBaseURL = `${baseURL}/cfma-lists/v1`;
export const listByIdURL = `${listBaseURL}/%listId%`;
// export const REACT_APP_LISTS_API_UPDATE_LIST_URL=`${listBaseURL}/%listId%`
// export const REACT_APP_LISTS_API_DELETE_LIST_URL=`${listBaseURL}/%listId%`
export const listGetAllItemsById = `${listBaseURL}/external/%listId%`;
export const listCreateItemURL = `${listBaseURL}/item`;
export const listModifyItemURL = `${listBaseURL}/item/%itemId%`;
// export const REACT_APP_LISTS_API_DELETE_ITEM_URL=`${listBaseURL}/item/%itemId%`
export const listGetAllFeaturedURL = `${listBaseURL}/featuredlist`;
export const listSaveFeaturedURL = `${listBaseURL}/copy`;
export const listGetSchoolsByZipURL = `${listBaseURL}/external/schools`;
export const listGetBySchoolURL = `${listBaseURL}/external/school/%schoolId%/lists`;
export const listCreateSharedURL = `${listBaseURL}/share`;
export const listSharedBaseURL = `${listBaseURL}/shared`;
export const listGetSharedURL = `${listSharedBaseURL}/%listId%`;
export const listSaveSharedURL = `${listSharedBaseURL}/copy/%listId%`;

export const pointHistoryURL = `${rewardsBaseURL}/loyaltypointshistory`;
// STORE
export const loyaltyRecyclingOfferURL = `${rewardsBaseURL}/loyaltyrecyclingoffers`;

// CONFIGURATION DETAILS

export const configurationBaseURL = `${listBaseURL}/getconfigdetails/%configName%`;


// FILE Upload URL

export const getFileUploadUrl = `${userBaseURL}/pnidamfileuploadpath`;
export const getFileListUrl = `${userBaseURL}/damfiles`;