/* eslint-disable import/prefer-default-export */
/* eslint import/namespace: [2, { allowComputed: false }] */
import axios from 'axios';
import { getFileListUrl, getFileUploadUrl } from 'src/services/urls';
import { GetFileListResponse, GetFileUploadUrlResponse, UploadFileRequest } from 'src/screens/YourFileSection/YourFileTypes';
import axiosInstance from './custom-axios';


export async function uploadFile(request: UploadFileRequest): Promise<any | null> {
    const { url, binary, fileName, md5 } = request
    try {
        const results: any = await axiosInstance.put(
            url, binary, {
            headers: {
                "x-ms-blob-type": 'BlockBlob',
                "x-ms-tags": `Content-Md5=${md5}`,
                "x-ms-meta-FileName": fileName,
                "MetadataUpdated": true,
                "IndexTagUpdated": true,
            },
        }
        );

        console.log(results, "getFileUploadPath")
        return results
    } catch (e) {
        return null;
    }
}

export async function getFileUploadPath(): Promise<GetFileUploadUrlResponse> {

    const results = await axiosInstance.get(
        getFileUploadUrl
    );
    return results.data

}
export async function getFileListData(): Promise<GetFileListResponse> {

    const results = await axiosInstance.get(
        getFileListUrl
    );
    return results.data

}