/* eslint-disable no-useless-computed-key */

import React, { useContext, useEffect, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ROUTES from 'src/lib/routes';
import QRCode from 'react-qr-code';
import useRouter from 'src/hooks/useRouter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { useUserInfo } from 'src/hooks';
import { Body, Button, Heading } from 'src/components/UI';
import { IconCornerSplash, IconStaplesLogo } from 'src/components/Icons';
import useConfigInfo from 'src/hooks/useConfigInfo';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import BrandIframe from 'src/components/BrandIframe';
import COLORS from 'src/lib/colors';
import NavTop from 'src/components/nav-top';
import { isListEnabled } from 'src/lib/api-client/lists';
import { useQuery } from 'react-query';
import ShippingInfoContext from '../../../lib/contexts/shipping-info-context';

const useStyles = makeStyles((theme) => ({
    qrInformationConatiner: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        background: '#FFFFFF',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
    },
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        overflow: 'auto',
    },
    splash: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    topContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '30px',
        width: '100%',
    },
    qrShipmentInformation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '5px',
    },
    qrInformationTextForShiNum: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#1E1E1E',
        marginBottom: 24,
    },
    qrDescription: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#1E1E1E',
        width: '288px',
        marginTop: '30px',
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        width: '100%',
        alignItems: 'center',
    },
    shipMore: {
        background: '#CC0000',
        borderRadius: '500px',
        marginTop: '30px',
        width: '100%',
        padding: '18px 70px',
        '& span': {
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '17px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#ffffff',
        },
    },
    backHomeBtn: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '16px',
        color: '#086DD2',
    },
    shipmentNumberAlign: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#1E1E1E',
        letterSpacing: '0',
        marginBottom: '5px',
    },
    backIcon: {
        fontSize: '1.2rem',
        color: COLORS.whiteSmoke,
    },
    title: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
        color: '#ffffff',
        margin: 'auto',
    },
    cartHeader: {
        display: 'flex',
        width: '100%',
        padding: '25px 20px',
    },
    imgAlign: {
        ['@media (max-width: 360px)']: {
            width: '95%',
        },
        ['@media (max-width: 330px)']: {
            width: '90%',
        },
    },
}));

const CreateShippingQRInformation = ({
    qrCodeValue,
}: {
    qrCodeValue: string;
}) => {
    const classes = useStyles();
    const { history } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const { updateFetchSenderInfo } = useContext(ShippingInfoContext);
    const { isRewardShipperEnable } = useUserInfo();
    const { shortdesc: shippingConfigPoints } = useConfigInfo(
        'ShippingOfferPoints'
    );
    const { setStoreServicesLink } = useTabsUIStateContext();
    const [holidayLink, setHolidayLink] = useState('');

    const { data: HolidayShippingEnabled } = useQuery(
        ['HolidayShippingEnabled'],
        () => isListEnabled('HolidayShipping'),
        {
            refetchOnMount: true,
        }
    );

    const handleShipMorePackages = () => {
        updateFetchSenderInfo(true);
        history.push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=createShipmentSenderInfo',
        });
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'ship more packages',
            element_location: 'qr page',
        });
        localStorage.setItem('clearForm', 'true');
    };
    const handleViewLabel = () => {
        history.push({
            pathname: ROUTES.shippingServicesLabelShipment,
            search: '',
        });
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'view saved labels',
            element_location: 'qr page',
        });
    };
    const handleBackToHome = () => {
        history.push({
            pathname: ROUTES.shippingServices,
        });
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'back to home',
            element_location: 'qr page',
        });
    };
    useEffect(() => {
        analyticsCustomEvent(eventMetricsMap.screen_view, {
            firebase_screen: `shipping_qrcode confirmation`,
            firebase_screen_class: `shipping_qrcode confirmation`,
        });
    }, [qrCodeValue]);

    const handleNavigation = () => {
        setHolidayLink(
            `${process.env.REACT_APP_DOTCOM_URL}/stores/services/holidayshipping`
        );
    };

    const handleClickOnBackBtn = () => {
        setHolidayLink('');
    };

    if (holidayLink && HolidayShippingEnabled?.isenabled) {
        return (
            <Box className={classes.screen}>
                <NavTop
                    withTopScroll
                    bgColor={COLORS.brandRed}
                    bgImageSrc={require('src/assets/bgs/header-bg.svg')}
                    noBorderRadius
                    minHeight="0"
                >
                    <Box className={classes.cartHeader}>
                        <ButtonBase onClick={handleClickOnBackBtn}>
                            <ArrowBackIosRoundedIcon
                                className={classes.backIcon}
                            />
                        </ButtonBase>
                        <Typography className={classes.title}>
                            Holiday Shipping
                        </Typography>
                    </Box>
                </NavTop>
                <BrandIframe
                    title="holidayShippingLink"
                    id="holidayShippingLink-iframe"
                    src={holidayLink}
                    height="calc(100vh - 70px)"
                />
            </Box>
        );
    }
    /* tslint:disable:no-string-literal */
    return (
        <div className={classes.qrInformationConatiner}>
            <span className={classes.splash}>
                <IconCornerSplash height={70} width={107} />
            </span>
            <div className={classes.topContentContainer}>
                <IconStaplesLogo fillColor="fillRed" />
                <div>
                    <Heading
                        type="h3"
                        weight="bold"
                        size={32}
                        color="textPurple"
                        textAlign="textCenter"
                    >
                        All set!
                    </Heading>
                    {isRewardShipperEnable ? (
                        <Body textAlign="textCenter">
                            You'll earn {shippingConfigPoints} points when this
                            ships.
                        </Body>
                    ) : null}
                </div>
                <div className={classes.qrShipmentInformation}>
                    <Typography className={classes.shipmentNumberAlign}>
                        {qrCodeValue}
                    </Typography>
                    <QRCode size={98} value={qrCodeValue} />
                    <Body
                        size={12}
                        weight="bold"
                        lineHeight={16}
                        color="textMediumGray"
                        margin="5px 0 0"
                    >
                        Associate instruction
                    </Body>
                    <Body
                        size={10}
                        weight="light"
                        lineHeight={14}
                        color="textMediumGray"
                    >
                        Scan QR code in the Ship Services PC to start a shipping
                        label.
                    </Body>
                </div>
                <Typography className={classes.qrDescription}>
                    This shipment QR code can be found in under label and
                    tracking. Visit your local Staples® store to process your
                    shipment.
                </Typography>
                <div className={classes.btnContainer}>
                    <ButtonBase
                        className={classes.shipMore}
                        onClick={handleViewLabel}
                    >
                        <span>View labels and tracking</span>
                    </ButtonBase>
                    <Button
                        variant="text"
                        color="textLinkBlue"
                        onClick={handleShipMorePackages}
                    >
                        Create more shipments
                    </Button>
                </div>
            </div>
            {HolidayShippingEnabled?.isenabled ? (
                <ButtonBase
                    style={{ margin: '25px 0px' }}
                    onClick={handleNavigation}
                >
                    <img
                        src={require('src/assets/icons/homepage_carousel_banner.png')}
                        alt="home icon"
                        className={classes.imgAlign}
                    />
                </ButtonBase>
            ) : null}
            <ButtonBase
                className={classes.backHomeBtn}
                onClick={handleBackToHome}
            >
                <span>Back to home</span>
            </ButtonBase>
        </div>
    );
};

export default CreateShippingQRInformation;
