import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconFile({
    width = 14,
    height = 16,
    fillColor = 'white',
}: IconInterface) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 14 16"
            fill="none"
        >
            <path
                d="M11.7383 0H4.48748C4.4815 0 4.47551 0 4.46953 0.000747663C4.46355 0.000747663 4.45832 0.000747663 4.45234 0.00149533C4.40673 0.00448598 4.36262 0.0134579 4.32 0.0276636C4.31477 0.0291589 4.30953 0.0314019 4.3043 0.0336449C4.28411 0.0411215 4.26393 0.0500935 4.24449 0.0598131C4.24374 0.0598131 4.24224 0.0605607 4.2415 0.0613084C4.22131 0.0717757 4.20262 0.084486 4.18393 0.0971963C4.17944 0.100187 4.17495 0.103925 4.16972 0.107664C4.15103 0.121869 4.13308 0.136822 4.11664 0.153271L0.153271 4.11738C0.136822 4.13383 0.121869 4.15178 0.107664 4.17047C0.103925 4.17495 0.100935 4.17944 0.0971963 4.18393C0.0837383 4.20262 0.0717757 4.22131 0.0613084 4.2415C0.0613084 4.24224 0.0605607 4.24374 0.0598131 4.24449C0.0500935 4.26393 0.0411215 4.28336 0.0336449 4.30355C0.0314019 4.30878 0.0299065 4.31402 0.0276636 4.32C0.0201869 4.34093 0.0142056 4.36262 0.00971963 4.38505C0.00971963 4.38505 0.00971963 4.38579 0.00971963 4.38654C0.00523364 4.40822 0.00299065 4.42991 0.00149533 4.45234C0.00149533 4.45832 0.00149533 4.4643 0.000747663 4.47028C0.000747663 4.47626 0 4.48224 0 4.48748V13.9813C0 15.0946 0.90542 16 2.01869 16H11.7383C12.8516 16 13.757 15.0946 13.757 13.9813V2.01869C13.757 0.90542 12.8516 0 11.7383 0ZM12.7103 13.9813C12.7103 14.5174 12.2744 14.9533 11.7383 14.9533H2.01869C1.48262 14.9533 1.04673 14.5174 1.04673 13.9813V5.01084H2.99215C4.10542 5.01084 5.01084 4.10542 5.01084 2.99215V1.04673H11.7383C12.2744 1.04673 12.7103 1.48262 12.7103 2.01869V13.9813Z"
                fill="white"
            />
        </svg>
    );
}

export default IconFile;
