import { Box, Collapse, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { motion } from 'framer-motion';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { Body, Heading } from 'src/components/UI';
import COLORS from 'src/lib/colors';
import CommonModal from 'src/components/CommonModal';
import DialogBox from 'src/components/DialogBox';
import IconCamera from 'src/components/Icons/IconCamera';
import IconFile from 'src/components/Icons/IconFile';
import IconPhoto from 'src/components/Icons/IconPhoto';
import IconUploadFile from 'src/components/Icons/IconUploadFile';
import Row from 'src/components/Row';
import { useGuestMode } from 'src/hooks';
import SearchInput from 'src/components/SearchInput';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { sortByOptions, useYourFiles } from 'src/lib/contexts/YourFilesContext';
import AuthRoutesAsGuest from './components/AuthRoutesAsGuest';
import UploadFileSection from './components/UploadFileSection';
import UploadInput from './components/UploadInput';
import UploadModal from './components/UploadModal';
import { Documents, ScanQr } from './modules';
import FileTypeTabs from './modules/FileTypeTabs';
import PrintingCompleted from './modules/PrintingCompleted';
import TakePhoto from './modules/TakePhoto';
import { DocumentsProps } from './YourFileTypes';

export const docFileTypes =
    '.pdf, .html, .xls, .xlsx, .ppt, .pptx, .doc, .docx, .pub, .txt';
export const imageFileTypes =
    'image/jpeg, image/jpg, image/png, image/gif, image/bmp, image/tiff';

interface IProps {
    uploadVariant?: 'default' | 'desktop';
}

function YourFileSection({ uploadVariant = 'default' }: IProps) {
    const { isGuestModeOn } = useGuestMode();
    const {
        selectedFileTypeTab,
        selectedSortBy,
        searchDocsFile,
        showFileOption,
        openUploadModal,
        fileLoading,
        fileProgress,
        files,
        timeLeft,
        isPrintSuccessDialogOpen,
        documentsList,
        setShowFileOption,
        setSelectedSortBy,
        onCancel,
        togglePrintDialog,
        getFileList,
        isQrScanSuccess,
        isCameraOpen,
        setIsCameraOpen,
        setSearchDocsFile,
    } = useYourFiles();
    const [showSortByList, setShowSortByList] = useState<boolean>(false);
    const [isFixed, setIsFixed] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const isUploadVariantDesktop = uploadVariant === 'desktop';

    const onBack = () => {
        setIsCameraOpen(false);
    };

    const domRefForSortBy = useOutsideClick(() => {
        setShowSortByList(false);
    });
    const domRef = useOutsideClick(() => {
        setShowFileOption(false);
    });

    useEffect(() => {
        getFileList();
    }, []);

    /* eslint-disable consistent-return */
    function handleScroll() {
        const scrollTop = containerRef.current?.scrollTop ?? 0;
        const scanQrBoxHeight = isQrScanSuccess ? 186 : 176;
        setIsFixed(scrollTop > scanQrBoxHeight);
    }
    /* eslint-disable consistent-return */
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);

            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const [counts, setCounts] = useState({ docsCount: 0, photoCount: 0 });

    const filterFunction = (data: DocumentsProps[]) =>
        data.filter((document) =>
            !searchDocsFile.length
                ? document
                : document.properties?.filename
                      ?.toLowerCase()
                      .includes(searchDocsFile.toLowerCase())
        );

    const countData = useCallback(() => {
        const searchFilterDocuments = filterFunction(documentsList);
        const docsCount = searchFilterDocuments
            .filter(
                (file) =>
                    file.properties.filename.includes('doc') ||
                    file.properties.filename.includes('html') ||
                    file.properties.filename.includes('xls') ||
                    file.properties.filename.includes('xlsx') ||
                    file.properties.filename.includes('xlsm') ||
                    file.properties.filename.includes('ppt') ||
                    file.properties.filename.includes('pptx') ||
                    file.properties.filename.includes('pptm') ||
                    file.properties.filename.includes('doc') ||
                    file.properties.filename.includes('docx') ||
                    file.properties.filename.includes('docm') ||
                    file.properties.filename.includes('pub') ||
                    file.properties.filename.includes('txt') ||
                    file.properties.filename.includes('pdf')
            )
            .reduce((sum) => sum + 1, 0);

        const photoCount = searchFilterDocuments
            .filter(
                (file) =>
                    file.properties.filename.includes('jpg') ||
                    file.properties.filename.includes('jpeg') ||
                    file.properties.filename.includes('jpe') ||
                    file.properties.filename.includes('jfif') ||
                    file.properties.filename.includes('gif') ||
                    file.properties.filename.includes('bmp') ||
                    file.properties.filename.includes('tiff') ||
                    file.properties.filename.includes('rtf') ||
                    file.properties.filename.includes('png') ||
                    file.properties.filename.includes('msg')
            )
            .reduce((sum) => sum + 1, 0);

        return { docsCount, photoCount };
    }, [documentsList, searchDocsFile]);

    useEffect(() => {
        const { docsCount, photoCount } = countData();
        setCounts({ docsCount, photoCount });
    }, [countData]);

    const DocumentMemo = useMemo(
        () => <Documents />,
        [selectedSortBy, searchDocsFile]
    );

    const fixedContainer = () => {
        if (isFixed) {
            return {
                position: 'fixed',
                top: isQrScanSuccess ? '122px' : '203px',
                left: '16px',
                right: '16px',
                width: '93%',
            };
        }
        return {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            width: '100%',
        };
    };

    return (
        <>
            <>
                {!isUploadVariantDesktop && <ScanQr />}
                {isUploadVariantDesktop && documentsList.length && (
                    <Box
                        style={{
                            background:
                                'linear-gradient(98deg, #3E009F 0%, #5B0069 153.14%)',
                            // boxShadow: ' 0px 5px 4px 0px rgba(0, 0, 0, 0.10)',
                            marginBottom: '2px',
                            padding: '24px',
                        }}
                    >
                        <Body
                            size={16}
                            weight="normal"
                            lineHeight={20}
                            color="textWhite"
                            textAlign="textCenter"
                        >
                            Return to desktop to continue
                        </Body>
                    </Box>
                )}
                <div
                    ref={containerRef}
                    style={{
                        // height: 'calc(100% - 142px)',
                        overflow: 'auto',
                        marginLeft: '16px',
                        marginRight: '16px',
                        ...(showFileOption && { opacity: 0.4 }),
                    }}
                >
                    {isGuestModeOn && (
                        <Box
                            sx={{
                                my: '20px',
                            }}
                        >
                            <AuthRoutesAsGuest />
                        </Box>
                    )}
                    <Box
                        sx={{
                            mt: '20px',
                        }}
                    >
                        <UploadFileSection
                            fileAccept={
                                selectedFileTypeTab === 1
                                    ? imageFileTypes
                                    : docFileTypes
                            }
                            supportedText={
                                selectedFileTypeTab === 1
                                    ? 'Supported file types: JPG, JPEG, PNG'
                                    : 'Supported file types: PDF, DOC, PPT, EXL'
                            }
                        />
                    </Box>
                    <Box
                        sx={{
                            my: '10px',
                        }}
                    >
                        <SearchInput
                            placeholder="Search images or documents"
                            value={searchDocsFile}
                            onChange={(e) => setSearchDocsFile(e.target.value)}
                        />
                    </Box>
                    <Box
                        sx={{
                            // height: 'calc(100% - 284px)',
                            my: '10px',
                        }}
                    >
                        <Box
                            sx={{
                                ...fixedContainer(),
                                zIndex: 1000,
                                bgcolor: COLORS.lightGrey,
                            }}
                        >
                            <FileTypeTabs countData={counts} />
                        </Box>
                        <Box
                            sx={{
                                height: 'calc(100% - 130px)',
                            }}
                        >
                            <Box
                                sx={{
                                    height: '100%',
                                }}
                            >
                                {(selectedFileTypeTab === 1
                                    ? counts.photoCount !== 0 &&
                                      filterFunction(documentsList).length > 0
                                    : counts.docsCount !== 0 &&
                                      filterFunction(documentsList).length >
                                          0) && (
                                    <Row
                                        alignItems={'center'}
                                        sx={{
                                            justifyContent: 'space-between',
                                            pt: '20px',
                                            pb: '10px',
                                        }}
                                    >
                                        <>
                                            <Heading
                                                type="h5"
                                                weight="normal"
                                                size={16}
                                                lineHeight={20}
                                            >
                                                {selectedFileTypeTab === 1
                                                    ? 'Your photos'
                                                    : 'Your documents'}
                                            </Heading>
                                            <div
                                                ref={domRefForSortBy}
                                                style={{
                                                    position: 'relative',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <Row
                                                    alignItems={'center'}
                                                    gap="5px"
                                                    onClick={() =>
                                                        setShowSortByList(
                                                            !showSortByList
                                                        )
                                                    }
                                                >
                                                    <>
                                                        <Body
                                                            size={14}
                                                            color="textMediumGray"
                                                            weight="light"
                                                            lineHeight={18}
                                                        >
                                                            Sort by
                                                        </Body>

                                                        <img
                                                            src={require('../../assets/icons/icon-chevron-down.svg')}
                                                            height={20}
                                                            alt=""
                                                            className={`dropdown-icon ${
                                                                showSortByList
                                                                    ? 'rotate'
                                                                    : ''
                                                            }`}
                                                        />
                                                    </>
                                                </Row>
                                                {showSortByList && (
                                                    <Box
                                                        sx={{
                                                            position:
                                                                'absolute',
                                                            right: 0,
                                                            zIndex: 1111,
                                                            minWidth: 150,
                                                        }}
                                                    >
                                                        <Collapse
                                                            in={showSortByList}
                                                            style={{
                                                                borderRadius:
                                                                    '10px',
                                                                boxShadow:
                                                                    '0px 3px 8px rgba(0, 0, 0, 0.24) ',
                                                                // border: `1px solid ${COLORS.darkGray}`,
                                                                backgroundColor:
                                                                    'white',
                                                            }}
                                                        >
                                                            <Row
                                                                direction="column"
                                                                gap="10px"
                                                                sx={{
                                                                    px: '10px',
                                                                    py: '15px',
                                                                }}
                                                            >
                                                                <>
                                                                    {sortByOptions.map(
                                                                        (
                                                                            option
                                                                        ) => {
                                                                            const isMatchOption =
                                                                                selectedSortBy ===
                                                                                option;
                                                                            return (
                                                                                <Box
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        setSelectedSortBy(
                                                                                            option
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Body
                                                                                        size={
                                                                                            14
                                                                                        }
                                                                                        color={
                                                                                            isMatchOption
                                                                                                ? 'textBlack'
                                                                                                : 'textMediumGray'
                                                                                        }
                                                                                        weight={
                                                                                            isMatchOption
                                                                                                ? 'bold'
                                                                                                : 'light'
                                                                                        }
                                                                                        lineHeight={
                                                                                            18
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option
                                                                                        }
                                                                                    </Body>
                                                                                </Box>
                                                                            );
                                                                        }
                                                                    )}
                                                                </>
                                                            </Row>
                                                        </Collapse>
                                                    </Box>
                                                )}
                                            </div>
                                        </>
                                    </Row>
                                )}
                                {DocumentMemo}
                            </Box>
                        </Box>
                    </Box>
                </div>
                <Row
                    sx={{
                        pt: '12px',
                        pb: '12px',
                        justifyContent: 'end',
                        position: 'absolute',
                        right: 10,
                        bottom: 10,
                    }}
                >
                    <div
                        ref={domRef}
                        style={{
                            position: 'relative',
                        }}
                    >
                        <IconButton
                            style={{
                                width: showFileOption ? 40 : 70,
                                height: showFileOption ? 40 : 70,
                                background: COLORS.brandRed,
                            }}
                            onClick={() => setShowFileOption(!showFileOption)}
                        >
                            <motion.div
                                style={{
                                    position: 'relative',
                                    width: 40,
                                    height: 40,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {showFileOption ? (
                                    <Close
                                        style={{
                                            color: COLORS.primaryWhite,
                                        }}
                                    />
                                ) : (
                                    <IconUploadFile
                                        fillColor={COLORS.primaryWhite}
                                    />
                                )}
                            </motion.div>
                        </IconButton>
                        {showFileOption && (
                            <Box
                                sx={{
                                    position: 'fixed',
                                    bottom: 65,
                                    right: 10,
                                    width: 170,
                                    height: 154,
                                    bgcolor: 'black',
                                    borderRadius: '12px',
                                    p: '16px',
                                }}
                            >
                                <Row gap="10px" direction="column">
                                    <>
                                        <UploadInput
                                            htmlFor="fileDocs"
                                            iconComponent={<IconFile />}
                                            acceptFileType={docFileTypes}
                                            title="Choose files"
                                            redirect={0}
                                        />
                                        <UploadInput
                                            htmlFor="photos"
                                            iconComponent={<IconPhoto />}
                                            acceptFileType={imageFileTypes}
                                            title="Photo library"
                                            redirect={1}
                                        />
                                        {/* <TakePhoto /> */}
                                        <Row
                                            gap="10px"
                                            sx={{
                                                alignItems: 'center',
                                            }}
                                            onClick={() =>
                                                setIsCameraOpen(true)
                                            }
                                        >
                                            <>
                                                <Box
                                                    sx={{
                                                        minWidth: 34,
                                                        minHeight: 34,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        bgcolor: '#585858',
                                                        borderRadius: '50%',
                                                    }}
                                                >
                                                    <IconCamera />
                                                </Box>
                                                <Body
                                                    color="textWhite"
                                                    lineHeight={20}
                                                    size={16}
                                                >
                                                    Take a photo
                                                </Body>
                                            </>
                                        </Row>
                                    </>
                                </Row>
                            </Box>
                        )}
                    </div>
                </Row>
            </>

            <CommonModal open={openUploadModal}>
                <UploadModal
                    headerText="files"
                    timeLeft={timeLeft}
                    loading={fileLoading}
                    progress={fileProgress}
                    files={files}
                    onCancel={onCancel}
                />
            </CommonModal>
            <DialogBox
                open={isPrintSuccessDialogOpen}
                header="Printing completed"
                isRightButton
                onBack={togglePrintDialog}
            >
                <PrintingCompleted />
            </DialogBox>
            <DialogBox
                open={isCameraOpen}
                header="Photo"
                isRightButton
                onBack={onBack}
                noPedding
            >
                <>
                    <TakePhoto />
                </>
            </DialogBox>
        </>
    );
}

export default YourFileSection;
