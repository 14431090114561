/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState } from 'react';
import {
    Box,
    ButtonBase,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import useRouter from 'src/hooks/useRouter';
import { optimoveEvents } from 'src/screens/optimove';
import { makeSearchString } from 'src/lib/utils/url-utils';
import COLORS from 'src/lib/colors';
import useUserInfo from 'src/hooks/useUserInfo';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { validateUserInfo } from 'src/utils/userUtils';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { GUTTER_WIDTH, GUTTER_WIDTH_SLIM } from 'src/theme';
import axiosInstance from 'src/lib/api-client/custom-axios';
import { loyaltyRecyclingOfferURL } from 'src/services/urls';
import { useGuestMode } from 'src/hooks';
import { User } from 'src/services/types';
import {
    enrollInRewards,
    getLoyaltyRewardPoints,
} from 'src/services/easyRewardsServices';
import ROUTES from 'src/lib/routes';
import FullWidthCard from './cards/full-width-card';
import Shape from '../assets/icons/Shape.svg';
import { Body, Heading, Button } from './UI';
import { RewardsEnrollErrorDialog } from './EasyRewards';

const useStyles = makeStyles({
    recycleVisit: {
        justifyContent: 'center',
        // marginTop: '1rem',
        '& > div': {
            padding: '0 0 0.25rem 0 !important',
        },
    },
    recycleVisitWrapper: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
    },
    recycleVisitTitleWrapper: {
        backgroundColor: '#81C671',
        minHeight: '1.75rem',
        color: 'white',
        textAlign: 'left',
        userSelect: 'none',
    },
    recycleVisitTitle: {
        padding: '0 1rem',
        // fontSize: '1.25rem',
        marginTop: '1.5rem',
        // fontWeight: 700,
        marginBottom: '0.5rem',
    },
    mainTitle: {
        color: COLORS.primaryWhite,
        userSelect: 'none',
    },
    cardWrapper: {
        marginTop: '0.1rem',
        padding: '1rem',
    },
    storeVisit: {
        borderRight: '1px solid lightgray',
    },
    points: {
        color: COLORS.textBlack,
        textAlign: 'center',
        // fontSize: '2.75rem',
        // fontWeight: 700,
        lineHeight: 'normal',
        marginBottom: '-5px',
    },
    digit: {
        padding: '0 !important',
    },
    btnPart: {
        padding: '20px 16px 20px 16px !important',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    recycleIconWrapper: {
        backgroundColor: '#81C671',
        justifyContent: 'center',
        borderRadius: '50%',
        width: '4rem',
        height: '4rem',
        margin: '2rem 0 -3.5rem 0',
        // position: 'absolute',
        textAlign: 'center',
        paddingTop: '0.7rem',
        // backgroundImage: `url(${Shape})`,
        // left: 0,
        // right: 0,
        // top: '-62px',
    },
    titleWrapper: {
        backgroundColor: '#81C671',
        minHeight: '6rem',
        backgroundImage: `url(${Shape})`,
        userSelect: 'none',
    },
    recycleIcon: {
        alignSelf: 'center',
        zIndex: 9,
        width: '2.5rem',
    },
    recycle: {
        // marginLeft: '0.75rem',
        // marginRight: '0.75rem',
        justifyContent: 'center',
        '& div': {
            padding: '0 !important',
        },
    },
    brRadius: {
        borderRadius: '15px 15px 0 0',
    },
    recycleWrapper: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
    },
    title: {
        // color: 'white',
        // fontWeight: 700,
        // fontSize: '1.625rem',
        marginTop: '2.2rem',
        // marginBottom: '1rem',
        marginLeft: '-0.6rem',
        // textAlign: 'center',
        // padding: '0 1rem',
        lineHeight: '1.875rem',
        userSelect: 'none',
    },
    recycleTitle: {
        color: '#086DD2',
        // fontWeight: 500,
        // fontSize: '1rem',
        margin: '1rem 0 0rem 0rem',
        lineHeight: '1.6rem',
        // textAlign: 'center',
    },
    innerWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    recycleTruckIcon: {
        marginTop: '-5rem',
        width: '100%',
    },
    contentWrapper: {
        padding: '0 1rem',
    },
    recycleButton: {
        alignSelf: 'center',
        backgroundColor: 'black',
        color: 'white',
        lineHeight: '2.5rem',
        borderRadius: '2rem',
        width: '100%',
        fontWeight: 500,
        // margin: '1.75rem 0',
        fontSize: '0.95rem',
        letterSpacing: '0',
        userSelect: 'none',
    },
    recycleText: {
        fontSize: '1rem',
        fontWeight: 500,
        color: '#343434',
        marginBottom: '1.25rem',
        marginTop: '0.625rem',
        userSelect: 'none',
    },
    recycleDollerText: {
        fontSize: '2rem',
        fontWeight: 700,
        color: '#CC0000',
        lineHeight: '2.5rem',
    },
    recycleInfoText: {
        // fontSize: '0.875rem',
        // fontWeight: 400,
        color: '#585858',
        lineHeight: '1rem',
        textAlign: 'center',
    },
    learnMore: {
        fontWeight: 500,
        marginRight: GUTTER_WIDTH,
        color: COLORS.brandCyan,
        cursor: 'pointer',
        fontSize: '1rem',
    },
    recycleVisitText: {
        fontSize: '1.2rem',
        fontWeight: 500,
        color: '#343434',
        lineHeight: '1.8rem',
        marginTop: '1rem',
    },
    recycleSubHeadingText: {
        margin: '-10px 0 1rem 1.2rem !important',
        // fontSize: '0.875rem',
        // fontWeight: 400,
        // fontStyle: 'normal',
    },
    numberText: {
        fontSize: '3.5rem',
        fontWeight: 700,
        textAlign: 'center',
        color: '#343434',
        margin: '2rem 0 0 0',
        lineHeight: '3.5rem',
    },
    recycleVisitStoreText: {
        textAlign: 'center',
        marginBottom: 0,
        marginTop: 0,
    },
    recycleTextContainer: {
        // padding: '0 0.4rem !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',
    },
    recycleInfoIcon: {
        marginTop: '1rem',
        marginLeft: '0.5rem',
    },
    localImpactButton: {
        background: '#E0F7E2',
        padding: '0.3rem 0.8rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'row',
        rowGap: '1rem',
        width: '90%',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        marginTop: '1.5rem',
    },
    localText: {
        marginRight: '1rem',
    },
    upIcon: {
        width: '80%',
        marginTop: '3px',
    },
});
export default function RecycleEveryDay(props: any) {
    const {
        recycleData,
        toggleDrawer,
        setStoreRecycleLink,
        toggleLocal,
        toggleJoinFree,
    } = props;
    const { storeVisitsCount } = recycleData || { storeVisitsCount: 0 };
    // const storeVisitsCount = 6;
    const classes = useStyles();
    const recyclingURL = `${process.env.REACT_APP_DOTCOM_URL}/stores/recycling`;
    const rewardcenterURL = `${process.env.REACT_APP_DOTCOM_URL}/grs/rewards/sr/loyaltycenter?openrecycledrawer=yes&closebutton=no`;
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const { isGuestModeOn } = useGuestMode();
    const { history, pathname, push, prevPath } = useRouter();
    const { userInfo, refetchUserInfo } = useUserInfo();
    const { storeVisitedUpdated, isStoreVisited, setGuestModal } =
        useUserUpdateContext();
    const [showRecycledTruck, setShowRecycledTruck] = useState(!isStoreVisited);
    const [loyaltyRewardPoints, setLoyaltyRewardPoints] = useState<
        Record<any, number | string>
    >({});
    const { data: RewardPoints }: any = getLoyaltyRewardPoints();
    const [enrollmentError, setEnrollmentError] = useState<{
        errorCode: string;
        errorDialogMessage: string;
    } | null>(null);

    const { mutateAsync: enroll } = enrollInRewards({
        onSuccess: () => refetchUserInfo(),
    });

    const handleVisit = (e: any) => {
        if (e.target.id === 'learnMore') {
            customEvent(eventMetricsMap.recycle_reward_learnmore);
            analyticsCustomEvent(eventMetricsMap.recycling, {
                click_text: 'learn more',
                element_location: 'stores page',
            });
            optimoveEvents(eventMetricsMap.scm_recycling, {
                click_text: 'learn more',
                element_location: 'stores page',
            });
        } else if (e.target.id === 'nextVisit') {
            customEvent(eventMetricsMap.recycle_plannextvisit);
            analyticsCustomEvent(eventMetricsMap.recycling, {
                click_text: 'plan your next recycling visit',
                element_location: 'stores page',
            });
            optimoveEvents(eventMetricsMap.scm_recycling, {
                click_text: 'plan your next recycling visit',
                element_location: 'stores page',
            });
        } else {
            customEvent(eventMetricsMap.recycle_planfirstvisit);
            analyticsCustomEvent(eventMetricsMap.recycling, {
                click_text: 'plan your first recycling visit',
                element_location: 'stores page',
            });
            optimoveEvents(eventMetricsMap.scm_recycling, {
                click_text: 'plan your first recycling visit',
                element_location: 'stores page',
            });
        }
        setStoreRecycleLink(recyclingURL);
        analyticsCustomEvent(eventMetricsMap.outbound, {
            click_text: 'recycling',
            link_domain: 'staples.com',
            link_url: recyclingURL,
        });
        optimoveEvents(eventMetricsMap.scm_outbound, {
            click_text: 'recycling',
            link_domain: 'staples.com',
            link_url: recyclingURL,
        });
    };
    const handleSignup = () => {
        customEvent(eventMetricsMap.recycle_signintoenroll);
        history.push({
            pathname,
            search: makeSearchString({
                enrollStep: 'step2',
                flowName: 'SRW',
            }),
        });
    };
    const handleJoinFreeClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (isGuestModeOn) {
            setGuestModal({ open: true, closeBehavior: 'onboarding', previousRoute: prevPath });
        } else if (userInfo && validateUserInfo(userInfo)) {
            enroll({
                FirstName: userInfo.firstName,
                LastName: userInfo.lastName,
                PhoneNumber: userInfo.phone,
                EmailId: userInfo.email,
            })
                .then((res: any) => {
                    console.log({ res });
                    if (res?.data?.success) {
                        push(REWARDS_ENROLLMENT_ROUTES.peronalize);
                    } else {
                        analyticsCustomEvent(eventMetricsMap.pop_up, {
                            click_text:
                                'We were unable to enroll you in Easy Rewards at this time.',
                            element_location: 'easy_rewards',
                        });
                        setEnrollmentError({
                            errorCode: res?.data.code,
                            errorDialogMessage: res?.data.msg,
                        });
                    }
                })
                .catch((error) => {
                    analyticsCustomEvent(eventMetricsMap.pop_up, {
                        click_text:
                            'We were unable to enroll you in Easy Rewards at this time.',
                        element_location: 'easy_rewards',
                    });
                    setEnrollmentError({
                        errorCode: error?.response?.data?.code || 'default',
                        errorDialogMessage:
                            error?.response?.data?.msg || 'Please try again',
                    });
                });
        } else {
            push(REWARDS_ENROLLMENT_ROUTES.missingInfo);
        }
        analyticsCustomEvent(eventMetricsMap.stores, {
            click_text: 'join for free',
        });
    };

    const getRecycleCurrentStage = () => {
        let result = (
            <>
                <ButtonBase
                    className={classes.recycleButton}
                    onClick={handleJoinFreeClick}
                /* onClick={async () => {
            push(ROUTES.rewardsWallet)
        }} */
                >
                    Join for free
                </ButtonBase>
            </>
        );
        if (userInfo?.rewardsNumber) {
            result = (
                <>
                    <button
                        className={classes.recycleButton}
                        style={{ marginTop: '1rem' }}
                        type="button"
                        id="recycle-visit"
                        onClick={handleVisit}
                    >
                        Plan your recycling visit
                    </button>
                </>
            );
        }
        return result;
    };
    const getRecycleImage = () => (
        <img
            aria-hidden="true"
            className={classes.recycleTruckIcon}
            src={require('../assets/icons/truckRecycle.gif')}
            alt="recycle truck icon"
        />
    );
    const [localExpnd, setLocalExpnd] = useState(false);
    const toggleLocalImpact = () => {
        console.log('toggleLocal');
        setLocalExpnd(!localExpnd);
        toggleLocal(!localExpnd);
    };
    const handleInfoIconClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        toggleDrawer(true);
    };

    useEffect(() => {
        setLoyaltyRewardPoints(RewardPoints?.[0]?.results?.[0]);
    }, [RewardPoints]);
    useEffect(() => {
        // getLoyaltyRewardPoints();
        if (storeVisitsCount > 0) {
            storeVisitedUpdated();
            setTimeout(() => {
                setShowRecycledTruck(false);
            }, 7500);
        }
    }, []);
    return (
        <>
            <Grid container style={{ padding: '1.25rem 1rem' }}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <div className={classes.recycleIconWrapper}>
                            <img
                                aria-hidden="true"
                                className={classes.recycleIcon}
                                src={require('../assets/icons/iconStoreRecycle.svg')}
                                alt="recycle store icon"
                                id="recycle_icon"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Heading
                            type="h2"
                            weight="bold"
                            size={26}
                            className={classes.title}
                        // id="Recycle-every-day-title"
                        >
                            Recycle every day at Staples
                        </Heading>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {userInfo?.rewardsNumber && storeVisitsCount >= 0 ? (
                        <Typography className={classes.recycleText}>
                            {`Earn ${loyaltyRewardPoints?.points || 'additional'
                                } points on each ink or toner cartridge you recycle, up to ${loyaltyRewardPoints?.maxnumberofcartridgesrecycled ||
                                'a limit'
                                } per month. No purchase required.`}
                        </Typography>
                    ) : (
                        <Typography className={classes.recycleText}>
                            Sign in or join Easy Rewards to earn points on
                            recycling and to track your recycling progress.
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {storeVisitsCount <= 0 ? (
                        <FullWidthCard
                            className={
                                !localExpnd
                                    ? classes.recycle
                                    : `${classes.recycle} ${classes.brRadius}`
                            }
                            id="classroom-rewards-summary-card"
                        >
                            {getRecycleImage()}
                            <section className={classes.btnPart}>
                                {getRecycleCurrentStage()}
                                <div className={classes.recycleTextContainer}>
                                    <Button
                                        variant="text"
                                        className={classes.recycleTitle}
                                        onClick={() =>
                                            setStoreRecycleLink(rewardcenterURL)
                                        }
                                    >
                                        <Body size={16} textAlign="textCenter">
                                            or recycle via our mail-back program
                                        </Body>
                                    </Button>
                                    <img
                                        id="onboarding-swipeable-elem-image"
                                        src={require('../assets/screenshots/infoIcon.svg')}
                                        alt="onboarding introduction"
                                        className={classes.recycleInfoIcon}
                                        onClick={(e) => handleInfoIconClick(e)}
                                    />
                                </div>
                                <section
                                    role="button"
                                    onClick={toggleLocalImpact}
                                    id="localImpactButton"
                                    className={classes.localImpactButton}
                                >
                                    <div className={classes.localText}>
                                        <Body size={14} textAlign="textCenter">
                                            See your local and nationwide impact
                                        </Body>
                                    </div>
                                    <div>
                                        {localExpnd ? (
                                            <img
                                                aria-hidden="true"
                                                className={classes.upIcon}
                                                src={require('../assets/icons/iconChevronUp.svg')}
                                                alt="arrow"
                                            />
                                        ) : (
                                            <img
                                                aria-hidden="true"
                                                className={classes.upIcon}
                                                src={require('../assets/icons/iconChevronDown.svg')}
                                                alt="arrow"
                                            />
                                        )}
                                    </div>
                                </section>
                            </section>
                        </FullWidthCard>
                    ) : (
                        <FullWidthCard
                            hasManualShadow
                            className={classes.recycleVisit}
                            id="Ink-recycle-summary-card"
                        >
                            <div className={classes.recycleVisitWrapper}>
                                <div
                                    className={classes.recycleVisitTitleWrapper}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Heading
                                                type="h3"
                                                weight="bold"
                                                size={20}
                                                className={`${classes.recycleVisitTitle} ${classes.mainTitle}`}
                                            >
                                                Your recycling visits
                                            </Heading>
                                            <Body
                                                size={14}
                                                weight="light"
                                                className={
                                                    classes.recycleSubHeadingText
                                                }
                                            >
                                                in last 12 months
                                            </Body>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Grid
                                    container
                                    spacing={4}
                                    className={classes.cardWrapper}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        className={`${classes.storeVisit} ${classes.digit}`}
                                    >
                                        <Body
                                            size={44}
                                            weight="bold"
                                            className={classes.points}
                                            id="storeVisits-count"
                                        >
                                            {recycleData.storeVisitsCount}
                                        </Body>
                                        <Body
                                            size={14}
                                            weight="light"
                                            className={classes.recycleInfoText}
                                        >
                                            Store visits
                                        </Body>
                                    </Grid>
                                    <Grid item xs={6} className={classes.digit}>
                                        <Body
                                            size={44}
                                            weight="bold"
                                            className={classes.points}
                                            id="inkAndToner-count"
                                        >
                                            {recycleData.inkandTonerCount || 0}
                                        </Body>
                                        <Body
                                            size={14}
                                            weight="light"
                                            className={classes.recycleInfoText}
                                        >
                                            Ink & toners recycled
                                        </Body>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.btnPart}
                                    >
                                        <Button
                                            variant="contained-rounded"
                                            bgColor="bgBlack"
                                            color="textWhite"
                                            fullWidth={true}
                                            onClick={handleVisit}
                                            id="next-recycle-visit"
                                        >
                                            Plan your next recycling visit
                                        </Button>
                                        <div
                                            className={
                                                classes.recycleTextContainer
                                            }
                                        >
                                            <p
                                                className={classes.recycleTitle}
                                                id="Recycle-every-day-title"
                                                onClick={() =>
                                                    setStoreRecycleLink(
                                                        rewardcenterURL
                                                    )
                                                }
                                            >
                                                <Body
                                                    size={14}
                                                    textAlign="textCenter"
                                                >
                                                    or recycle via our mail-back
                                                    program
                                                </Body>
                                            </p>
                                            <img
                                                id="onboarding-swipeable-elem-image"
                                                src={require('../assets/screenshots/infoIcon.svg')}
                                                alt="onboarding introduction"
                                                className={
                                                    classes.recycleInfoIcon
                                                }
                                                onClick={(e) =>
                                                    handleInfoIconClick(e)
                                                }
                                            />
                                        </div>
                                        <section
                                            onClick={toggleLocalImpact}
                                            id="localImpactButton"
                                            className={
                                                classes.localImpactButton
                                            }
                                        >
                                            <div className={classes.localText}>
                                                <Body
                                                    size={16}
                                                    textAlign="textCenter"
                                                >
                                                    See your local and
                                                    nationwide impact
                                                </Body>
                                            </div>
                                            <div>
                                                {localExpnd ? (
                                                    <img
                                                        className={
                                                            classes.upIcon
                                                        }
                                                        src={require('../assets/icons/iconChevronUp.svg')}
                                                        alt="arrow"
                                                    />
                                                ) : (
                                                    <img
                                                        className={
                                                            classes.upIcon
                                                        }
                                                        src={require('../assets/icons/iconChevronDown.svg')}
                                                        alt="arrow"
                                                    />
                                                )}
                                            </div>
                                        </section>
                                    </Grid>
                                </Grid>
                            </div>
                        </FullWidthCard>
                    )}
                </Grid>
            </Grid>
            <RewardsEnrollErrorDialog
                error={enrollmentError}
                setError={setEnrollmentError}
            />
        </>
    );
}
