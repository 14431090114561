import { Box, Modal, ModalProps } from '@material-ui/core'
import React from 'react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    '&:focus-visible': {
        outline: 'none',
    },
};

interface IProps extends ModalProps {
    children: JSX.Element
}

function CommonModal({ children, ...rest }: IProps) {
    return (
        <Modal {...rest} >
            <Box sx={{
                borderRadius: '10px',
                ...style,
            }}>
                {children}
            </Box>
        </Modal>
    )
}

export default CommonModal