/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */

// login-create-new-user.jsx

import React, { ChangeEvent, useRef, useState, FocusEvent } from 'react';
import {
    Tabs,
    Checkbox,
    Tab as MUITab,
    withStyles,
    InputAdornment,
    Box,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LoadingButton from 'src/components/loading-button';
import { Button, Heading, Body, SwipeableDrawer } from 'src/components/UI';
import {
    IconRightBackArrow,
    IconPasswordClose,
    IconPasswordOpen,
    IconVector,
} from 'src/components/Icons';
import { LOGIN_CREATE_NEW_USER } from 'src/utils/stringConstants';
import { createAccount } from 'src/lib/api-client/sign-on';
import {
    useRouter,
    useUserState,
    useEventsReporter,
    eventMetricsMap,
    useGuestMode,
} from 'src/hooks';
import {
    COACH_SCREENS_ROUTES,
    LOGIN_ROUTES,
    REWARDS_ENROLLMENT_ROUTES,
} from 'src/routers/routes';
import InfoButton from 'src/components/info-button';
import { obfuscateUserEmail } from 'src/utils/userUtils';
import Captcha from 'src/components/login-flow/captcha';
import { getCartDetails, mergeCartDetails } from 'src/lib/api-client/cart-api';
import { useCartCountContext } from 'src/lib/contexts/cart-count-context';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { customDomains } from 'src/lib/constants';
import ROUTES from 'src/lib/routes';
import CommonDialog from 'src/components/common-dialog';
import COLORS from 'src/lib/colors';
import { getStplSessionIdUniqueId } from 'src/lib/utils/app-utils';
import FullWidthCard from '../../components/cards/full-width-card';
import ValidationTextField from '../../components/validation-text-field';
import LoadingOverlay from '../../components/loading-overlay';
import styles from './styles.module.scss';

function formatPhoneNumber(phoneNumber: string): string {
    const input = phoneNumber.replace(/\D/g, ''); // Remove all non-digit characters
    let formattedNumber = '';
    // Format the phone number as (123) 134 2627
    if (input.length > 0) {
        formattedNumber += `(${input.substring(0, 3)}`;
    }
    if (input.length > 3) {
        formattedNumber += `) ${input.substring(3, 6)}`;
    }
    if (input.length > 6) {
        formattedNumber += ` ${input.substring(6, 10)}`;
    }

    return formattedNumber; // Return the original input if it doesn't match the expected pattern
}

const Tab = withStyles(() => ({
    root: {
        fontSize: 18,
        textTransform: 'none',
        fontFamily: 'StaplesNormsRegular',
        '&$selected': {
            fontFamily: 'StaplesNormsBold',
        },
    },
    selected: {},
}))((props: { label: string }) => <MUITab {...props} />);

//  eslint-disable-next-line max-len,no-useless-escape
const passwordReg =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-])\S{8,}$/;

const phoneRegex = /^\(\d{3}\)\s\d{3}\s\d{4}/;

const passwordRegxValidationMsg = 'Password does not meet the requirement.';

const validationSchema = yup.object({
    firstName: yup
        .string()
        .matches(
            /^[a-zA-Z0-9\s]*$/,
            'First name cannot contain special characters.'
        )
        .trim()
        .required('First name is required.'),
    lastName: yup
        .string()
        .matches(
            /^[a-zA-Z0-9\s]*$/,
            'Last name cannot contain special characters.'
        )
        .trim()
        .required('Last name is required.'),
    phoneNumber: yup
        .string()
        .required(
            'Phone number is required. We’ll use your phone number to help verify your account in the future.'
        )
        .length(10, 'Phone number is invalid.'),
    // .matches(phoneRegex, 'Please enter valid phone number'),
    email: yup
        .string()
        .required('Email is required.')
        .email('Email is invalid.')
        .transform((value) => value.toLocaleLowerCase()),
    confirmEmail: yup
        .string()
        .required('Confirm email is required.')
        .email('Confirm email is invalid.')
        .transform((value) => value.toLocaleLowerCase())
        .oneOf([yup.ref('email'), null], `Email address doesn't match.`),
    password: yup
        .string()
        .required('Password is required.')
        .matches(passwordReg, passwordRegxValidationMsg),
    confirmPassword: yup
        .string()
        .oneOf(
            [yup.ref('password'), null],
            'Password does not meet the requirement.'
        ),
});

const CreateAccountScreen = ({
    showInModal,
    setNextScreen,
    postLoginBehavior,
}: any) => {
    const { push, replace } = useRouter();
    const [userState, setUserState] = useUserState();
    const { exitGuestMode, isGuestModeOn } = useGuestMode();
    const [tosSource, setTosSource] = useState<string | null>(null);
    const [tosTabIndex, setTosTabIndex] = useState(0);
    const [createUserError, setCreateuserError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const initialRef: any = null;
    const emailAutoCompleteRef = useRef(initialRef);
    const confirmEmailAutoCompleteRef = useRef(initialRef);
    const [isPasswordShow, setPasswordShow] = useState(false);
    const [isTerms, setTermsChecked] = useState(false);
    const [showTabs, setShowTabs] = useState(false);
    const [captchaHtml, setCaptchaHtml] = useState('');
    const [fieldFocus, setFieldFoucs] = useState('');
    const { activeIframeId, updateCartCount } = useCartCountContext();

    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const { setLoginCaptchaResData } = useUserUpdateContext();
    const [showList, setShowList] = useState(false);
    const [showListEmail, setShowListEmail] = useState(false);
    const [queryText, setQuery] = useState('');
    const [createAccountErrorMessage, setCreateAccountErrorMessage] =
        useState('');
    const termsAndConditionAriaLabel = `By creating an account, you agree to Staples and Easy Rewards Terms and Conditions link, 
    Privacy Notice link, and California Notice link. You also agree to receive Staples promotional communications. You may unsubscribe at any time.`;
    const ariaDetails = {
        tabIndexText: 0,
        ariaSelectedText: true,
        ariaLabelText:
            "We'll use your phone number to help verify your account in the future.",
        tabIndexButton: 0,
        ariaSelectedButton: true,
        ariaLabelButton: 'Got it',
    };
    const [validation, setValidation] = useState({
        length: false,
        letter: false,
        specialChar: false,
        number: false,
        noSpaces: false,
    });

    const handleCloseBtn = () => {
        if (!showInModal) {
            push(LOGIN_ROUTES.login);
        } else {
            setNextScreen(LOGIN_ROUTES.login);
        }
    };

    const refreshWebView = () => {
        const documentData = document?.getElementById?.(
            activeIframeId
        ) as HTMLIFrameElement;
        if (activeIframeId && documentData) {
            documentData?.contentWindow?.location?.reload();
        }
    };

    const { values, handleSubmit, handleChange, errors, touched, setTouched } =
        useFormik({
            initialValues: {
                firstName: '',
                lastName: '',
                email: userState?.email?.toLocaleLowerCase() || '',
                confirmEmail: '',
                password: '',
                confirmPassword: '',
                phoneNumber: '',
                shopFor: 'self',
                organization: '',
            },
            validationSchema,
            // enableReinitialize: true,
            validateOnMount: true,
            onSubmit: async (currentValues) => {
                const stplSessionId = getStplSessionIdUniqueId();
                const nPayload = (
                    document?.getElementsByName(
                        'nds-pmd'
                    )[0] as HTMLInputElement
                )?.value;

                const nuCaptchaAnswer = (document.getElementById(
                    'nucaptcha-answer'
                ) as HTMLInputElement)
                    ? (
                          document.getElementById(
                              'nucaptcha-answer'
                          ) as HTMLInputElement
                      ).value
                    : '';
                const nuCaptchaToken = (document.getElementById(
                    'nucaptcha-token'
                ) as HTMLInputElement)
                    ? (
                          document.getElementById(
                              'nucaptcha-token'
                          ) as HTMLInputElement
                      ).value
                    : '';

                // patchLocalUserInfo({ zipCode: '' })
                console.log(currentValues);
                setLoading(true);

                const requestBody = {
                    user: {
                        username: currentValues.email,
                        contact: {
                            email: currentValues.email,
                            firstName: currentValues.firstName.trim(),
                            lastName: currentValues.lastName.trim(),
                            phone2: currentValues.phoneNumber,
                        },
                        tenant: 'StaplesDotCom',
                    },
                    tenant: 'StaplesDotCom',
                    nudataPayload: '',
                    ndsPageModeData: nPayload,
                    password: currentValues.password,
                    requestUrl: 'app/createAccount',
                    accountType: 'consumer',
                    stplSessionId,
                    placement: 'CreateAccountWebSC',
                    channel: 'SWeb',
                    captchaAnswer: nuCaptchaAnswer,
                    nuCaptchaToken,
                    enrollOrigin: 'MOBILE',
                };

                const responseData: any = await createAccount(
                    requestBody,
                    setLoading
                );
                setLoading(false);
                console.log(responseData);
                console.log('🚀 ~ onSubmit: ~ responseData:', responseData);
                const captchaInRes =
                    responseData?.data?.captchaResponse?.captchaResponseHtml;
                if (captchaInRes) {
                    setCaptchaHtml(
                        responseData?.data?.captchaResponse?.captchaResponseHtml
                    );
                }
                if (responseData.status) {
                    analyticsCustomEvent(eventMetricsMap.account_creation, {
                        click_text: 'create account - success',
                        element_location: 'create_an_account',
                        enroll_flow: 'account creation',
                        new_account_biometrics: 'false',
                    });
                    analyticsScreenCustomNames({
                        user_id: responseData?.loginRes?.data?.sub,
                    });
                    setUserState({
                        ...userState,
                        userType: 'new',
                    });

                    if (
                        responseData?.loginRes?.captchaResponse
                            ?.captchaResponseHtml
                    ) {
                        const loginRes = {
                            email: currentValues.email,
                            key: currentValues.password,
                            captcha:
                                responseData?.loginRes?.captchaResponse
                                    ?.captchaResponseHtml,
                        };
                        setLoginCaptchaResData(loginRes);
                        setUserState({
                            ...userState,
                            email: currentValues.email,
                        });
                        push(LOGIN_ROUTES.login);
                    } else {
                        analyticsCustomEvent(eventMetricsMap.login_flow, {
                            click_text: `sign in`,
                            element_location: `new account flow`,
                        });
                        analyticsScreenCustomNames({
                            logged_in: true,
                        });
                        // sessionStorage.removeItem('guest');
                        exitGuestMode();
                        if (showInModal) {
                            await mergeCartDetails();
                            const cartDetails = await getCartDetails(false);

                            const cartCountData =
                                cartDetails?.data?.cartInfo?.cartAttributes
                                    ?.numberOfItems || 0;
                            setNextScreen?.('closeInModal');
                            if (cartCountData) {
                                updateCartCount(cartCountData);
                            }
                            if (activeIframeId) {
                                refreshWebView();
                            }
                            if (postLoginBehavior === 'go-to-cart') {
                                replace(ROUTES.cart);
                            } else if (postLoginBehavior === 'onboarding') {
                                replace(COACH_SCREENS_ROUTES.location);
                            }
                            setNextScreen?.('closeInModal');
                            replace(REWARDS_ENROLLMENT_ROUTES.newAccountIntro);
                        } else {
                            setNextScreen?.('closeInModal');
                            replace(REWARDS_ENROLLMENT_ROUTES.newAccountIntro);
                        }
                    }
                } else {
                    setCreateAccountErrorMessage(responseData?.message || '');
                    analyticsCustomEvent(eventMetricsMap.account_creation, {
                        click_text: 'create account - failure',
                        element_location: 'create an account',
                    });
                }
            },
        });
    console.log({ errors });
    const handleOnBlur = (
        e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
        if (e.target.name) {
            setTouched({ ...touched, [e.target.name]: true });
            // setFieldFoucs('');
        }
    };
    const handleChangeEmail = (val: string) => {
        handleChange({ target: { value: val, name: 'email' } });
    };

    const handlePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
        // Strip out non-digit characters
        const digitsOnly = e.target.value.replace(/\D/g, '');

        // Check if the cleaned input value length is less than or equal to 10
        if (digitsOnly.length <= 10) {
            handleChange({
                target: { value: digitsOnly, name: 'phoneNumber' },
            });
        }
    };

    const handleTerms = (e: ChangeEvent<HTMLInputElement>) => {
        setTermsChecked(e.target.checked);
    };
    const handleSuggetion = (val: any, email = false) => {
        if (val?.includes('@')) {
            if (email) {
                setShowListEmail(true);
            } else {
                setShowList(true);
            }
            const index = val.indexOf('@');
            const domainName = val.substring(index);
            setQuery(domainName);
        } else {
            setShowListEmail(false);
            setShowList(false);
        }
    };
    const getEmailData =
        customDomains.filter((email) =>
            email.toLowerCase().includes(queryText.toLowerCase())
        ) || [];

    const emailList = (
        <ul className={getEmailData?.length ? styles.emailAutoComplete : ''}>
            {getEmailData?.map((email) => (
                // eslint-disable-next-line
                <li
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="link"
                    className={styles.emailItem}
                    onClick={(e) => {
                        // e.preventDefault();
                        setShowListEmail(false);
                        const emailFieldVal = values.email;
                        const index = emailFieldVal.indexOf('@');
                        const clearedEmail = emailFieldVal.substring(0, index);
                        // eslint-disable-next-line
                        handleChange({
                            target: {
                                value: clearedEmail + email,
                                name: 'email',
                            },
                        });
                    }}
                >
                    {values.email.substring(0, values.email.indexOf('@'))}
                    {email}
                </li>
            ))}
        </ul>
    );
    const getConfiemEmail = customDomains.filter((email) =>
        email.toLowerCase().includes(queryText.toLowerCase())
    );
    const emailListConfirm = (
        <ul className={getConfiemEmail?.length ? styles.emailAutoComplete : ''}>
            {getConfiemEmail?.map((email) => (
                // eslint-disable-next-line
                <li
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="link"
                    className={styles.emailItem}
                    onClick={(e) => {
                        // e.preventDefault();
                        const emailFieldVal = values.confirmEmail;
                        const index = emailFieldVal.indexOf('@');
                        const clearedEmail = emailFieldVal.substring(0, index);
                        console.log('🚀 ~ clearedEmail:', clearedEmail + email);
                        // eslint-disable-next-line
                        handleChange({
                            target: {
                                value: clearedEmail + email,
                                name: 'confirmEmail',
                            },
                        });
                        setShowList(false);
                    }}
                >
                    {values.confirmEmail.substring(
                        0,
                        values.confirmEmail.indexOf('@')
                    )}
                    {email}
                </li>
            ))}
        </ul>
    );

    const isEmailFieldError = touched.email && Boolean(errors.email);
    const isConfirmationEmailFieldError =
        touched.confirmEmail && Boolean(errors.confirmEmail);
    const isFirstNameFieldError =
        touched.firstName && Boolean(errors.firstName);
    const isLastNameFieldError = touched.lastName && Boolean(errors.lastName);
    const isPasswordFieldError = touched.password && Boolean(errors.password);
    const isPhoneNumberFieldError =
        touched.phoneNumber && Boolean(errors.phoneNumber);

    const isPasswordChar = values?.password?.length >= 8;
    const isValidChar = /[a-zA-Z]/.test(values?.password);
    const isValidSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(
        values?.password
    );
    const isNoSpace = !/\s/.test(values?.password);
    const isContainNumber = /[0-9]/.test(values?.password);

    return (
        <>
            <CommonDialog
                open={!!createAccountErrorMessage}
                onClose={() => setCreateAccountErrorMessage('')}
                title="We couldn't create an account"
                message={createAccountErrorMessage}
                buttonText="Try again"
            />
            <FullWidthCard
                noPadding
                id="LoginCreateNewUser"
                className={styles.createUserContainer}
            >
                <Button
                    onClick={handleCloseBtn}
                    variant="icon"
                    align="alignSelfStart"
                    className={styles.alignBackBtn}
                    tabIndex={2}
                    role="button"
                    ariaLabel="Go back"
                >
                    <img
                        className={styles.backIcon}
                        src={require('src/assets/icons/iconBackArrow.svg')}
                        alt="iconBack"
                    />
                </Button>
                <p className={styles.createTitle} tabIndex={1}>
                    Create an account
                </p>
                {createUserError ? (
                    <div style={{ color: 'red', textAlign: 'center' }}>
                        {createUserError}
                    </div>
                ) : null}
                <form onSubmit={handleSubmit} className={styles.textContainer}>
                    <div className={styles.fieldContainer}>
                        <label htmlFor="email" className={styles.labelAlign}>
                            Enter your email address
                        </label>
                        <input
                            type="email"
                            aria-label="Email"
                            id="email"
                            name="email"
                            autoComplete="username"
                            aria-required="true"
                            value={values.email}
                            tabIndex={3}
                            onChange={(e) => {
                                handleChange(e);
                                emailAutoCompleteRef?.current?.change(e);
                                handleSuggetion(e.target.value, true);
                            }}
                            onBlur={(e: any) => {
                                handleOnBlur(e);
                            }}
                            onFocus={(e: any) => {
                                setFieldFoucs('email');
                            }}
                            style={{
                                border: isEmailFieldError
                                    ? '1px solid red'
                                    : '1px solid #ccc',
                                boxShadow: isEmailFieldError
                                    ? '0px 0px 0px 4px #CC00001A'
                                    : 'none',
                            }}
                            className={
                                fieldFocus === 'email'
                                    ? styles.inputFieldActive
                                    : styles.inputFieldAlign
                            }
                            required
                        />
                        {fieldFocus === 'email' && showListEmail && emailList}
                        {isEmailFieldError ? (
                            <div
                                style={{
                                    color: COLORS.brandRed,
                                    marginTop: '5px',
                                }}
                                aria-label={`${errors.email} alert`}
                            >
                                {errors.email}
                            </div>
                        ) : null}
                    </div>

                    <div className={styles.fieldContainer}>
                        <label
                            htmlFor="confirmEmail"
                            className={styles.labelAlign}
                        >
                            Confirm email address
                        </label>
                        <input
                            type="email"
                            id="confirmEmail"
                            name="confirmEmail"
                            autoComplete="username"
                            aria-required="true"
                            tabIndex={4}
                            value={values.confirmEmail}
                            aria-label={
                                touched.confirmEmail
                                    ? errors.confirmEmail
                                        ? 'Email is invalid alert.'
                                        : 'Confirm email is a required field.'
                                    : 'Confirm email'
                            }
                            onChange={(e) => {
                                handleChange(e);
                                confirmEmailAutoCompleteRef?.current?.change(e);
                                handleSuggetion(e.target.value);
                            }}
                            onBlur={(e: any) => {
                                handleOnBlur(e);
                            }}
                            onFocus={(e: any) => {
                                // setTouched({ ...touched, confirmEmail: true })
                                setFieldFoucs('confirmEmail');
                            }}
                            style={{
                                border: isConfirmationEmailFieldError
                                    ? '1px solid red'
                                    : '1px solid #ccc',
                                boxShadow: isConfirmationEmailFieldError
                                    ? '0px 0px 0px 4px #CC00001A'
                                    : 'none',
                            }}
                            className={
                                fieldFocus === 'confirmEmail'
                                    ? styles.inputFieldActive
                                    : styles.inputFieldAlign
                            }
                            required
                        />
                        {fieldFocus === 'confirmEmail' &&
                            showList &&
                            emailListConfirm}
                        {isConfirmationEmailFieldError ? (
                            <div
                                style={{
                                    color: COLORS.brandRed,
                                    marginTop: '5px',
                                }}
                                aria-label={`${errors.confirmEmail} alert`}
                            >
                                {errors.confirmEmail}
                            </div>
                        ) : null}
                    </div>
                    <div className={styles.fieldContainer}>
                        <label
                            htmlFor="firstName"
                            className={styles.labelAlign}
                        >
                            First name
                        </label>
                        <input
                            id="firstName"
                            name="firstName"
                            onChange={handleChange}
                            tabIndex={5}
                            aria-required="true"
                            aria-label={
                                errors.firstName && touched.firstName
                                    ? 'First name is a required field.'
                                    : 'First name'
                            }
                            value={values.firstName}
                            autoComplete="username"
                            onBlur={(e: any) => {
                                handleOnBlur(e);
                            }}
                            onFocus={(e: any) => {
                                // setTouched({ ...touched, firstName: true })
                                setFieldFoucs('firstName');
                            }}
                            style={{
                                border: isFirstNameFieldError
                                    ? '1px solid red'
                                    : '1px solid #ccc',
                                boxShadow: isFirstNameFieldError
                                    ? '0px 0px 0px 4px #CC00001A'
                                    : 'none',
                            }}
                            className={
                                fieldFocus === 'firstName'
                                    ? styles.inputFieldActive
                                    : styles.inputFieldAlign
                            }
                            maxLength={15}
                            required
                        />
                        {isFirstNameFieldError ? (
                            <div
                                style={{
                                    color: COLORS.brandRed,
                                    marginTop: '5px',
                                }}
                                aria-label={`${errors.firstName} alert`}
                            >
                                {errors.firstName}
                            </div>
                        ) : null}
                    </div>

                    <div className={styles.fieldContainer}>
                        <label htmlFor="lastName" className={styles.labelAlign}>
                            Last name
                        </label>
                        <input
                            id="lastName"
                            name="lastName"
                            onChange={handleChange}
                            tabIndex={6}
                            aria-required="true"
                            aria-label={
                                errors.lastName && touched.lastName
                                    ? 'LastName name is a required field.'
                                    : 'Last name'
                            }
                            value={values.lastName}
                            onBlur={(e: any) => {
                                handleOnBlur(e);
                            }}
                            onFocus={(e: any) => {
                                // setTouched({ ...touched, lastName: true })
                                setFieldFoucs('lastName');
                            }}
                            style={{
                                border: isLastNameFieldError
                                    ? '1px solid red'
                                    : '1px solid #ccc',
                                boxShadow: isLastNameFieldError
                                    ? '0px 0px 0px 4px #CC00001A'
                                    : 'none',
                            }}
                            className={
                                fieldFocus === 'lastName'
                                    ? styles.inputFieldActive
                                    : styles.inputFieldAlign
                            }
                            maxLength={20}
                            required
                        />
                        {isLastNameFieldError ? (
                            <div
                                style={{
                                    color: COLORS.brandRed,
                                    marginTop: '5px',
                                }}
                                aria-label={`${errors.lastName} alert`}
                            >
                                {errors.lastName}
                            </div>
                        ) : null}
                    </div>

                    <div
                        className={styles.fieldContainer}
                        style={{ position: 'relative' }}
                    >
                        <label htmlFor="password" className={styles.labelAlign}>
                            Password
                        </label>
                        <input
                            id="password"
                            name="password"
                            tabIndex={7}
                            aria-required="true"
                            aria-label={
                                touched.password
                                    ? errors.password
                                        ? `8 characters minimum, a letter, a special character, and a number. No spaces.`
                                        : 'Password is required field.'
                                    : 'Password'
                            }
                            autoComplete="new-password"
                            value={values.password}
                            onChange={handleChange}
                            type={!isPasswordShow ? 'password' : 'text'}
                            onFocus={(e) => {
                                setFieldFoucs(e.target.name);
                            }}
                            onBlur={(e) => handleOnBlur(e)}
                            style={{
                                border: isPasswordFieldError
                                    ? '1px solid red'
                                    : '1px solid #ccc',
                                boxShadow: isPasswordFieldError
                                    ? '0px 0px 0px 4px #CC00001A'
                                    : 'none',
                            }}
                            className={
                                fieldFocus === 'password'
                                    ? styles.inputFieldActive
                                    : styles.inputFieldAlign
                            }
                            maxLength={50}
                            required
                        />
                        {isPasswordFieldError && fieldFocus !== 'password' ? (
                            <div
                                style={{
                                    color: COLORS.brandRed,
                                    marginTop: '5px',
                                }}
                                aria-label={`${errors.password} alert`}
                            >
                                {errors.password}
                            </div>
                        ) : null}
                        {(fieldFocus === 'password' && isPasswordFieldError) ||
                        fieldFocus === 'password' ? (
                            <div className={styles.alignPasswordValidation}>
                                <div
                                    className={
                                        isPasswordChar
                                            ? styles.validationPass
                                            : styles.validationError
                                    }
                                >
                                    <img
                                        src={
                                            isPasswordChar
                                                ? require('src/assets/icons/iconRightGreen.svg')
                                                : require('src/assets/icons/iconDash.svg')
                                        }
                                        alt="icon"
                                        id={
                                            isPasswordChar
                                                ? 'characters_checked'
                                                : 'characters_unchecked'
                                        }
                                    />
                                    &nbsp; 8+ characters
                                </div>
                                <div
                                    className={
                                        isValidChar
                                            ? styles.validationPass
                                            : styles.validationError
                                    }
                                >
                                    <img
                                        src={
                                            isValidChar
                                                ? require('src/assets/icons/iconRightGreen.svg')
                                                : require('src/assets/icons/iconDash.svg')
                                        }
                                        alt="icon"
                                        id={
                                            isValidChar
                                                ? 'letter_checked'
                                                : 'letter_unchecked'
                                        }
                                    />
                                    &nbsp; Letter
                                </div>
                                <div
                                    className={
                                        isValidSpecialChar
                                            ? styles.validationPass
                                            : styles.validationError
                                    }
                                >
                                    <img
                                        src={
                                            isValidSpecialChar
                                                ? require('src/assets/icons/iconRightGreen.svg')
                                                : require('src/assets/icons/iconDash.svg')
                                        }
                                        alt="icon"
                                        id={
                                            isValidSpecialChar
                                                ? 'specialChar_checked'
                                                : 'specialChar_unchecked'
                                        }
                                    />
                                    &nbsp; Special character
                                </div>
                                <div
                                    className={
                                        isContainNumber
                                            ? styles.validationPass
                                            : styles.validationError
                                    }
                                >
                                    <img
                                        src={
                                            isContainNumber
                                                ? require('src/assets/icons/iconRightGreen.svg')
                                                : require('src/assets/icons/iconDash.svg')
                                        }
                                        alt="icon"
                                        id={
                                            isContainNumber
                                                ? 'number_checked'
                                                : 'number_unchecked'
                                        }
                                    />
                                    &nbsp; Number
                                </div>
                                <div
                                    className={
                                        isNoSpace
                                            ? styles.validationPass
                                            : styles.validationError
                                    }
                                >
                                    <img
                                        src={
                                            isNoSpace
                                                ? require('src/assets/icons/iconRightGreen.svg')
                                                : require('src/assets/icons/iconDash.svg')
                                        }
                                        alt="icon"
                                        id={
                                            isNoSpace
                                                ? 'noSpaces_checked'
                                                : 'noSpaces_unchecked'
                                        }
                                    />
                                    &nbsp; No spaces
                                </div>
                            </div>
                        ) : null}

                        <Button
                            onClick={() => setPasswordShow(!isPasswordShow)}
                            className={styles.eyeImageAlign}
                        >
                            {isPasswordShow ? (
                                <div
                                    tabIndex={8}
                                    role="button"
                                    aria-label="Hide password"
                                    id="hidePassword"
                                >
                                    <IconPasswordOpen />
                                </div>
                            ) : (
                                <div
                                    tabIndex={8}
                                    role="button"
                                    aria-label="Show password"
                                    id="showPassword"
                                >
                                    <IconPasswordClose />
                                </div>
                            )}
                        </Button>
                    </div>

                    <div className={styles.fieldContainer}>
                        <label
                            htmlFor="phoneNumber"
                            className={styles.labelAlign}
                        >
                            Phone number
                        </label>
                        <input
                            id="phoneNumber"
                            name="phoneNumber"
                            inputMode="numeric"
                            tabIndex={9}
                            aria-required="true"
                            aria-label={
                                touched.phoneNumber
                                    ? errors.phoneNumber
                                        ? 'Please enter a valid phone number.'
                                        : 'Phone number is a required field.'
                                    : 'Phone number'
                            }
                            onChange={handlePhoneNumber}
                            type="tel"
                            value={formatPhoneNumber(values.phoneNumber)}
                            onBlur={(e: any) => {
                                handleOnBlur(e);
                                setFieldFoucs('');
                            }}
                            onFocus={(e: any) => {
                                // setTouched({ ...touched, phoneNumber: true })
                                setFieldFoucs('phoneNumber');
                            }}
                            style={{
                                border: isPhoneNumberFieldError
                                    ? '1px solid red'
                                    : '1px solid #ccc',
                                boxShadow: isPhoneNumberFieldError
                                    ? '0px 0px 0px 4px #CC00001A'
                                    : 'none',
                            }}
                            className={
                                fieldFocus === 'phoneNumber'
                                    ? styles.inputFieldActive
                                    : styles.inputFieldAlign
                            }
                            required
                        />
                        {isPhoneNumberFieldError ? (
                            <div
                                style={{
                                    color: COLORS.brandRed,
                                    marginTop: '5px',
                                }}
                                aria-label={`${errors.phoneNumber} alert`}
                            >
                                {errors.phoneNumber}
                            </div>
                        ) : null}

                        {fieldFocus === 'phoneNumber' &&
                        Boolean(!errors.phoneNumber) ? (
                            <div
                                style={{ marginTop: '5px' }}
                                className={styles.labelAlign}
                            >
                                We’ll use your phone number to help verify your
                                account in the future.
                            </div>
                        ) : null}
                    </div>
                    <div
                        id="checkboxContainer"
                        className={styles.checkboxContainer}
                    >
                        <Checkbox
                            id="termsCheckbox"
                            className={
                                isTerms
                                    ? styles.customCheckboxChecked
                                    : styles.customCheckbox
                            }
                            style={{
                                padding: 0,
                            }}
                            checked={isTerms}
                            tabIndex={11}
                            role="checkbox"
                            aria-label="Terms and Conditions Checkbox"
                            onChange={handleTerms}
                        />
                        <Body
                            size={10}
                            weight="light"
                            lineHeight={14}
                            tabIndex={12}
                            ariaLabel={termsAndConditionAriaLabel}
                        >
                            <span aria-hidden>
                                By creating an account, you agree to Staples and
                                Easy Rewards{' '}
                                <Button
                                    tabIndex={-1}
                                    variant="text"
                                    display="inlineFlex"
                                    className={styles.subLink}
                                    fontSize={10}
                                    id="createUsertermsAndConditions"
                                    onClick={() => {
                                        setShowTabs(true);
                                        setTosSource(
                                            `${process.env.REACT_APP_DOTCOM_URL}/hc?id=52e40651-0852-4ad7-a532-45017c287d50`
                                        );
                                    }}
                                    // href="https://www.staples.com/hc?id=52e40651-0852-4ad7-a532-45017c287d50"
                                >
                                    {LOGIN_CREATE_NEW_USER.TERM_CONDITION}
                                </Button>
                                ,{' '}
                                <Button
                                    tabIndex={-1}
                                    variant="text"
                                    display="inlineFlex"
                                    className={styles.subLink}
                                    fontSize={10}
                                    id="createUserPrivacyPolicy"
                                    onClick={() => {
                                        setTosSource(
                                            `${process.env.REACT_APP_DOTCOM_URL}/hc?id=dbb94c10-973c-478b-a078-00e58f66ba32`
                                        );
                                    }}
                                    // href="https://www.staplesconnect.com/privacypolicy"
                                >
                                    {LOGIN_CREATE_NEW_USER.PRIVACY_POLICY}
                                </Button>
                                , and{' '}
                                <Button
                                    tabIndex={-1}
                                    variant="text"
                                    display="inlineFlex"
                                    fontSize={10}
                                    className={styles.subLink}
                                    id="createUserCaliforniaNotice"
                                    onClick={() => {
                                        setTosSource(
                                            `${process.env.REACT_APP_DOTCOM_URL}/hc?id=d0641e76-dd9f-4d69-80d3-ff148ddcd188`
                                        );
                                    }}
                                    // href="https://www.staplesconnect.com/californianotice"
                                >
                                    {LOGIN_CREATE_NEW_USER.CALIFORNIA}
                                </Button>
                                . You also agree to receive Staples promotional
                                communications. You may unsubscribe at any time.
                            </span>
                        </Body>
                    </div>
                    <LoadingButton
                        fullWidth
                        id="step2-next-button"
                        variant="contained"
                        classes={{
                            root: styles.createAccountButton,
                        }}
                        type="submit"
                        tabIndex={13}
                        role="button"
                        aria-label="Continue"
                        loading={false}
                        disabled={
                            (errors && Object.keys(errors).length !== 0) ||
                            !isTerms
                        }
                    >
                        {LOGIN_CREATE_NEW_USER.CREATE_ACCOUNT}
                    </LoadingButton>

                    <br />
                    <br />
                </form>
                {isLoading ? (
                    <LoadingOverlay
                        variant="wordmark"
                        theme="transparent-light"
                        open={isLoading}
                    />
                ) : null}
            </FullWidthCard>
            <Captcha
                capthaHtml={captchaHtml}
                sessionId={getStplSessionIdUniqueId()}
                onCaptchaSumbit={handleSubmit}
                placement="CreateAccountWebSC"
            />
            <SwipeableDrawer
                anchor="bottom"
                open={!!tosSource}
                onClose={() => {
                    setTosSource(null);
                    setShowTabs(false);
                    setTosTabIndex(0);
                }}
                onOpen={() => console.log('open')}
            >
                {showTabs && (
                    <Tabs
                        className={styles.tosTabs}
                        value={tosTabIndex}
                        onChange={(_e, value) => {
                            setTosTabIndex(value);
                            if (value === 0) {
                                setTosSource(
                                    `${process.env.REACT_APP_DOTCOM_URL}/hc?id=52e40651-0852-4ad7-a532-45017c287d50`
                                );
                            } else {
                                setTosSource(
                                    `${process.env.REACT_APP_DOTCOM_URL}/lp/easy-rewards-terms-conditions`
                                );
                            }
                        }}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Staples" />
                        <Tab label="Easy Rewards" />
                    </Tabs>
                )}
                {tosTabIndex === 0 && (
                    <iframe
                        id="privacy-link"
                        title="privacy link iFrame"
                        style={{ height: '90vh', width: '100%', marginTop: 16 }}
                        src={tosSource || '#'}
                    />
                )}
                {tosTabIndex === 1 && (
                    <iframe
                        id="privacy-link"
                        title="privacy link iFrame"
                        style={{ height: '90vh', width: '100%', marginTop: 16 }}
                        src={tosSource || '#'}
                    />
                )}
            </SwipeableDrawer>
        </>
    );
};

export default CreateAccountScreen;
