import React from 'react';


function IconERCart() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" width="110" data-name="Layer 1" viewBox="0 0 1080 1080">
  <g>
    <rect fill="#5a2267" strokeWidth={0} x="485.33" y="450.33" width="194.81" height="151.7" transform="translate(-93.66 924.83) rotate(-72.59)"/>
    <path fill="#000"  strokeWidth={0} d="M539.5,410.54l-58.28,185.89,144.75,45.38,58.28-185.89-144.75-45.38ZM608.94,609.22l-95.13-29.82,42.72-136.27,95.13,29.82-42.72,136.27Z"/>
  </g>
  <path  fill="#ee2a24"  strokeWidth={0} d="M221.91,563.91l56.7,227.31,17.87,49.8s383.04,3.91,407.3,0l40.08-50.64,34.08-226.47H221.91Z"/>
  <path fill="#dfe1df"  strokeWidth={0} d="M831.25,982.36H318.35c-7.18,0-13,5.82-13,13s5.82,13,13,13h512.9c7.18,0,13-5.82,13-13s-5.82-13-13-13Z"/>
  <path fill="#b72926"  strokeWidth={0} d="M781.09,572.42c-11.26,82.89-22.52,139.76-33.78,222.64-2.12,15.6-37.96,35.05-40.08,50.64,24.26-3.91,82.25-11.67,106.51-15.58l35.51-65.95,22.32-200.25-90.48,8.5Z"/>
  <polygon fill="#b72926"  strokeWidth={0} points="422.55 456.41 201.44 456.41 216.14 507.03 436.77 505.52 422.55 456.41"/>
  <path fill="#fff"  strokeWidth={0} d="M563.61,651.1h-96.69c-15,0-27.27,12.27-27.27,27.27v60.85h27.61v-60.51h95.99v60.51h27.61v-60.87c0-14.99-12.26-27.25-27.25-27.25Z"/>
  <path fill="#000"  strokeWidth={0} d="M626.66,858.7c.86,0,86.95-.11,131.78-.11,25.16,0,49.78-9.74,69.31-27.41,19.49-17.64,31.69-41.17,34.34-66.25l25.4-214.01h-429.11l-23.18-97.51c-1.39-5.86-6.63-9.99-12.65-9.99h-221.1c-3.98,0-7.73,1.82-10.2,4.94s-3.37,7.2-2.45,11.06l34.85,146.82h-.03s19.72,82.98,19.72,82.98l30.95,130.38c.09.36.19.72.3,1.06l2.66,11.17c5.88,24.72,20.91,47.86,42.33,65.17,21.5,17.37,47.05,26.94,71.95,26.94h.07c-2.03,6.29-3.14,12.98-3.14,19.95,0,35.84,29.05,64.89,64.89,64.89s64.89-29.05,64.89-64.89c0-6.95-1.1-13.63-3.12-19.91l167.24.06c-2.01,6.26-3.1,12.92-3.1,19.84,0,35.84,29.05,64.89,64.89,64.89s64.89-29.05,64.89-64.89c0-6.95-1.11-13.65-3.13-19.93l25.35.02h.01c7.17,0,12.99-5.81,13-12.99,0-7.18-5.81-13-12.99-13.01l-41.31-.04c-11.74-11.71-27.93-18.95-45.82-18.95s-34.18,7.28-45.93,19.05l-199.01-.07c-11.74-11.73-27.95-18.98-45.85-18.98s-34.08,7.24-45.82,18.94h-16c-27.81,0-55.76-15.96-73.23-39.23,56.38-.02,228.08-.08,308.36-.1v.11ZM744.13,904.98c21.44,0,38.89,17.45,38.89,38.89s-17.45,38.89-38.89,38.89-38.89-17.45-38.89-38.89,17.45-38.89,38.89-38.89ZM453.34,904.98c21.44,0,38.89,17.45,38.89,38.89s-17.45,38.89-38.89,38.89-38.89-17.45-38.89-38.89,17.45-38.89,38.89-38.89ZM789.49,576.91h68.73l-20.89,176.03h-68.73l20.89-176.03ZM832.43,778.95c-10.79,30.76-40.97,53.64-74,53.64-7.99,0-17.3,0-27.21,0,.54-.47,1.08-.94,1.61-1.42,15.81-14.31,26.82-32.49,31.83-52.23h67.77ZM412.27,469.41l4.78,20.11h-194.39l-4.77-20.11h194.38ZM237.24,550.91l-8.4-35.39h194.4l8.41,35.39h-194.41ZM304.51,832.7c-.76-2.27-1.42-4.57-1.98-6.9l-34.06-143.26-25.07-105.62h519.9l-21.96,185.03-.02.17c-4.08,38.86-38.98,70.47-77.81,70.47-42.14,0-319.87.1-359.01.11Z"/>
  <rect fill="#58b6e7"  strokeWidth={0} x="468.22" y="323.74" width="26" height="140.36"/>
  <rect fill="#58b6e7"  strokeWidth={0} x="528.22" y="274.08" width="26" height="97.92"/>
  <rect fill="#58b6e7"  strokeWidth={0} x="591.66" y="334.94" width="26" height="57.91"/>
  <rect fill="#58b6e7"  strokeWidth={0} x="835.51" y="176.38" width="26" height="70.19"/>
  <rect fill="#58b6e7"  strokeWidth={0} x="775.51" y="126.73" width="26" height="97.92"/>
  <rect fill="#58b6e7"  strokeWidth={0} x="712.07" y="187.59" width="26" height="57.91"/>
  <g>
    <circle fill="#ffdda6"  strokeWidth={0} cx="789.4" cy="363.89" r="102.13" transform="translate(-26.1 664.77) rotate(-45)"/>
    <path fill="#f9ab60"  strokeWidth={0} d="M830.71,270.49c-13.43-5.94-27.46-8.76-41.26-8.75-39.19,0-76.58,22.69-93.46,60.84-22.82,51.58.5,111.9,52.09,134.72,13.43,5.94,27.46,8.75,41.26,8.75,39.19,0,76.58-22.69,93.46-60.84,22.82-51.58-.5-111.9-52.09-134.72ZM859.02,394.69c-12.19,27.56-39.54,45.36-69.68,45.36-10.6,0-20.94-2.2-30.74-6.53-18.6-8.23-32.88-23.2-40.21-42.17-7.33-18.97-6.84-39.65,1.38-58.25,12.19-27.55,39.54-45.36,69.68-45.36h0c10.59,0,20.93,2.2,30.73,6.53,18.6,8.23,32.88,23.2,40.21,42.17,7.33,18.97,6.84,39.65-1.38,58.25Z"/>
  </g>
  <path fill="#f9ab60"  strokeWidth={0} d="M823.54,352.49l-48.65-21.52c-7.55-3.34-16.45.11-19.79,7.65l-13.54,30.62,13.89,6.15,13.47-30.45,48.3,21.36-13.47,30.45,13.89,6.15,13.55-30.63c3.34-7.54-.11-16.44-7.65-19.78Z"/>
</svg>
    );
}

export default IconERCart;
