import {
    ButtonBase,
    InputBase,
    InputBaseProps,
    makeStyles,
} from '@material-ui/core';
import React from 'react';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles({
    searchInput: {
        display: 'flex',
        width: '100%',

        height: '38px',
        padding: '0.25rem 0',
        borderRadius: '0.625rem',
        backgroundColor: COLORS.primaryWhite,
        gap: '10px',
        color: '#000000',
    },
    searchIconBtn: {
        marginLeft: '.9rem',
    },
    searchIcon: {},

    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

interface InputProps extends InputBaseProps {
    placeholder?: string;
}

const SearchInput = ({ placeholder = '', ...rest }: InputProps) => {
    const classes = useStyles();
    return (
        <>
            <InputBase
                {...rest}
                className={classes.searchInput}
                placeholder={placeholder}
                startAdornment={
                    <ButtonBase
                        id="school-selection-search-button"
                        className={classes.searchIconBtn}
                    >
                        <img
                            id="school-search-icon-img"
                            src={require('../assets/icons/Search_icon_home.svg')}
                            className={classes.searchIcon}
                            alt="search icon"
                        />
                    </ButtonBase>
                }
            />
        </>
    );
};

export default SearchInput;
