/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
    Box,
    makeStyles,
    Typography,
    CardContent,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ButtonBase,
    Grid,
    SwipeableDrawer,
    TextField,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { optimoveEvents } from 'src/screens/optimove';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { format, parse } from 'date-fns';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { Store, StoreHourDetail, StoreHours } from 'src/services/types';
import { storeDetailsUrl } from 'src/lib/utils/store-utils';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import useDragScroll from 'src/hooks/useDragScroll';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import axiosInstance from 'src/lib/api-client/custom-axios';
import useUserInfo from 'src/hooks/useUserInfo';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import { useGuestMode } from 'src/hooks';
import COLORS from 'src/lib/colors';
import moment from 'moment';
import { formatPhoneNumber } from 'src/lib/utils/string-utils';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import InfoIconCard from './info-icon-card';
import ScrollableCard from './scrollable-card';
import ScrollableRow from '../scrollable-row';
import AppLink from '../link';
import { Body } from '../UI';
import OpenPrintExternalDialog from '../open-print-external-dialog';

type StoreServiceCardProps = {
    storeData?: Store | null;
    openStoreSearch: () => void;
    storeServicesLink: string;
    setStoreServicesLink: (link: string) => void;
    storeServicesExternalLink: boolean;
    setStoreServicesExternalLink: (bool: boolean) => void;
    recycleRef: React.MutableRefObject<HTMLDivElement | null>;
};
const storeServicesAllowToDis = [
    'Print and Marketing Services',
    'TSA Precheck',
    'Tech Services',
    'Passport Services',
    'Recycling Services',
    'UPS Shipping & Drop-off',
    'Passport Photos',
    'Amazon Returns',
    'Amazon Lockers',
    'iPostal1',
    'Affirm',
    'PC Tune-Up',
];

const useStyles = makeStyles((theme) => ({
    accordianRoot: {
        '&::before': {
            border: 0,
            background: '#ffffff',
        },
    },
    accordianTitle: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18px',
        color: '#343434',
    },
    accordionDetailsContainer: {
        width: '100%',
    },
    container: {
        background: '#FFFFFF',
        borderRadius: '16px',
        padding: '16px',
        margin: '18px 15px 16px',
        '& #store-info-scrollable-row': {
            padding: '0px',
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
        },
        '& .MuiAccordion-root.Mui-expanded': {
            margin: '0px',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px',
        },
        '& .MuiAccordionSummary-expandIcon.': {
            padding: '0',
        },
        '& .MuiAccordionSummary-root': {
            height: '20px !important',
            marginTop: '10px',
            minHeight: '20px !important',
            padding: 0,
        },
        '& .MuiAccordionDetails-root': {
            padding: '8px 0 16px',
        },
    },
    ctas: {
        display: 'flex',
        width: '100%',
        gap: '30px',
    },
    ctaText: {
        cursor: 'pointer',
        color: '#086dd2',
        fontSize: '14px',
        fontWeight: 500,
    },
    serviceContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px !important',
        flexDirection: 'column',
        background: '#F8F7F7',
        borderRadius: '15px',
        width: '84px',
        height: '90px',
        justifyContent: 'space-between',
    },
    serviceIfoMainContainer: {
        display: 'flex',
        gap: 24,
        alignItems: 'center',
        boxShadow: 'none',
        overflow: 'initial',
        padding: '8px 0px',
    },
    serviceImageInfo: {
        width: 30,
    },
    servicetextInfo: {
        fontSize: 12,
        color: '#585858',
        lineHeight: '12px',
        textAlign: 'center',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    title: {
        fontSize: '18px !important',
        lineHeight: '22px',
        color: '#343434',
        marginBottom: '10px',
        [theme.breakpoints.down(370)]: {
            fontSize: '16px !important',
        },
    },
    phone: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '30px',
        border: '0 !important',
        background: 'none',
        outline: '0 !important',
        textDecoration: 'none !important',
        color: '#585858 !important',
    },
    address: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    learnMore: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',
        color: '#086dd2',
    },
    infoDetails: {
        height: '100%',
        padding: '0 10px 0 0',
        justifyContent: 'flex-start',
    },
    infoIcon: {
        height: '100%',
        justifyContent: 'center',
    },
    infoGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        [theme.breakpoints.down(370)]: {
            fontSize: '12px',
        },
    },
    infoContainer: {
        borderTop: '1px solid #F2F2F2',
        paddingTop: '24px',
        display: 'flex',
        gap: '14px',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '60%',
    },
    exitContainer: {
        height: '190px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '24px',
    },
    ctwText: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#000000',
        margin: '35px auto 16px',
    },
    tacoext: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#000000',
        marginBottom: '27px',
        width: '199px',
        textAlign: 'center',
    },
    btnCta: {
        fontSize: '1.1667rem',
        background: COLORS.brandRed,
        textAlign: 'center',
        width: '175px',
        height: '36px',
        borderRadius: '28px',
        '& span': {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#ffffff',
        },
    },
    paperDrawer: {
        borderRadius: ' 15px 15px 0px 0px',
        margin: '0 1rem',
    },
    openSign: {
        height: '10px',
        width: '10px',
        borderRadius: '3px',
        display: 'inline-block',
        marginRight: '8px',
    },
    storeDetailsLocation: {
        margin: '20px 0px',
    },
}));

function getDayOfWeek() {
    return format(new Date(), 'EEEE').toLowerCase();
}

function nextDayTime() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return format(currentDate, 'EEEE').toLowerCase();
}

const describeStoreHours = (
    storeHours: StoreHours,
    timezone: string
): {
    open: boolean;
    closingTime: string;
    nextDayOpenTime: string;
} => {
    const todaysHours = (storeHours as any)[getDayOfWeek()] as any;
    const nextDay: any = (storeHours as any)[nextDayTime()] as any;
    const dateOfClose = parse(todaysHours.close24Hr, 'HHmm', new Date());
    const nextDayTimeDis = parse(nextDay.open24Hr, 'HHmm', new Date());
    const now = moment(new Date()).tz(timezone).format('HHmm');
    console.log('🚀 ~ dateOfClose:', { dateOfClose, now, todaysHours });
    return {
        open: now < todaysHours.close24Hr,
        closingTime: moment(dateOfClose, 'HH:mm', true).isValid()
            ? format(dateOfClose, 'h:mmaaa')
            : 'Closed',
        nextDayOpenTime: moment(nextDayTimeDis, 'HH:mm', true).isValid()
            ? format(nextDayTimeDis, 'h:mmaaa')
            : 'Closed',
    };
};

/** Displays store service information on the home screen */
export default function StoreServiceCard({
    openStoreSearch,
    storeData,
    storeServicesLink,
    setStoreServicesLink,
    storeServicesExternalLink,
    setStoreServicesExternalLink,
    recycleRef,
}: StoreServiceCardProps) {
    const classes = useStyles();
    const { userInfo } = useUserInfo();
    const { history } = useRouter();
    const { isGuestModeOn } = useGuestMode();
    const [storeOpenMessage, setStoreOpenMessage] = useState('');
    const { setOpenPrintDrawer } = useTabsUIStateContext();
    const { analyticsCustomEvent, customEvent } = useEventsReporter();
    const scrollProps = useDragScroll();
    const { data: response } = useQuery(
        ['recycle-data', userInfo?.preferredStoreId],
        () =>
            axiosInstance.get(
                `${process.env.REACT_APP_STORE}`.replace(
                    '%storeIdentifier%',
                    `${userInfo?.preferredStoreId}`
                )
            ),
        {
            enabled: !!userInfo?.preferredStoreId,
            keepPreviousData: true,
        }
    );

    useEffect(() => {
        if (storeData) {
            if (storeData?.storeHours) {
                const { open, closingTime, nextDayOpenTime } =
                    describeStoreHours(
                        storeData?.storeHours,
                        storeData?.timezone
                    );
                if (closingTime === 'Closed') {
                    setStoreOpenMessage(`Closed now`);
                } else if (open) {
                    setStoreOpenMessage(
                        `Open until ${closingTime.toUpperCase()}`
                    );
                } else if (nextDayOpenTime === 'Closed') {
                    setStoreOpenMessage(`Closed now`);
                } else {
                    setStoreOpenMessage(
                        `Opens at ${nextDayOpenTime.toUpperCase()}`
                    );
                }
            } else {
                setStoreOpenMessage('Temporarily Closed');
            }
        }
    }, [storeData]);

    const getTheStoreName = (name: string) => {
        let storeNameDis = '';
        switch (name) {
            case 'Print and Marketing Services':
                storeNameDis = 'Print Services';
                break;
            case 'Recycling Services':
                storeNameDis = 'Recycling Services';
                break;
            case 'UPS Shipping & Drop-off':
                storeNameDis = 'UPS Shipping';
                break;
            case 'TSA Precheck':
                storeNameDis = 'TSA Precheck';
                break;
            case 'Passport Photos':
                storeNameDis = 'Passport Photos';
                break;
            case 'Amazon Returns':
                storeNameDis = 'Amazon Returns';
                break;
            case 'Amazon Lockers':
                storeNameDis = 'Amazon Lockers';
                break;
            case 'iPostal1':
                storeNameDis = 'iPostal1';
                break;
            case 'Tech Services':
                storeNameDis = 'Tech Services';
                break;
            case 'ClipDrop':
                storeNameDis = 'ClipDrop';
                break;
            case 'Coworking':
                storeNameDis = 'Coworking';
                break;
            default:
                storeNameDis = name;
                break;
        }
        return storeNameDis;
    };

    const getTheStoreNameByPriority = (name: string) => {
        let storePriorityLinkDis = { storePriorityDis: 0, linkUrl: '' };
        switch (name) {
            case 'Print and Marketing Services':
                storePriorityLinkDis = {
                    storePriorityDis: 1,
                    linkUrl: `${process.env.REACT_APP_DOT_COM_URL}/services/printing/custom-cards/holiday-cards/?utm_source=staples_app&utm_medium=referral&utm_campaign=omni-homepage&utm_term=pto-cards&utm_content=quicklinks`,
                };
                break;
            case 'TSA Precheck':
                storePriorityLinkDis = {
                    storePriorityDis: 2,
                    linkUrl: `${process.env.REACT_APP_DOT_COM_URL}/stores/services/tsa`,
                };
                break;
            case 'Tech Services':
                storePriorityLinkDis = {
                    storePriorityDis: 3,
                    linkUrl:
                        window.location.host === 'qe101.staples.com'
                            ? `${process.env.REACT_APP_DOT_COM_URL}/stores/yeti-intake`
                            : `${process.env.REACT_APP_DOT_COM_URL}/stores/services/start-your-in-store-tech-service`,
                };
                break;
            case 'Passport Photos':
                storePriorityLinkDis = {
                    storePriorityDis: 4,
                    linkUrl: `${process.env.REACT_APP_DOT_COM_URL}/stores/services/passport-photos`,
                };
                break;
            case 'Recycling Services':
                storePriorityLinkDis = {
                    storePriorityDis: 5,
                    linkUrl: 'StoreTab',
                };
                break;
            case 'UPS Shipping & Drop-off':
                storePriorityLinkDis = {
                    storePriorityDis: 6,
                    linkUrl: 'CreateShipment',
                };
                break;

            case 'Amazon Returns':
                storePriorityLinkDis = {
                    storePriorityDis: 7,
                    linkUrl: `${process.env.REACT_APP_DOT_COM_URL}/stores/services/amazonreturns`,
                };
                break;
            case 'Amazon Lockers':
                storePriorityLinkDis = {
                    storePriorityDis: 8,
                    linkUrl: `${process.env.REACT_APP_DOT_COM_URL}/stores/services/amazonpickup`,
                };
                break;
            case 'iPostal1':
                storePriorityLinkDis = {
                    storePriorityDis: 9,
                    linkUrl: `${process.env.REACT_APP_DOT_COM_URL}/stores/services/iPostal1`,
                };
                break;
            case 'Affirm':
                storePriorityLinkDis = {
                    storePriorityDis: 10,
                    linkUrl: `${process.env.REACT_APP_DOT_COM_URL}/stores/services/affirm`,
                };
                break;
            case 'PC Tune-Up':
                storePriorityLinkDis = {
                    storePriorityDis: 11,
                    linkUrl: `${process.env.REACT_APP_DOT_COM_URL}/stores/p/pctuneup`,
                };
                break;
            default:
                storePriorityLinkDis = {
                    storePriorityDis: 12,
                    linkUrl: '',
                };
                break;
        }
        return storePriorityLinkDis;
    };

    const getWidthByName = (storeServiceInfoName: string) => {
        let width = 'auto';
        if (storeServiceInfoName === 'TSA Precheck') {
            width = '75px';
        }
        return width;
    };

    const { setGuestModal } = useUserUpdateContext();

    const handleOnClickNavigate = (
        servicesLink: string,
        storeServiceInfo: any
    ) => {
        console.log(
            '🚀 ~ servicesLink:',
            servicesLink,
            storeServiceInfo?.data?.name
        );
        if (storeServiceInfo?.data?.name === 'Tech Services') {
            analyticsCustomEvent(eventMetricsMap.tech_services, {
                click_text: 'begin service request',
                element_location: 'available services',
            });
        } else {
            analyticsCustomEvent(eventMetricsMap.stores, {
                click_text: getTheStoreName(
                    storeServiceInfo?.data?.name
                ).toLowerCase(),
                element_location: 'available services',
            });
        }

        if (servicesLink === 'StoreTab' && recycleRef) {
            if (isGuestModeOn) {
                setGuestModal({ open: true });
            } else {
                return recycleRef.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        } else if (servicesLink === 'CreateShipment') {
            if (isGuestModeOn) {
                setGuestModal({ open: true });
            } else {
                return history.push({
                    pathname: ROUTES.shippingServices,
                });
            }
        } else if (servicesLink) {
            if (
                !servicesLink.includes(`${process.env.REACT_APP_DOT_COM_URL}`)
            ) {
                setStoreServicesExternalLink(true);
            } else if (servicesLink.includes(`services/printing`)) {
                setStoreServicesLink(servicesLink);
            } else {
                return setStoreServicesLink(servicesLink);
            }
        }
        return null;
    };
    const handleCtw = () => {
        openInNewTab(storeServicesLink);
        setStoreServicesLink('');
        setStoreServicesExternalLink(false);
    };
    const getStoreServices = () => {
        if (!storeData?.storeServices) return null;
        const filterdStoreServices: any = storeData?.storeServices
            .reduce((acc: any, data: any) => {
                if (storeServicesAllowToDis?.includes(data?.name)) {
                    const storeDataPriorityLink = getTheStoreNameByPriority(
                        data?.name
                    );
                    acc.push({
                        data,
                        priority: storeDataPriorityLink.storePriorityDis,
                        link: storeDataPriorityLink.linkUrl,
                    });
                }
                return acc;
            }, [])
            ?.sort((a: any, b: any) => a.priority - b.priority);
        return (
            <ScrollableRow
                id="store-info-scrollable-row"
                scrollProps={scrollProps}
                showShadow={false}
            >
                {filterdStoreServices?.map(
                    (storeServiceInfo: any, index: number) => (
                        <ScrollableCard
                            className={classes.serviceIfoMainContainer}
                            id="storeInfoContainerId"
                            noPadding
                        >
                            <CardContent
                                role="button"
                                key={storeServiceInfo?.data?.id}
                                className={classes.serviceContainer}
                                onClick={() => {
                                    handleOnClickNavigate(
                                        storeServiceInfo.link,
                                        storeServiceInfo
                                    );
                                }}
                            >
                                <div className={classes.imageContainer}>
                                    <img
                                        aria-hidden="true"
                                        src={
                                            storeServiceInfo?.data
                                                ?.serviceIcon ||
                                            require('../../assets/icons/iconServicesPlaceHolder.svg')
                                        }
                                        alt="img"
                                        className={classes.serviceImageInfo}
                                    />
                                </div>
                                <Typography
                                    className={classes.servicetextInfo}
                                    style={{
                                        width: getWidthByName(
                                            storeServiceInfo?.data?.name
                                        ),
                                    }}
                                >
                                    {getTheStoreName(
                                        storeServiceInfo?.data?.name
                                    )}
                                </Typography>
                            </CardContent>
                        </ScrollableCard>
                    )
                )}
            </ScrollableRow>
        );
    };

    return (
        <div className={classes.container}>
            <Body weight="bold" className={classes.title}>
                Available services at your Staples
            </Body>
            {getStoreServices()}
            <SwipeableDrawer
                anchor="bottom"
                open={storeServicesExternalLink}
                classes={{
                    paper: classes.paperDrawer,
                }}
                style={{ borderRadius: ' 15px 15px 0px 0px', margin: '0 1rem' }}
                onClose={() => {
                    setStoreServicesLink('');
                    setStoreServicesExternalLink(false);
                }}
                onOpen={() => console.log('dialog open')}
            >
                <div style={{ alignSelf: 'center' }}>
                    <img
                        src={require('../../assets/icons/shoppingDrawerLine.svg')}
                        alt="drawerLine"
                    />
                </div>
                {storeServicesExternalLink ? (
                    <div className={classes.exitContainer}>
                        <Typography className={classes.ctwText}>
                            Continue to website
                        </Typography>
                        <Typography className={classes.tacoext}>
                            This action is continued out of the Staples app.
                        </Typography>
                        <ButtonBase
                            className={classes.btnCta}
                            onClick={handleCtw}
                        >
                            <span>Continue to website</span>
                        </ButtonBase>
                    </div>
                ) : null}
            </SwipeableDrawer>
            <OpenPrintExternalDialog />
        </div>
    );
}
