import { Box, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import Row from 'src/components/Row';
import { useRouter } from 'src/hooks';
import COLORS from 'src/lib/colors';
import { LOGIN_ROUTES } from 'src/routers/routes';

function AuthRoutesAsGuest() {
    const { push } = useRouter();
    return (
        <Box
            sx={{
                bgcolor: '#FFFFFF',

                borderRadius: '15px',
            }}
        >
            <Row
                style={{
                    paddingLeft: '16px',
                    borderBottom: `1px solid ${COLORS.lightGrey}`,
                }}
            >
                <>
                    <Box>
                        <img
                            height={'100%'}
                            src={require('../../../assets/images/userAsGuest.png')}
                            alt=""
                        />
                    </Box>
                    <Typography
                        style={{
                            fontSize: 16,
                            fontWeight: 400,
                            lineHeight: '20px',
                            color: COLORS.textBlack,
                            paddingTop: '16px',
                            paddingRight: '16px',
                        }}
                    >
                        <span
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                lineHeight: '24px',
                                color: COLORS.linkText,
                                cursor: 'pointer',
                            }}
                            onClick={(e) => {
                                push(LOGIN_ROUTES.create);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    push(LOGIN_ROUTES.create);
                                }
                            }}
                            role="button"
                            tabIndex={-1}
                        >
                            Create an account {'  '}
                        </span>
                        to save your files for future projects
                    </Typography>
                    <Box
                        style={{
                            marginTop: '-4px',
                            marginRight: '-4px',
                        }}
                    >
                        <IconButton>
                            <Close />
                        </IconButton>
                    </Box>
                </>
            </Row>
            <Box
                sx={{
                    p: '16px',
                }}
            >
                <Typography
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: COLORS.textBlack,
                    }}
                >
                    Already have an account? {'  '}
                    <span
                        style={{
                            fontSize: 16,
                            fontWeight: 500,
                            lineHeight: '24px',
                            color: COLORS.linkText,
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            push(LOGIN_ROUTES.login);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                push(LOGIN_ROUTES.login);
                            }
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        Sign in
                    </span>
                </Typography>
            </Box>
        </Box>
    );
}

export default AuthRoutesAsGuest;
