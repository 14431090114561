import React, { createContext, useState, useContext, useRef } from 'react';
import { eventMetricsMap, useEventsReporter, useUserInfo } from 'src/hooks';
import useConfigInfo from 'src/hooks/useConfigInfo';
import { addShipmentTracking } from '../api-client/shipment-services';

interface ScanMessageProps {
    title: string;
    description: string;
    description1: string;
    point: string;
}

type ShipmentTrackingContextType = {
    isLoading: boolean;
    trackingError: boolean;
    isScanCompleted: boolean;
    scannerData: any;
    setScannerData: React.Dispatch<React.SetStateAction<any>>;
    scanMessage: ScanMessageProps;
    getShipmentTrackingDetails: (data: any) => void;
    handleClose: () => void;
};

const ShipmentTrackingContextModule =
    createContext<ShipmentTrackingContextType | null>(null);

export const useShipmentTracking = () =>
    useContext(ShipmentTrackingContextModule) as ShipmentTrackingContextType;

export function ShipmentTrackingProvider({ children }: any) {
    const [scannerData, setScannerData] = useState('');

    const { analyticsCustomEvent } = useEventsReporter();
    const { shortdesc: shippingConfigPoints } = useConfigInfo(
        'ShippingOfferPoints'
    );
    const [scanMessage, setScanMessage] = useState({
        title: 'Scan successful!',
        description1: '',
        point: '',
        description: 'every time you ship or drop off a package in store.',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [trackingError, setTrackingError] = useState(false);
    const [isScanCompleted, setIsScanCompleted] = useState(false);

    const handleClose = () => {
        setIsScanCompleted(false);
        setTrackingError(false);
        setScannerData('');
    };

    const getShipmentTrackingDetails = async (trackingnumber: any) => {
        setIsLoading(true);
        const response = await addShipmentTracking(trackingnumber);
        setScannerData('');
        setIsLoading(false);
        if (response?.issuccess) {
            setTrackingError(true);
            if (response?.status === 'SUCCESS') {
                setTrackingError(false);
                setIsScanCompleted(true);

                setScanMessage({
                    title: 'Scan successful!',
                    description1: `Easy Rewards™ members earn`,
                    point: shippingConfigPoints,
                    description:
                        'every time you ship or drop off a package in store.',
                });
                analyticsCustomEvent(eventMetricsMap.shipping, {
                    click_text: 'scan success',
                    element_location: 'scanner',
                });
            } else if (response?.status === 'Fail') {
                setScanMessage({
                    title: 'Your package couldn’t be found',
                    description1: '',
                    point: '',
                    description:
                        'We couldn’t find your package at this time. Please try again later.',
                });
            } else if (response?.status === 'DUPLICATE_TRACKING') {
                setScanMessage({
                    title: 'Duplicate label',
                    description1: '',
                    point: '',
                    description:
                        'This label has been previously scanned ' +
                        'and is already in the system.',
                });
            } else if (response?.status === 'INVALID_TRACKING') {
                setScanMessage({
                    title: `Your package couldn't be found`,
                    description1: '',
                    point: '',
                    description:
                        'Make sure you have a UPS label and ' +
                        'shipment number to start tracking.',
                });
            } else if (response?.status === 'NONUS_TRACKING') {
                setScanMessage({
                    title: 'Want to ship internationally?',
                    description1: '',
                    point: '',
                    description:
                        'Bring your package to your local Staples store ' +
                        'and we’ll ship it anywhere in the world.',
                });
            } else {
                setScanMessage({
                    title: 'Label couldn’t be scanned',
                    description1: '',
                    point: '',
                    description:
                        'We couldn’t scan your label at this time. Please try again later.',
                });
            }
            setIsScanCompleted(true);
            setScannerData('');
        } else {
            setTrackingError(true);
            setIsScanCompleted(true);
            setScanMessage({
                title: 'Your package couldn’t be found',
                description1: '',
                point: '',
                description:
                    'We couldn’t find your package at this time. Please try again later.',
            });
        }
    };

    return (
        <ShipmentTrackingContextModule.Provider
            value={{
                isLoading,
                trackingError,
                scanMessage,
                isScanCompleted,
                scannerData,
                setScannerData,
                getShipmentTrackingDetails,
                handleClose,
            }}
        >
            {children}
        </ShipmentTrackingContextModule.Provider>
    );
}
