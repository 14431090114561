import React, { useEffect, useState } from 'react';
import { SwipeableDrawer } from 'src/components/UI';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { FavoriteSelectionCarousel } from 'src/screens/RewardsEnrollment/FavoriteSelectionScreen';
import { mergeClasses } from 'src/stylesheets/utils';
import styles from './styles.module.scss';

interface ChooseCategoryDrawerProps {
    setIsFavoriteSelectionDrawerOpen?: (arg: boolean) => void;
    handleSelectionClose?: () => void;
    categoryDrawerOpen: boolean;
}

function ChooseCategoryDrawer(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    {setIsFavoriteSelectionDrawerOpen = () => {}, handleSelectionClose = () => {}, categoryDrawerOpen=false}:
         ChooseCategoryDrawerProps) {
    const { isNotifyChooseCategory, openChooseCategory } = useUserUpdateContext();
    const [isCategoryDrawerOpen, setIsCategoryDrawerOpen] =
        useState<boolean>(false);
    useEffect(() => {
            setIsCategoryDrawerOpen(isNotifyChooseCategory);
    }, [isNotifyChooseCategory]);

    useEffect(() => {
            setIsCategoryDrawerOpen(categoryDrawerOpen);  
        
    },[categoryDrawerOpen])
    
    return (<SwipeableDrawer
                anchor="bottom"
                open={isCategoryDrawerOpen}
                onClose={() => { setIsFavoriteSelectionDrawerOpen(false); openChooseCategory(false); }}
                onOpen={() => setIsFavoriteSelectionDrawerOpen(true)}
            >
                <div
                    className={mergeClasses(
                        styles.p8,
                        styles.favoriteSelectionCarouselContainer
                    )}
                >
                    <FavoriteSelectionCarousel
                        variant="manage-favorites"
                        handleClose={() => {
                            handleSelectionClose();
                            setIsFavoriteSelectionDrawerOpen(false);
                        }
                           
                        }
                    />
                </div>
            </SwipeableDrawer>)
}

export default ChooseCategoryDrawer;