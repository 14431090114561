import React, { createContext, useState, useContext, useRef } from 'react';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import {
    DocumentsProps,
    GetFileUploadUrlResponse,
    UploadFileRequest,
} from 'src/screens/YourFileSection/YourFileTypes';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { atom } from 'jotai';
import img from '../../assets/images/docsImg.png';
import {
    getFileListData,
    getFileUploadPath,
} from '../api-client/file-upload-api';

export const isOpenBrowseFileAtom = atom(false);

export interface FileDataProps {
    img: string;
    text: string;
    date: string;
}

export const dummyData: FileDataProps[] = [
    {
        img,
        text: 'file-name',
        date: 'Uploaded Sep 21',
    },
    {
        img,
        text: 'file-name1',
        date: 'Uploaded Sep 21',
    },
    {
        img,
        text: 'file-name2',
        date: 'Uploaded Sep 21',
    },
    {
        img,
        text: 'file-name3',
        date: 'Uploaded Sep 21',
    },
    {
        img,
        text: 'file-name4',
        date: 'Uploaded Sep 21',
    },
    {
        img,
        text: 'file-name5',
        date: 'Uploaded Sep 21',
    },
    {
        img,
        text: 'file-name6',
        date: 'Uploaded Sep 21',
    },
    {
        img,
        text: 'file-name7',
        date: 'Uploaded Sep 21',
    },
];
export const sortByOptions = [
    'Recently uploaded',
    'File name (A -> Z)',
    'File name (Z -> A)',
];

interface FileStatus {
    name: string;
    progress: number;
    timeLeft: string;
}

type YourFilesContextType = {
    selectedFileTypeTab: number;
    fileProgress: number;
    currentFileIndex: number;
    timeLeft: string;
    fileStatus: FileStatus[];
    selectedSortBy: string;
    searchDocsFile: string;
    fileErrorMsg: string;
    showFileOption: boolean;
    fileLoading: boolean;
    openUploadModal: boolean;
    isScanQrOpen: boolean;
    fileListLoading: boolean;
    isPrintSuccessDialogOpen: boolean;
    isQrScanSuccess: boolean;
    isCameraOpen: boolean;
    files: File[];
    documentsList: DocumentsProps[];
    selectedDocument: DocumentsProps | null;

    setShowFileOption: React.Dispatch<React.SetStateAction<boolean>>;
    setIsScanQrOpen: React.Dispatch<React.SetStateAction<boolean>>;
    seIsPrintSuccessDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsCameraOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedSortBy: React.Dispatch<React.SetStateAction<string>>;
    setSearchDocsFile: React.Dispatch<React.SetStateAction<string>>;
    handleTabChange: (tab: number) => void;
    handleFilesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onCancel: () => void;
    togglePrintDialog: () => void;
    onPrintClick: () => void;
    getFileList: () => void;
    onClickFileAction: (file: DocumentsProps | null) => void;
    onUploadFile: (
        file: File,
        data: GetFileUploadUrlResponse,
        index: number,
        totalFilesCount: number
    ) => void;
};

const YourFilesContextModule = createContext<YourFilesContextType | null>(null);

export const useYourFiles = () =>
    useContext(YourFilesContextModule) as YourFilesContextType;

export function YourFilesProvider({ children }: any) {
    const [selectedFileTypeTab, setSelectedFileTypeTab] = useState(0);
    const [currentFileIndex, setCurrentFileIndex] = useState<number>(0);
    const [showFileOption, setShowFileOption] = useState<boolean>(false);
    const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
    const [isScanQrOpen, setIsScanQrOpen] = useState<boolean>(false);
    const [fileListLoading, setFileListLoading] = useState<boolean>(false);
    const [isQrScanSuccess, setIsQrScanSuccess] = useState<boolean>(false);
    const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);
    const [searchDocsFile, setSearchDocsFile] = useState<string>('');
    const [isPrintSuccessDialogOpen, seIsPrintSuccessDialogOpen] =
        useState<boolean>(false);
    const [selectedSortBy, setSelectedSortBy] =
        useState<string>('Recently uploaded');

    const [timeLeft, setTimeLeft] = useState('');
    const [fileErrorMsg, setFileErrorMsg] = useState('');
    const [fileProgress, setFileProgress] = useState(0);
    const [fileLoading, setFileLoading] = useState<boolean>(false);

    const [files, setFiles] = useState<File[]>([]);
    const [fileStatus, setFileStatus] = useState<FileStatus[]>([]);
    const [documentsList, setDocumentsList] = useState<DocumentsProps[]>([]);
    const [selectedDocument, setSelectedDocument] =
        useState<DocumentsProps | null>(null);

    const startTime: number = Date.now();

    const handleTabChange = (tab: number) => {
        setSelectedFileTypeTab(tab);
    };

    function binaryString(file: File): Promise<ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    const bufferData = event.target?.result as ArrayBuffer;
                    resolve(bufferData);
                } catch (error) {
                    reject(error);
                }
            };

            reader.onerror = () => {
                reject(reader.error);
            };

            reader.readAsArrayBuffer(file);
        });
    }

    const getFileList = async () => {
        setFileListLoading(true);
        try {
            const response = await getFileListData();
            if (
                response &&
                response.damuploadpath &&
                response.damuploadpath.length
            ) {
                const modifyData = response.damuploadpath.map((value) => ({
                    ...value,
                    properties: {
                        ...value.properties,
                        filename: decodeURI(value.properties.filename),
                    },
                }));
                setDocumentsList(modifyData);
                setShowFileOption(false);
            } else {
                setShowFileOption(true);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setFileListLoading(false);
        }
    };

    const estimateTimeLeft = (
        uploadedFilesCount: number,
        totalFilesCount: number,
        totalTimeTaken: number
    ) => {
        if (uploadedFilesCount === 0) return '';

        const avgTimePerFile = totalTimeTaken / uploadedFilesCount;
        const remainingFiles = totalFilesCount - uploadedFilesCount;
        const estimatedTimeLeft = avgTimePerFile * remainingFiles;

        const minutes = Math.floor(estimatedTimeLeft / 60000);
        const seconds = Math.floor((estimatedTimeLeft % 60000) / 1000);
        return `${minutes}m ${seconds}s`;
    };

    const onUploadFile = async (
        file: File,
        data: GetFileUploadUrlResponse,
        index: number,
        totalFilesCount: number
    ) => {
        // setFileLoading(true);
        try {
            const arrayBuffer = await file.arrayBuffer();
            const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);

            // Generate MD5 hash
            const md5 = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex);

            // Convert file to Base64
            const base64String = btoa(md5);

            const base64FileName = encodeURI(file.name);
            const binaryData = await binaryString(file);

            const response = await axios.put(data.damuploadpath, binaryData, {
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'x-ms-tags': `Content-Md5=${base64String.replace('=', '')}`,
                    // 'x-ms-meta-FileName': encodeURI(fileName),
                    'x-ms-meta-FileName': base64FileName,
                    MetadataUpdated: true,
                    IndexTagUpdated: true,
                },
            });
            console.log('getFileList', response);
            setOpenUploadModal(true);
            const timeTaken = Date.now() - startTime;
            const totalTimeTaken = timeTaken * (index + 1);

            const estimatedTimeLeft = estimateTimeLeft(
                index + 1,
                totalFilesCount,
                totalTimeTaken
            );
            setTimeLeft(estimatedTimeLeft);

            // setFiles([...files, file]);
            getFileList();
        } catch (error) {
            console.log('getFileList', error);
        }
    };

    const allowedTypes = [
        '.jpeg',
        '.jpg',
        '.png',
        '.gif',
        '.bmp',
        '.tiff',
        '.pdf',
        '.html',
        '.xls',
        '.xlsx',
        '.ppt',
        '.pptx',
        '.doc',
        '.docx',
        '.pub',
        '.txt',
    ];

    const onCancel = () => {
        setFileProgress(0);
        setTimeLeft('');
        setFileErrorMsg('');
        setOpenUploadModal(false);
        setFiles([]);
    };

    const handleFilesChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // setOpenUploadModal(true);
        if (event.target.files) {
            setFileLoading(true);
            setFileProgress(0);
            setTimeLeft('');
            const selectedFiles = Array.from(event.target.files);
            const validFiles: File[] = [];
            const inValidFiles: string[] = [];

            selectedFiles.forEach((file) => {
                console.log('selectedFiles', file);
                if (
                    allowedTypes.some((type) => {
                        console.log(
                            'selectedFiles',
                            file.name.includes(type),
                            type
                        );
                        return file.name.endsWith(type);
                    })
                ) {
                    validFiles.push(file);
                } else {
                    inValidFiles.push(file.name);
                }
            });
            if (inValidFiles.length > 0) {
                setFileErrorMsg(
                    `These files are not allowed and were removed: ${inValidFiles.join(
                        ', '
                    )}`
                );
            }
            setFiles([...validFiles]);

            await validFiles.reduce(async (prevPromise, file, index) => {
                await prevPromise;

                const fileSize = file.size / 1024 / 1024;

                if (fileSize <= 10) {
                    try {
                        setCurrentFileIndex(index + 1);
                        const data = await getFileUploadPath();
                        await onUploadFile(
                            file,
                            data,
                            index,
                            validFiles.length
                        );
                    } catch (error) {
                        console.error(
                            `Error uploading file ${file.name}:`,
                            error
                        );
                    }
                } else {
                    console.warn(
                        `File ${file.name} exceeds size limit and was skipped.`
                    );
                }
            }, Promise.resolve());

            setTimeout(() => {
                onCancel();
            }, 2000);
            setFileLoading(false);
            // setCurrentFileIndex(-1); // Reset current index
        }
    };

    const togglePrintDialog = () => {
        seIsPrintSuccessDialogOpen(!isPrintSuccessDialogOpen);
    };
    const onPrintClick = () => {
        togglePrintDialog();
    };

    const onClickFileAction = (file: DocumentsProps | null) => {
        setSelectedDocument((preFile) =>
            preFile?.name === file?.name ? null : file
        );
    };

    return (
        <YourFilesContextModule.Provider
            value={{
                selectedFileTypeTab,
                currentFileIndex,
                openUploadModal,
                isScanQrOpen,
                isCameraOpen,
                isPrintSuccessDialogOpen,
                fileProgress,
                fileStatus,
                fileLoading,
                fileListLoading,
                isQrScanSuccess,
                files,
                documentsList,
                selectedDocument,
                showFileOption,
                selectedSortBy,
                searchDocsFile,
                fileErrorMsg,
                timeLeft,
                setShowFileOption,
                setSelectedSortBy,
                setSearchDocsFile,
                setIsScanQrOpen,
                setIsCameraOpen,
                seIsPrintSuccessDialogOpen,
                handleTabChange,
                handleFilesChange,
                onCancel,
                togglePrintDialog,
                onPrintClick,
                getFileList,
                onClickFileAction,
                onUploadFile,
            }}
        >
            {children}
        </YourFilesContextModule.Provider>
    );
}
