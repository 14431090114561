import pdfIcon from '../../../assets/fileTypeIcon/pdf.svg'
import docIcon from '../../../assets/fileTypeIcon/doc.svg'
import htmlIcon from '../../../assets/fileTypeIcon/html.svg'
import pptIcon from '../../../assets/fileTypeIcon/ppt.svg'
import pubIcon from '../../../assets/fileTypeIcon/pub.svg'
import txtIcon from '../../../assets/fileTypeIcon/txt.svg'
import xlsIcon from '../../../assets/fileTypeIcon/xls.svg'
import bmpIcon from '../../../assets/fileTypeIcon/bmp.svg'
import gifIcon from '../../../assets/fileTypeIcon/gif.svg'
import jfifIcon from '../../../assets/fileTypeIcon/jfif.svg'
import jpgIcon from '../../../assets/fileTypeIcon/jpg.svg'
import msgIcon from '../../../assets/fileTypeIcon/msg.svg'
import pngIcon from '../../../assets/fileTypeIcon/png.svg'
import rtfIcon from '../../../assets/fileTypeIcon/rtf.svg'
import tiffIcon from '../../../assets/fileTypeIcon/tiff.svg'
import imgIcon from '../../../assets/images/docsImg.png'

export const fileType=(fileName: string)=> {
    if (fileName.includes('pdf')) {
        return 'pdf';
    }
    if (fileName.includes('pub')) {
        return 'pub';
    }
    if (fileName.includes('txt')) {
        return 'txt';
    }
    if (fileName.includes('html')) {
        return 'html';
    }
    if (fileName.includes('doc') || fileName.includes('docx') || fileName.includes('docm')) {
        return 'doc';
    }
    if (fileName.includes('ppt') || fileName.includes('pptx') || fileName.includes('pptm')) {
        return 'ppt';
    }
    if (fileName.includes('xls') || fileName.includes('xlsx')) {
        return 'xls';
    } 
    if (fileName.includes('jpg') || fileName.includes('jpeg')) {
        return 'jpg';
    } 
    if (fileName.includes('bmp') ) {
        return 'bmp';
    } 
    if (fileName.includes('png') ) {
        return 'png';
    } 
    if (fileName.includes('gif') ) {
        return 'gif';
    } 
    if (fileName.includes('tiff') ) {
        return 'tiff';
    } 
    if (fileName.includes('jfif') ) {
        return 'jfif';
    } 
    if (fileName.includes('rtf') ) {
        return 'rtf';
    } 
    if (fileName.includes('msg') ) {
        return 'msg';
    } 
    return 'photo'
   
}

export const getFileIcon =(file:string)=>{
   
    const type = fileType(file);
        switch (type) {
            case 'pdf':
                return pdfIcon;
            case 'doc':
                return docIcon;
            case 'xls':
                return xlsIcon;
            case 'ppt':
                return pptIcon;
            case 'txt':
                return txtIcon;
            case 'pub':
                return pubIcon;
            case 'html':
                return htmlIcon;
            case 'bmp':
                return bmpIcon;
            case 'jpg':
                return jpgIcon;
            case 'png':
                return pngIcon;
            case 'gif':
                return gifIcon;
            case 'jfif':
                return jfifIcon;
            case 'msg':
                return msgIcon;
            case 'rtf':
                return rtfIcon;
            case 'tiff':
                return tiffIcon;
                default:
                return imgIcon ;
        }
        
    }

