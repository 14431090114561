import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { dummyData, useYourFiles } from 'src/lib/contexts/YourFilesContext';
import IconAddDocs from 'src/components/Icons/IconAddDocs';
import IconAddPhoto from 'src/components/Icons/IconAddPhoto';
import Row from 'src/components/Row';
import DocumentActionCard from '../components/DocumentActionCard';
import LoadingDocumentCard from '../components/LoadingDocumentCard';
import { DocumentsProps } from '../YourFileTypes';
import FIleEmptyState from '../components/FIleEmptyState';

function Card({
    documentsList,
    selectedSortBy,
    selectedFileTypeTab,
}: {
    documentsList: DocumentsProps[];
    selectedSortBy: string;
    selectedFileTypeTab: number;
}) {
    function getSortingDataFromSortBy(data: DocumentsProps[]) {
        if (selectedSortBy === 'File name (A -> Z)') {
            return data.sort((a, b) => {
                const fileNameA = a.properties.filename.toLowerCase();
                const fileNameB = b.properties.filename.toLowerCase();
                if (fileNameA > fileNameB) return 1;
                if (fileNameA < fileNameB) return -1;
                return 0;
            });
        }
        if (selectedSortBy === 'File name (Z -> A)') {
            return data.sort((a, b) => {
                const fileNameA = a.properties.filename.toLowerCase();
                const fileNameB = b.properties.filename.toLowerCase();
                if (fileNameA < fileNameB) return 1;
                if (fileNameA > fileNameB) return -1;
                return 0;
            });
        }
        return data.sort(
            (a, b) =>
                new Date(b.properties.creationtime).getTime() -
                new Date(a.properties.creationtime).getTime()
        );
    }

    function filterFiles(file: DocumentsProps) {
        if (selectedFileTypeTab === 0) {
            return (
                file.properties.filename.includes('doc') ||
                file.properties.filename.includes('html') ||
                file.properties.filename.includes('xls') ||
                file.properties.filename.includes('xlsx') ||
                file.properties.filename.includes('xlsm') ||
                file.properties.filename.includes('ppt') ||
                file.properties.filename.includes('pptx') ||
                file.properties.filename.includes('pptm') ||
                file.properties.filename.includes('doc') ||
                file.properties.filename.includes('docx') ||
                file.properties.filename.includes('docm') ||
                file.properties.filename.includes('pub') ||
                file.properties.filename.includes('txt') ||
                file.properties.filename.includes('pdf')
            );
        }
        return (
            file.properties.filename.includes('jpg') ||
            file.properties.filename.includes('jpeg') ||
            file.properties.filename.includes('jpe') ||
            file.properties.filename.includes('jfif') ||
            file.properties.filename.includes('gif') ||
            file.properties.filename.includes('bmp') ||
            file.properties.filename.includes('tiff') ||
            file.properties.filename.includes('rtf') ||
            file.properties.filename.includes('png') ||
            file.properties.filename.includes('msg')
        );
    }

    if (documentsList.length) {
        const filteredData = documentsList.filter(filterFiles);
        const sortedData = getSortingDataFromSortBy(filteredData);
        return (
            <>
                {sortedData.map((item, index) => (
                    <DocumentActionCard
                        key={`${item.name}`}
                        item={item}
                        isShowBorderBottom={false}
                    />
                ))}
            </>
        );
    }
    return null;
}

function DocumentComponent() {
    const {
        fileListLoading,
        documentsList,
        selectedFileTypeTab,
        searchDocsFile,
        selectedSortBy,
    } = useYourFiles();

    const searchFilterDocuments = documentsList.filter((document) =>
        !searchDocsFile.length
            ? document
            : document.properties?.filename
                  ?.toLowerCase()
                  .includes(searchDocsFile.toLowerCase())
    );

    if (fileListLoading) {
        return (
            <>
                {[...Array(4)].map(() => (
                    <LoadingDocumentCard />
                ))}
            </>
        );
    }
    if (!searchFilterDocuments.length) {
        return (
            <FIleEmptyState
                iconComponent={
                    selectedFileTypeTab === 0 ? (
                        <IconAddDocs />
                    ) : (
                        <IconAddPhoto />
                    )
                }
                title={
                    selectedFileTypeTab === 0
                        ? 'Hey, upload a file to get started.'
                        : 'Hey, upload a photo to get started.'
                }
            />
        );
    }
    return (
        <Card
            documentsList={searchFilterDocuments}
            selectedSortBy={selectedSortBy}
            selectedFileTypeTab={selectedFileTypeTab}
        />
    );
}

function Documents() {
    return (
        <Row direction="column" gap="10px">
            <DocumentComponent />
        </Row>
    );
}

export default Documents;
