/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { motion, AnimatePresence, useScroll } from 'framer-motion';
import { Player } from '@lottiefiles/react-lottie-player';
import logging from 'src/lib/logging';
import useUserInfo from 'src/hooks/useUserInfo';
import { getLoyaltyAccountDetails } from 'src/services/easyRewardsServices';
import { mergeClasses } from 'src/stylesheets/utils';
import layout_classes from 'src/stylesheets/layout_classes.module.scss';
import { numberWithCommas } from 'src/lib/utils/app-utils';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import useRouter from 'src/hooks/useRouter';
import PointHistoryDrawer from 'src/screens/EasyRewards/PointHistoryDrawer';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { eventMetricsMap, useEventsReporter, useGuestMode } from 'src/hooks';
import axiosInstance from '../lib/api-client/custom-axios';
import { rewardsPostLoyaltyAccountURL } from '../services/urls';

import { Coin } from './Icons';
import { Body } from './UI';

const useStyles = makeStyles({
    welcomeMessage: {
        color: '#ffffff',
        fontSize: '0.875rem',
        height: '1.5rem',
        fontWeight: 400,
    },
    container: {
        marginTop: '0.75rem',
        minHeight: '1.5rem',
    },
    SignInBtn: {
        background: '#A40000',
        borderRadius: '16px',
        height: '32px',
        width: '69px',
        color: '#fff',
        '&:hover': {
            background: '#A40000',
        },
    },
    coinIcon: {
        width: '22px',
        height: '22px',
    },
});
export default function WelcomeMessage() {
    const classes = useStyles();
    const { push } = useRouter();
    const { userInfo, userInfoError, isFetchingUserInfo } = useUserInfo();
    const [message, setMessage] = useState('');
    const [showPoints, setShowPoints] = useState(false);
    const [isVariantTypePointInfo, setVariantTypePointInfo] = useState(false);
    const { isGuestModeOn } = useGuestMode();
    const { setGuestModal } = useUserUpdateContext();

    const [currentAnimationStep, setCurrentAnimationStep] = useState(0);
    const [loyaltyData, setLoyaltyData] = useState<any>({});
    const { isPointHistoryDrawerOpen, setPointHistoryDrawer } =
        useUserUpdateContext();
    const { analyticsCustomEvent } = useEventsReporter();

    useEffect(() => {
        if (userInfo && userInfo.firstName) {
            setMessage(`Hi, ${userInfo.firstName}`);
            setTimeout(() => setShowPoints(true), 2000);
        } else if (!isFetchingUserInfo) {
            setMessage('Welcome back!');
            setTimeout(() => setShowPoints(true), 2000);
        }
    }, [userInfo, isFetchingUserInfo]);

    const { data: pointsData } = getLoyaltyAccountDetails({
        config: {
            enabled: !!userInfo?.rewardsNumber,
        },
        rewardsNumber: userInfo?.rewardsNumber,
    });
    const handlePointsBtnClick = () => {
        if (userInfo && !userInfo.rewardsNumber) {
            push({
                pathname: REWARDS_ENROLLMENT_ROUTES.welcome,
                state: {
                    isNonMigrated: true,
                },
            });
        } else {
            setPointHistoryDrawer(true);
        }
    };
    const handleSignIn = () => {
        setGuestModal({ open: true });
        analyticsCustomEvent(eventMetricsMap.home, {
            click_text: 'sign in',
            element_location: `guest mode`,
        });
    };
    const hanRewardsWorks = (bool: boolean) => {
        setVariantTypePointInfo(bool);
    };
    useEffect(() => {
        if (userInfoError) {
            logging.log('error fetching user info');
            logging.log(userInfoError);
        }
    }, [userInfoError]);
    useEffect(() => {
        if (userInfo) {
            const loyaltyPayload = {
                accountIdentifier: userInfo?.rewardsNumber,
                identifierType: 'REWARDS',
            };
            axiosInstance
                .post(`${rewardsPostLoyaltyAccountURL}`, loyaltyPayload)
                .then((loyaltyRes: any) => {
                    const accounts = loyaltyRes?.data?.accounts;
                    setLoyaltyData(
                        accounts && accounts[0].walletinfo?.balances
                    );
                })
                .catch((error) => {
                    setLoyaltyData({});
                });
        }
    }, [userInfo]);

    if (isGuestModeOn) {
        return (
            <Button onClick={handleSignIn} className={classes.SignInBtn}>
                Sign in
            </Button>
        );
    }

    if (isFetchingUserInfo && !message) {
        return (
            <Box className={classes.container}>
                <Skeleton
                    animation="wave"
                    variant="rect"
                    width="100%"
                    height="1.5rem"
                />
            </Box>
        );
    }

    const animationElements = [
        <motion.span
            className={mergeClasses([
                layout_classes.flex,
                layout_classes.colGap2,
                layout_classes.alignCenter,
            ])}
            key={0}
            initial={{ opacity: 1 }}
            tabIndex={1}
            aria-label={`Hi, ${userInfo?.firstName}. You currently have ${
                pointsData?.accounts[0]?.walletinfo?.balances?.usable &&
                numberWithCommas(
                    pointsData?.accounts[0]?.walletinfo?.balances?.usable?.toString()
                )
            }} Easy Rewards points`}
            animate={{
                opacity: 1,
                transition: { duration: 3 },
            }}
            onAnimationComplete={() => setCurrentAnimationStep(1)}
        >
            Hi {userInfo?.firstName}
        </motion.span>,
        <motion.span
            className={mergeClasses([
                layout_classes.flex,
                layout_classes.colGap2,
                layout_classes.alignCenter,
            ])}
            key={1}
            tabIndex={1}
            aria-label={`Hi, ${userInfo?.firstName}. You currently have ${
                pointsData?.accounts[0]?.walletinfo?.balances?.usable &&
                numberWithCommas(
                    pointsData?.accounts[0]?.walletinfo?.balances?.usable?.toString()
                )
            }} Easy Rewards points`}
            initial={{ opacity: 0 }}
            animate={{
                opacity: 1,
                transition: { duration: 0.5 },
            }}
        >
            {pointsData?.accounts[0]?.walletinfo?.balances?.usable &&
                numberWithCommas(
                    pointsData?.accounts[0]?.walletinfo?.balances?.usable?.toString()
                )}{' '}
            pts
            <Player
                onEvent={(event) => {
                    if (event === 'complete') {
                        setCurrentAnimationStep(2);
                    }
                }}
                autoplay
                src={require('../assets/lotties/header_icon_coin_animated.json')}
                className={classes.coinIcon}
            />
        </motion.span>,
        <motion.span
            className={mergeClasses([
                layout_classes.flex,
                layout_classes.colGap2,
                layout_classes.alignCenter,
            ])}
            key={2}
            tabIndex={1}
            aria-label={`Hi, ${userInfo?.firstName}. You currently have ${
                pointsData?.accounts[0]?.walletinfo?.balances?.usable &&
                numberWithCommas(
                    pointsData?.accounts[0]?.walletinfo?.balances?.usable?.toString()
                )
            }} Easy Rewards points`}
            initial={{ opacity: 0 }}
            animate={{
                opacity: 1,
                transition: { duration: 0.5 },
            }}
        >
            <span style={{ color: 'lightgreen' }}>
                ${loyaltyData?.usablevalue}
            </span>

            <Player
                onEvent={(event) => {
                    if (event === 'complete') {
                        setCurrentAnimationStep(1);
                    }
                }}
                autoplay
                src={require('../assets/lotties/header_icon_coin_animated.json')}
                className={classes.coinIcon}
            />
        </motion.span>,
    ];

    return showPoints && pointsData ? (
        <>
            <AnimatePresence>
                <span
                    className={mergeClasses([
                        layout_classes.flex,
                        layout_classes.colGap2,
                        layout_classes.alignCenter,
                    ])}
                >
                    <motion.button
                        onClick={handlePointsBtnClick}
                        layout
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#A40000',
                            borderRadius: '800px',
                            padding: '6px',
                            border: 'none',
                            fontFamily: 'StaplesNormsMedium',
                            color: 'white',
                            height: 34,
                            fontSize: 15,
                        }}
                    >
                     {animationElements[currentAnimationStep]}
                    </motion.button>
                </span>
            </AnimatePresence>
            {isPointHistoryDrawerOpen ? (
                <PointHistoryDrawer
                    open={isPointHistoryDrawerOpen}
                    setOpen={setPointHistoryDrawer}
                    variant={
                        pointsData?.accounts[0]?.walletinfo?.balances
                            ?.lifetime > 0 && !isVariantTypePointInfo
                            ? 'points-history'
                            : 'points-info'
                    }
                    hanRewardsWorks={hanRewardsWorks}
                />
            ) : null}
        </>
    ) : (
        <AnimatePresence>
            <span
                className={mergeClasses([
                    layout_classes.flex,
                    layout_classes.colGap2,
                    layout_classes.alignCenter,
                ])}
            >
                <motion.button
                    onClick={handlePointsBtnClick}
                    layout
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#A40000',
                        borderRadius: '800px',
                        padding: '6px',
                        border: 'none',
                        fontFamily: 'StaplesNormsMedium',
                        color: 'white',
                        height: 34,
                        fontSize: 15,
                    }}
                >
                    {animationElements[currentAnimationStep]}
                </motion.button>
            </span>
        </AnimatePresence>
    );
}
