/* eslint-disable max-len */
import React from 'react';

function IconAddPhoto() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="62"
            viewBox="0 0 70 62"
            fill="none"
        >
            <g clipPath="url(#clip0_1212_3031)">
                <path
                    d="M45.8611 0H7.41467C3.32836 0 0 3.32562 0 7.41467V45.8611C0 49.9502 3.32836 53.2758 7.41467 53.2758H37.1338L36.4994 51.4057L36.3511 49.4311H7.41467C5.44841 49.4311 3.84465 47.8301 3.84465 45.8611V38.4904L15.645 26.6928C16.8231 25.5147 18.7399 25.5147 19.9153 26.6928L29.1232 35.9007L27.2585 37.7654C26.5088 38.5151 26.5088 39.7344 27.2585 40.4841C27.6348 40.8603 28.1263 41.0471 28.6179 41.0471C29.1095 41.0471 29.6038 40.8603 29.9772 40.4841L33.8384 36.6257L36.2523 34.2118L38.625 31.8364C39.6191 30.8423 41.1377 30.6858 42.2993 31.3696L45.0373 28.6042C43.0353 27.0031 40.3358 26.6901 38.0592 27.6622C37.2766 27.9945 36.5434 28.4806 35.9062 29.1177L33.5335 31.4904H33.5308L31.8419 33.182L22.634 23.9741C19.9592 21.2966 15.6038 21.2966 12.9262 23.9741L3.84465 33.0557V7.41467C3.84465 5.44566 5.44841 3.84465 7.41467 3.84465H45.8611C47.8301 3.84465 49.4311 5.44566 49.4311 7.41467V21.552C50.2056 21.4531 50.9937 21.4009 51.7956 21.4009C52.2927 21.4009 52.787 21.4202 53.2758 21.4614V7.41467C53.2758 3.32562 49.9502 0 45.8611 0Z"
                    fill="white"
                />
                <path
                    d="M30.7654 20.4947C33.0404 20.4947 34.8846 18.6504 34.8846 16.3754C34.8846 14.1004 33.0404 12.2562 30.7654 12.2562C28.4904 12.2562 26.6461 14.1004 26.6461 16.3754C26.6461 18.6504 28.4904 20.4947 30.7654 20.4947Z"
                    fill="white"
                />
                <path
                    d="M58.2051 41.8023H53.7206V37.3178C53.7206 36.255 52.861 35.3954 51.7983 35.3954C50.7355 35.3954 49.8759 36.255 49.8759 37.3178V41.8023H45.3914C44.3287 41.8023 43.4691 42.6618 43.4691 43.7246C43.4691 44.7874 44.3287 45.6469 45.3914 45.6469H49.8759V50.1314C49.8759 51.1942 50.7355 52.0537 51.7983 52.0537C52.861 52.0537 53.7206 51.1942 53.7206 50.1314V45.6469H58.2051C59.2679 45.6469 60.1274 44.7874 60.1274 43.7246C60.1274 42.6618 59.2679 41.8023 58.2051 41.8023Z"
                    fill="white"
                />
                <path
                    d="M51.7956 25.5202C41.7418 25.5202 33.5912 33.6708 33.5912 43.7246C33.5912 53.7783 41.7418 61.929 51.7956 61.929C61.8493 61.929 70 53.7783 70 43.7246C70 33.6708 61.8493 25.5202 51.7956 25.5202ZM51.7956 58.2793C43.7575 58.2793 37.2409 51.7627 37.2409 43.7246C37.2409 35.6865 43.7575 29.1699 51.7956 29.1699C59.8336 29.1699 66.3503 35.6865 66.3503 43.7246C66.3503 51.7627 59.8336 58.2793 51.7956 58.2793Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1212_3031">
                    <rect width="70" height="61.9262" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconAddPhoto;
