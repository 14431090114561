/* eslint-disable arrow-body-style */
/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
import React, { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { GUTTER_WIDTH, GUTTER_WIDTH_SLIM } from 'src/theme';
import COLORS from 'src/lib/colors';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import FullWidthCard from './cards/full-width-card';
import Carousel from './Carousel';
import { Body, Heading } from './UI';

const useStyles = makeStyles({
    localImpactContainer: {
        position: 'relative',
        marginTop: '-21px',
    },
    recycle: {
        marginLeft: '1rem',
        marginRight: '1rem',
        justifyContent: 'center',
        borderRadius: '0 0 15px 15px',
        marginBottom: '2rem',
        '& > div': {
            padding: 0,
        },
    },
    recycleWrapper: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
    },
    titleWrapper: {
        backgroundColor: '#81C671',
        minHeight: '4.75rem',
        color: 'white',
        textAlign: 'left',
    },
    title: {
        fontWeight: 700,
        fontSize: '1.125rem',
        marginTop: '1rem',
        padding: '0 1rem',
        color: '#81C671',
        userSelect: "none",
    },
    mainTitle: {
        color: COLORS.primaryWhite,
        userSelect: "none",
    },
    subTitle: {
        fontSize: '0.75rem',
        fontWeight: 400,
        padding: '0 1rem',
        userSelect: "none",
    },
    innerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem 0',
        '& div': {
            boxShadow: 'none',
        },
    },
    recycleLeafIcon: {
        alignItems: 'center',
    },
    recycleText: {
        fontSize: '1.06rem',
        fontWeight: 500,
    },
    unitCount: {
        color: '#81C671',
        fontSize: '1.43rem',
        fontWeight: 700,
        textAlign: 'right',
    },
    unitText: {
        fontSize: '0.81rem',
        fontWeight: 400,
        textAlign: 'right',
        lineHeight: 0,
    },
    ellipsesIcon: {
        marginTop: '0.7rem',
    },
    contentText: {
        paddingLeft: '1rem !important',
    },
    infoWrapper: {
        borderTop: '1px dotted lightgray',
    },
    accordionIcon: {
        textAlign: 'right',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '9px',
    },
});
export type ImpactProps = {
    id: number;
    img: string;
    content: string;
    count: any;
    unit: string;
    infoText: string;
    uniqueId: string;
};
export function ImpactBox(props: any) {
    const { id, img, content, count, unit, infoText, uniqueId } =
        props.progressData;
    const classes = useStyles();
    const { customEvent } = useEventsReporter();

    const convertToInternationalCurrencySystem = (num: number) => {
        return Math.abs(Number(num)) >= 1.0e9
            ? Math.round(Math.abs(Number(num)) / 1.0e9) + 'B'
            : Math.abs(Number(num)) >= 1.0e6
            ? Math.round(Math.abs(Number(num)) / 1.0e6) + 'M'
            : Math.abs(Number(num)) >= 1.0e3
            ? Math.round(Math.abs(Number(num)) / 1.0e3) + 'k'
            : Math.round(Math.abs(Number(num)));
    };
    const toggleSlideBox = () => {
        customEvent(eventMetricsMap.recycle_impact_clickfordetail);
    };
    return (
        <div className={classes.innerWrapper} key={id}>
            <Accordion>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id={`panel-header-${uniqueId}`}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <img
                                className={classes.recycleLeafIcon}
                                src={img}
                                alt="recycle leaf icon"
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.contentText}>
                            <Typography className={classes.recycleText}>
                                {content}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography className={classes.unitCount}>
                                        {convertToInternationalCurrencySystem(
                                            count
                                        )}
                                    </Typography>
                                    <Typography className={classes.unitText}>
                                        {unit}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.accordionIcon}
                                >
                                    <img
                                        onClick={toggleSlideBox}
                                        src={require('../assets/icons/iconRecycleElipses.svg')}
                                        alt="elipses icon"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={1} className={classes.ellipsesIcon}>
              <img onClick={toggleSlideBox} src={require('../assets/icons/iconRecycleElipses.svg')} alt="elipses icon" />
            </Grid> */}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.infoWrapper}>
                    <Grid container>
                        <Typography className={classes.subTitle}>
                            {infoText}
                        </Typography>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function LocalImpact(props: any) {
    const { recycleData } = props;
    const classes = useStyles();
    const stepData = [
        {
            title: 'Your local impact',
            recyleLocal: [
                {
                    id: 1,
                    img: require('../assets/icons/iconRecycleLeaf.svg'),
                    content: 'Greenhouse gas emissions reduced',
                    count: Math.round(recycleData?.storeContribution),
                    unit: 'tons',
                    infoText:
                        'Greenhouse gases warm our planet by absorbing energy and reducing these emissions can help slow climate change.',
                    uniqueId: 'greenhouse_gas_local_impact',
                },
            ],
            recyleProgress: [
                {
                    id: 1,
                    img: require('../assets/icons/iconRecycleInkToner.svg'),
                    content: 'Ink & toner cartridges',
                    count: Math.round(recycleData?.localInkandToner),
                    unit: 'units',
                    infoText:
                        'Recycling ink & toner cartridges can save up to 4 kilograms of CO2 and one gallon of oil use per cartridge.',
                    uniqueId: 'ink_toner_local_impact',
                },
                {
                    id: 2,
                    img: require('../assets/icons/iconRecycleTechnology.svg'),
                    content: 'Technology and more',
                    count: Math.round(recycleData?.localEwaste),
                    unit: 'tons',
                    infoText:
                        'An average person generates about 50 pounds of e-waste a year. Recycling electronics helps keep toxic materials out of landfills, allowing metals, plastics and glass to be recovered and reused.',
                    uniqueId: 'technology_more_local_impact',
                },
                {
                    id: 3,
                    img: require('../assets/icons/iconRecycleShredding.svg'),
                    content: 'Shredding',
                    count: Math.round(recycleData?.localShredding),
                    unit: 'tons',
                    infoText:
                        '85 million tons of paper is wasted each year. When compared to new paper, recycled shredded paper uses 35% less water and 75% less pollution.',
                    uniqueId: 'shredding_local_impact',
                },
            ],
            recyleImpact: [
                {
                    id: 1,
                    img: require('../assets/icons/iconRecycleCars.svg'),
                    content: 'Cars off the road',
                    count: Math.round(recycleData?.localCars),
                    unit: '',
                    infoText:
                        'One car emits about 4.6 tons of CO2 into the atmosphere each year. Our impact is equivalent to removing this number of cars off the road each year.',
                    uniqueId: 'cars_off_local_impact',
                },
                {
                    id: 2,
                    img: require('../assets/icons/iconRecycleHousehold.svg'),
                    content: 'Household carbon emissions reduced',
                    count: Math.round(recycleData?.localHousehold),
                    unit: '',
                    infoText:
                        'One US household generates about 7.5 tons of CO2 each year. Our impact is equivalent to a reduction in this number of household emissions.',
                    uniqueId: 'household_carbon_local_impact',
                },
                {
                    id: 3,
                    img: require('../assets/icons/iconRecycleLandfills.svg'),
                    content: 'Waste diverted from landfills',
                    count: Math.round(recycleData?.localLandfill),
                    unit: 'tons',
                    infoText:
                        'Waste diversion is reusing, recycling or composting materials to prevent materials from being disposed of at the landfill. Our impact is equivalent to this amount of waste diverted from landfills.',
                    uniqueId: 'waste_diverted_local_impact',
                },
            ],
        },
        {
            title: 'Nationwide impact',
            recyleLocal: [
                {
                    id: 1,
                    img: require('../assets/icons/iconRecycleLeaf.svg'),
                    content: 'Greenhouse gas emissions reduced',
                    count: Math.round(recycleData?.allStoresContribution),
                    unit: 'tons',
                    infoText:
                        'Greenhouse gases warm our planet by absorbing energy and reducing these emissions can help slow climate change.',
                    uniqueId: 'greenhouse_gas_nationwide_impact',
                },
            ],
            recyleProgress: [
                {
                    id: 1,
                    img: require('../assets/icons/iconRecycleInkToner.svg'),
                    content: 'Ink & toner cartridges',
                    count: Math.round(recycleData?.nationwideInkandToner),
                    unit: 'units',
                    infoText:
                        'Recycling ink & toner cartridges can save up to 4 kilograms of CO2 and one gallon of oil use per cartridge.',
                    uniqueId: 'ink_toner_nationwide_impact',
                },
                {
                    id: 2,
                    img: require('../assets/icons/iconRecycleTechnology.svg'),
                    content: 'Technology and more',
                    count: Math.round(recycleData?.nationwideEwaste),
                    unit: 'tons',
                    infoText:
                        'An average person generates about 50 pounds of e-waste a year. Recycling electronics helps keep toxic materials out of landfills, allowing metals, plastics and glass to be recovered and reused.',
                    uniqueId: 'technology_more_nationwide_impact',
                },
                {
                    id: 3,
                    img: require('../assets/icons/iconRecycleShredding.svg'),
                    content: 'Shredding',
                    count: Math.round(recycleData?.nationwideShredding),
                    unit: 'tons',
                    infoText:
                        '85 million tons of paper is wasted each year. When compared to new paper, recycled shredded paper uses 35% less water and 75% less pollution.',
                    uniqueId: 'shredding_nationwide_impact',
                },
            ],
            recyleImpact: [
                {
                    id: 1,
                    img: require('../assets/icons/iconRecycleCars.svg'),
                    content: 'Cars off the road',
                    count: Math.round(recycleData?.nationwideCars),
                    unit: '',
                    infoText:
                        'One car emits about 4.6 tons of CO2 into the atmosphere each year. Our impact is equivalent to removing this number of cars off the road each year.',
                    uniqueId: 'cars_off_nationwide_impact',
                },
                {
                    id: 2,
                    img: require('../assets/icons/iconRecycleHousehold.svg'),
                    content: 'Household carbon emissions reduced',
                    count: Math.round(recycleData?.nationwideHousehold),
                    unit: '',
                    infoText:
                        'One US household generates about 7.5 tons of CO2 each year. Our impact is equivalent to a reduction in this number of household emissions.',
                    uniqueId: 'household_carbon_nationwide_impact',
                },
                {
                    id: 3,
                    img: require('../assets/icons/iconRecycleLandfills.svg'),
                    content: 'Waste diverted from landfills',
                    count: Math.round(recycleData?.nationwideLandfill),
                    unit: 'tons',
                    infoText:
                        'Waste diversion is reusing, recycling or composting materials to prevent materials from being disposed of at the landfill. Our impact is equivalent to this amount of waste diverted from landfills.',
                    uniqueId: 'waste_diverted_nationwide_impact',
                },
            ],
        },
    ];

    return (
        <div className={classes.localImpactContainer} id='localImpactContainer'>
            <Carousel
                stepData={stepData}
                content={stepData.map((step) => (
                    <FullWidthCard
                        hasManualShadow
                        className={classes.recycle}
                        id="Recycle-local-impact-summary-card"
                    >
                        <div className={classes.recycleWrapper}>
                            <div className={classes.titleWrapper}>
                                <Heading
                                    type="h3"
                                    weight="bold"
                                    size={20}
                                    className={`${classes.title} ${classes.mainTitle}`}
                                >
                                    {step.title}
                                </Heading>
                                <Body
                                    size={14}
                                    weight="light"
                                    padding={'0 1rem'}
                                    className={classes.subTitle}
                                >
                                    Since 2021
                                </Body>
                            </div>
                            {step?.recyleLocal.map((item: ImpactProps) => (
                                <ImpactBox key={item.id} progressData={item} />
                            ))}
                            <Typography
                                className={classes.title}
                                id="Recycle-local-impact-title"
                            >
                                Recycling progress
                            </Typography>
                            {step?.recyleProgress.map((item: ImpactProps) => (
                                <ImpactBox key={item.id} progressData={item} />
                            ))}
                            <Typography
                                className={classes.title}
                                id="Recycle-local-impact-title"
                            >
                                Environmental impact
                            </Typography>
                            {step?.recyleImpact.map((item: ImpactProps) => (
                                <ImpactBox key={item.id} progressData={item} />
                            ))}
                        </div>
                    </FullWidthCard>
                ))}
                dots={stepData.length}
                dotPosition="top"
                activeColor={COLORS.primaryWhite}
                dotSizeStyle={{
                    height: '0.43rem',
                }}
                dotStyle={{
                    position: 'absolute',
                    top: '1rem',
                    zIndex: 9,
                    right: '3rem',
                    alignItems: 'flex-end',
                    justifyContent: 'right',
                }}
            />
        </div>
    );
}
