import { Box, ButtonBase } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import Row from 'src/components/Row';
import { Body } from 'src/components/UI';
import COLORS from 'src/lib/colors';
import { useYourFiles } from 'src/lib/contexts/YourFilesContext';

function PrintingCompleted() {
    const { togglePrintDialog } = useYourFiles();
    return (
        <Row
            gap="20px"
            direction="column"
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                mt: '50px',
            }}
        >
            <>
                <img
                    src={require('../../../assets/images/CheckWithCircle.png')}
                    alt=""
                />
                <Body
                    size={20}
                    weight="bold"
                    lineHeight={26}
                    textAlign="textCenter"
                >
                    Your [file-name] has been printed successfully.
                </Body>
                <ButtonBase
                    style={{
                        marginTop: '10px',
                        alignSelf: 'center',
                        backgroundColor: 'black',
                        color: 'white',
                        lineHeight: '2.5rem',
                        borderRadius: '2rem',
                        width: '255px',
                        height: '46px',
                        fontWeight: 500,
                        fontSize: 18,
                        letterSpacing: '0',
                        userSelect: 'none',
                    }}
                    onClick={togglePrintDialog}
                >
                    Print another file
                </ButtonBase>
            </>
        </Row>
    );
}

export default PrintingCompleted;
