import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import IconUploadFile from 'src/components/Icons/IconUploadFile';
import Row from 'src/components/Row';
import { Body } from 'src/components/UI';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles((theme) => ({
    uploadFileBox: {
        border: `1px dashed ${COLORS.linkText}`,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '10px',
        cursor: 'pointer',
        minHeight: 150,
        backgroundColor: 'white',
        margin: '1px',
    },
}));

interface IUploadFileSectionProps {
    fileAccept: string;
    supportedText: string;
}

function UploadFileSection({
    fileAccept,
    supportedText,
}: IUploadFileSectionProps) {
    const classes = useStyles();
    return (
        <label htmlFor="uploadFile" className={classes.uploadFileBox}>
            <Box>
                <IconUploadFile />
            </Box>
            <Body size={14} lineHeight={18} color="textLinkBlue">
                Upload files
            </Body>
            <Row direction="column">
                <>
                    <Body textAlign="textCenter" size={12} weight="light">
                        Maximum file size up to 100MB
                    </Body>
                    <Body textAlign="textCenter" size={12} weight="light">
                        {supportedText}
                    </Body>
                </>
            </Row>
            <input
                type="file"
                name=""
                id="uploadFile"
                hidden
                accept={fileAccept}
            />
        </label>
    );
}

export default UploadFileSection;
