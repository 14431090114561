/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import axiosInstance from 'src/lib/api-client/custom-axios';
import QRCode from 'react-qr-code';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import moment from 'moment';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import FullWidthCard from '../../cards/full-width-card';
import { Body, Heading } from '../../UI';

const useStyles = makeStyles((theme) => ({
    trackingContainer: {
        margin: '24px 23px',
        minHeight: '240px',
        '& .MuiCardContent-root': {
            padding: '15px !important',
        },
    },
    shippedTrackingContainer: {
        margin: '24px 23px',
        '& .MuiCardContent-root': {
            padding: '15px !important',
        },
    },
    deliveredTrackingContainer: {
        margin: '24px 23px',
        '& .MuiCardContent-root': {
            padding: '15px !important',
        },
    },
    labelScannerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '272px',
        alignItems: 'center',
    },
    scannedLabelText: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#1E1E1E',
        letterSpacing: '0',
    },
    cardMainText: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#1E1E1E',
        letterSpacing: '0',
        marginBottom: '22px',
    },
    shipmentNumberAlign: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#1E1E1E',
        letterSpacing: '0',
        marginBottom: '5px',
    },
    cardStatus: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '34px',
        letterSpacing: '-1px',
        color: '#1E1E1E',
        marginTop: '15px',
    },
    cardLableText: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0',
        color: '#101820',
    },
    barcodeAlign: {
        marginBottom: '14px',
    },
    shippedTrackingCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '234px',
        alignItems: 'center',
    },
    shippedTrackingName: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    shippedTrackingNumber: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '18.88px',
        letterSpacing: '0px',
        color: '#101820',
        marginBottom: '6px',
    },
    shippedName: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '-1px',
        color: '#1E1E1E',
        marginTop: '4px',
    },
    shippedTrackingAddress: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#101820',
    },
    shippedTrackingDate: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        color: '#858585',
        marginBottom: '24px',
    },
    shippedTrackingLink: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#086DD2',
    },
    deliveredTrackingName: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '33.75px',
        letterSpacing: '-1px',
        color: '#1E1E1E',
    },
    deliveredTrackingImg: {
        width: '100%',
        padding: '15px 0',
    },
    deliveredPackage: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '33.75px',
        letterSpacing: '-1px',
        color: '#1E1E1E',
    },
    deliveredTrackingAddress: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    deliveredTrackingDate: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        color: '#858585',
        marginTop: '8px',
    },
    deliveredTrackingLink: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#086DD2',
        marginBottom: '10px',
    },
    deliveredTrackingNumberName: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    deliveredTrackingNumber: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '19px',
        letterSpacing: '0px',
        color: '#086DD2',
    },
    deliveredTrackingConatiner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    pkgConatiner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: '15px',
    },
    trackingNumber: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
}));

const ShippingServicesTrackingCards = ({ data, activeTab }: any) => {
    const classes = useStyles();
    const { shippmentNumber, date, packagedetails, shimpmentstatus } = data;
    const status = data?.shimpmentstatus?.shipmentstatus;
    const pkgStatus = data?.shimpmentstatus?.shipmenttrackingstatus;
    const trackingNumber = data?.shimpmentstatus?.shipmenttrackingnumbers[0];
    const address = data?.recipientdetails?.street1;
    const [responseData, setResponseData] = useState({});
    const [allDelivered, setAllDelivered] = useState(true);
    const { analyticsCustomEvent } = useEventsReporter();
    // eslint-disable-next-line
    const handleUPSNavigation = (index: any) => {
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'tracking_number',
            element_location: 'qr page',
        });
        return () =>
            openInNewTab(
                `https://www.ups.com/track?loc=en_US&tracknum=${shimpmentstatus?.shipmenttrackingnumbers[index]}`
            );
    };
    return (
        <>
            {data?.shimpmentstatus?.shipmentnumber && activeTab === 'create' ? (
                <FullWidthCard className={classes.trackingContainer}>
                    <Box className={classes.cardContainer}>
                        <Typography className={classes.cardMainText}>
                            Your shipment number is
                        </Typography>
                        <Typography className={classes.shipmentNumberAlign}>
                            {data?.shimpmentstatus?.shipmentnumber}
                        </Typography>
                        <QRCode
                            size={92}
                            value={data?.shimpmentstatus?.shipmentnumber}
                        />
                        <Body
                            size={12}
                            weight="bold"
                            lineHeight={16}
                            color="textMediumGray"
                            margin="5px 0 0"
                        >
                            Associate instruction
                        </Body>
                        <Body
                            size={10}
                            weight="light"
                            lineHeight={14}
                            color="textMediumGray"
                        >
                            Scan QR code in the Ship Services PC to start a
                            shipping label.
                        </Body>
                        <Typography className={classes.cardStatus}>
                            Status
                        </Typography>
                        <Typography className={classes.cardLableText}>
                            Label created
                        </Typography>
                    </Box>
                </FullWidthCard>
            ) : null}

            {data?.shipmenttype.toLowerCase() === 'tracking' &&
            data.shimpmentstatus.shipmenttrackingstatus[0].package[0].activity
                .status.statusCode === '003' &&
            activeTab === 'create' ? (
                <FullWidthCard className={classes.shippedTrackingContainer}>
                    <Box className={classes.labelScannerContainer}>
                        <Heading type="h1" size={30} weight="bold">
                            Label Scanned
                        </Heading>
                        <Typography className={classes.scannedLabelText}>
                            at {data?.recipientdetails?.city},{' '}
                            {data?.recipientdetails?.state}
                        </Typography>
                        <Typography className={classes.deliveredTrackingDate}>
                            {`${moment(data?.shipmentcreateddate).format(
                                'MMM DD, YYYY'
                            )}`}
                        </Typography>
                        <Box
                            className={classes.trackingNumber}
                            style={{ marginTop: '20px' }}
                        >
                            <Typography
                                className={classes.deliveredTrackingNumberName}
                            >
                                Your tracking number is
                            </Typography>
                            <ButtonBase
                                className={classes.deliveredTrackingNumber}
                                onClick={handleUPSNavigation(0)}
                            >
                                {
                                    data.shimpmentstatus
                                        ?.shipmenttrackingnumbers[0]
                                }
                            </ButtonBase>
                        </Box>
                    </Box>
                </FullWidthCard>
            ) : null}

            {shimpmentstatus?.shipmenttrackingnumbers?.map(
                (_: any, index: any) => (
                    <>
                        {activeTab === 'shipped' ? (
                            <FullWidthCard
                                className={classes.shippedTrackingContainer}
                            >
                                {pkgStatus[index]?.package[0]?.activity?.status
                                    ?.statusCode === '003' ? (
                                    <Box
                                        className={
                                            classes.deliveredTrackingConatiner
                                        }
                                    >
                                        <Typography
                                            className={classes.shippedName}
                                        >
                                            Shipped
                                        </Typography>
                                        <img
                                            className={
                                                classes.deliveredTrackingImg
                                            }
                                            src={require('../../../assets/icons/shipped-pkg.svg')}
                                            alt="shipping-shipped"
                                        />

                                        <Box className={classes.pkgConatiner}>
                                            {data?.shipmenttype ===
                                            'APPSHIPMENT' ? (
                                                <span>
                                                    <Typography
                                                        className={
                                                            classes.deliveredPackage
                                                        }
                                                    >
                                                        {
                                                            packagedetails[
                                                                index
                                                            ]?.description
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.deliveredTrackingAddress
                                                        }
                                                    >
                                                        {address}
                                                    </Typography>
                                                </span>
                                            ) : (
                                                <span>
                                                    <Typography
                                                        className={
                                                            classes.deliveredPackage
                                                        }
                                                    >
                                                        Scanned package
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.deliveredTrackingAddress
                                                        }
                                                    >
                                                        {data?.shimpmentstatus
                                                            ? `${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.city}, 
                                        ${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.stateProvince}`
                                                            : null}
                                                    </Typography>
                                                </span>
                                            )}
                                            <Typography
                                                className={
                                                    classes.deliveredTrackingDate
                                                }
                                            >
                                                {`${moment(date).format(
                                                    'MMM DD, YYYY'
                                                )}`}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.trackingNumber}>
                                            <Typography
                                                className={
                                                    classes.deliveredTrackingNumberName
                                                }
                                            >
                                                Your tracking number is
                                            </Typography>
                                            <ButtonBase
                                                className={
                                                    classes.deliveredTrackingNumber
                                                }
                                                onClick={handleUPSNavigation(
                                                    index
                                                )}
                                            >
                                                {
                                                    shimpmentstatus
                                                        ?.shipmenttrackingnumbers[
                                                        index
                                                    ]
                                                }
                                            </ButtonBase>
                                        </Box>
                                    </Box>
                                ) : null}

                                {pkgStatus[index]?.package[0]?.activity?.status
                                    ?.statusCode === '021' ? (
                                    <Box
                                        className={
                                            classes.deliveredTrackingConatiner
                                        }
                                    >
                                        <Typography
                                            className={classes.shippedName}
                                        >
                                            Out for Delivery
                                        </Typography>
                                        <img
                                            className={
                                                classes.deliveredTrackingImg
                                            }
                                            src={require('../../../assets/icons/out-for-delivery-pkg.svg')}
                                            alt="shipping-outFor-delivery"
                                        />
                                        <Box className={classes.pkgConatiner}>
                                            {data?.shipmenttype ===
                                            'APPSHIPMENT' ? (
                                                <span>
                                                    <Typography
                                                        className={
                                                            classes.deliveredPackage
                                                        }
                                                    >
                                                        {
                                                            packagedetails[
                                                                index
                                                            ]?.description
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.deliveredTrackingAddress
                                                        }
                                                    >
                                                        {address}
                                                    </Typography>
                                                </span>
                                            ) : (
                                                <span>
                                                    <Typography
                                                        className={
                                                            classes.deliveredPackage
                                                        }
                                                    >
                                                        Scanned package
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.deliveredTrackingAddress
                                                        }
                                                    >
                                                        {data?.shimpmentstatus
                                                            ? `${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.city}, 
                                        ${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.stateProvince}`
                                                            : null}
                                                    </Typography>
                                                </span>
                                            )}
                                            <Typography
                                                className={
                                                    classes.deliveredTrackingDate
                                                }
                                            >
                                                {`${moment(date).format(
                                                    'MMM DD, YYYY'
                                                )}`}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.trackingNumber}>
                                            <Typography
                                                className={
                                                    classes.deliveredTrackingNumberName
                                                }
                                            >
                                                Your tracking number is
                                            </Typography>
                                            <ButtonBase
                                                className={
                                                    classes.deliveredTrackingNumber
                                                }
                                                onClick={handleUPSNavigation(
                                                    index
                                                )}
                                            >
                                                {
                                                    shimpmentstatus
                                                        ?.shipmenttrackingnumbers[
                                                        index
                                                    ]
                                                }
                                            </ButtonBase>
                                        </Box>
                                    </Box>
                                ) : null}

                                {pkgStatus[index]?.package[0]?.activity?.status
                                    ?.statusCode === '011' ? (
                                    <Box
                                        className={
                                            classes.deliveredTrackingConatiner
                                        }
                                    >
                                        <Typography
                                            className={classes.shippedName}
                                        >
                                            Delivered
                                        </Typography>
                                        <img
                                            className={
                                                classes.deliveredTrackingImg
                                            }
                                            src={require('../../../assets/icons/delivered-pkg.svg')}
                                            alt="shipping-outFor-delivery"
                                        />
                                        <Box className={classes.pkgConatiner}>
                                            {data?.shipmenttype ===
                                            'APPSHIPMENT' ? (
                                                <span>
                                                    <Typography
                                                        className={
                                                            classes.deliveredPackage
                                                        }
                                                    >
                                                        {
                                                            packagedetails[
                                                                index
                                                            ]?.description
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.deliveredTrackingAddress
                                                        }
                                                    >
                                                        {address}
                                                    </Typography>
                                                </span>
                                            ) : (
                                                <span>
                                                    <Typography
                                                        className={
                                                            classes.deliveredPackage
                                                        }
                                                    >
                                                        Scanned package
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.deliveredTrackingAddress
                                                        }
                                                    >
                                                        {data?.shimpmentstatus
                                                            ? `${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.city}, 
                                        ${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.stateProvince}`
                                                            : null}
                                                    </Typography>
                                                </span>
                                            )}
                                            <Typography
                                                className={
                                                    classes.deliveredTrackingDate
                                                }
                                            >
                                                {`${moment(date).format(
                                                    'MMM DD, YYYY'
                                                )}`}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.trackingNumber}>
                                            <Typography
                                                className={
                                                    classes.deliveredTrackingNumberName
                                                }
                                            >
                                                Your tracking number is
                                            </Typography>
                                            <ButtonBase
                                                className={
                                                    classes.deliveredTrackingNumber
                                                }
                                                onClick={handleUPSNavigation(
                                                    index
                                                )}
                                            >
                                                {
                                                    shimpmentstatus
                                                        ?.shipmenttrackingnumbers[
                                                        index
                                                    ]
                                                }
                                            </ButtonBase>
                                        </Box>
                                    </Box>
                                ) : null}

                                {pkgStatus[index]?.package[0]?.activity?.status
                                    ?.statusCode !== '003' &&
                                pkgStatus[index]?.package[0]?.activity?.status
                                    ?.statusCode !== '021' &&
                                pkgStatus[index]?.package[0]?.activity?.status
                                    ?.statusCode !== '011' ? (
                                    <Box
                                        className={
                                            classes.deliveredTrackingConatiner
                                        }
                                    >
                                        <Typography
                                            className={classes.shippedName}
                                        >
                                            In-Transit
                                        </Typography>
                                        <img
                                            className={
                                                classes.deliveredTrackingImg
                                            }
                                            src={require('../../../assets/icons/in-transit-pkg.svg')}
                                            alt="shipping-inTransit"
                                        />
                                        <Box className={classes.pkgConatiner}>
                                            {data?.shipmenttype ===
                                            'APPSHIPMENT' ? (
                                                <span>
                                                    <Typography
                                                        className={
                                                            classes.deliveredPackage
                                                        }
                                                    >
                                                        {
                                                            pkgStatus[index]
                                                                ?.package[0]
                                                                ?.activity
                                                                ?.status
                                                                ?.description
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.deliveredTrackingAddress
                                                        }
                                                    >
                                                        {address}
                                                    </Typography>
                                                </span>
                                            ) : (
                                                <span>
                                                    <Typography
                                                        className={
                                                            classes.deliveredPackage
                                                        }
                                                    >
                                                        Scanned package
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.deliveredTrackingAddress
                                                        }
                                                    >
                                                        {data?.shimpmentstatus
                                                            ? `${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.city},
                                        ${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.stateProvince}`
                                                            : null}
                                                    </Typography>
                                                </span>
                                            )}
                                            <Typography
                                                className={
                                                    classes.deliveredTrackingDate
                                                }
                                            >
                                                {`${moment(date).format(
                                                    'MMM DD, YYYY'
                                                )}`}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.trackingNumber}>
                                            <Typography
                                                className={
                                                    classes.deliveredTrackingNumberName
                                                }
                                            >
                                                Your tracking number is
                                            </Typography>
                                            <ButtonBase
                                                className={
                                                    classes.deliveredTrackingNumber
                                                }
                                                onClick={handleUPSNavigation(
                                                    index
                                                )}
                                            >
                                                {
                                                    shimpmentstatus
                                                        ?.shipmenttrackingnumbers[
                                                        index
                                                    ]
                                                }
                                            </ButtonBase>
                                        </Box>
                                    </Box>
                                ) : null}
                            </FullWidthCard>
                        ) : null}

                        {activeTab === 'delivered' ? (
                            <>
                                {
                                    <FullWidthCard
                                        className={
                                            classes.deliveredTrackingContainer
                                        }
                                    >
                                        <Box
                                            className={
                                                classes.deliveredTrackingConatiner
                                            }
                                        >
                                            <Typography
                                                className={
                                                    classes.deliveredTrackingName
                                                }
                                            >
                                                Delivered
                                            </Typography>
                                            <img
                                                className={
                                                    classes.deliveredTrackingImg
                                                }
                                                src={require('../../../assets/icons/delivered-pkg.svg')}
                                                alt="shipping-delivered"
                                            />
                                            {data?.shipmenttype ===
                                            'APPSHIPMENT' ? (
                                                <>
                                                    <Box
                                                        className={
                                                            classes.pkgConatiner
                                                        }
                                                    >
                                                        <span>
                                                            <Typography
                                                                className={
                                                                    classes.deliveredPackage
                                                                }
                                                            >
                                                                {
                                                                    packagedetails[
                                                                        index
                                                                    ]
                                                                        ?.description
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.deliveredTrackingAddress
                                                                }
                                                            >
                                                                {address}
                                                            </Typography>
                                                        </span>
                                                        <Typography
                                                            className={
                                                                classes.deliveredTrackingDate
                                                            }
                                                        >
                                                            {data?.shimpmentstatus
                                                                ? `${moment(
                                                                      shimpmentstatus
                                                                          ?.shipmenttrackingstatus[
                                                                          index
                                                                      ]
                                                                          ?.package[0]
                                                                          ?.activity
                                                                          ?.date
                                                                  ).format(
                                                                      'MMM DD, YYYY'
                                                                  )}`
                                                                : null}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        className={
                                                            classes.trackingNumber
                                                        }
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.deliveredTrackingNumberName
                                                            }
                                                        >
                                                            Your tracking number
                                                            is
                                                        </Typography>
                                                        <ButtonBase
                                                            className={
                                                                classes.deliveredTrackingNumber
                                                            }
                                                            onClick={handleUPSNavigation(
                                                                index
                                                            )}
                                                        >
                                                            {
                                                                shimpmentstatus
                                                                    ?.shipmenttrackingnumbers[
                                                                    index
                                                                ]
                                                            }
                                                        </ButtonBase>
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    <Box
                                                        className={
                                                            classes.pkgConatiner
                                                        }
                                                    >
                                                        <span>
                                                            <Typography
                                                                className={
                                                                    classes.deliveredPackage
                                                                }
                                                            >
                                                                Scanned package
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.deliveredTrackingAddress
                                                                }
                                                            >
                                                                {data?.shimpmentstatus
                                                                    ? `${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.city}, 
                                        ${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.stateProvince}`
                                                                    : null}
                                                            </Typography>
                                                        </span>
                                                        <Typography
                                                            className={
                                                                classes.deliveredTrackingDate
                                                            }
                                                        >
                                                            {data?.shimpmentstatus
                                                                ? `${moment(
                                                                      shimpmentstatus
                                                                          ?.shipmenttrackingstatus[
                                                                          index
                                                                      ]
                                                                          ?.package[0]
                                                                          ?.activity
                                                                          ?.date
                                                                  ).format(
                                                                      'MMM DD, YYYY'
                                                                  )}`
                                                                : null}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        className={
                                                            classes.trackingNumber
                                                        }
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.deliveredTrackingNumberName
                                                            }
                                                        >
                                                            Your tracking number
                                                            is
                                                        </Typography>
                                                        <ButtonBase
                                                            className={
                                                                classes.deliveredTrackingNumber
                                                            }
                                                            onClick={handleUPSNavigation(
                                                                index
                                                            )}
                                                        >
                                                            {
                                                                shimpmentstatus
                                                                    ?.shipmenttrackingnumbers[
                                                                    index
                                                                ]
                                                            }
                                                        </ButtonBase>
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    </FullWidthCard>
                                }
                            </>
                        ) : null}
                    </>
                )
            )}
        </>
    );
};

export default ShippingServicesTrackingCards;
