import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import moment from 'moment';
import COLORS from 'src/lib/colors';
import Row from 'src/components/Row';
import IconZoom from 'src/components/Icons/IconZoom';
import { useYourFiles } from 'src/lib/contexts/YourFilesContext';
import { Body, Heading } from 'src/components/UI';
import IconPrint from 'src/components/Icons/IconPrint';
import IconDelete from 'src/components/Icons/IconDelete';
import IconPrintPlus from 'src/components/Icons/IconPrintPlus';
import { DocumentsProps } from '../YourFileTypes';
import { getFileIcon } from './getFileIcon';

interface IProps {
    item: DocumentsProps;
    isShowBorderBottom?: boolean;
}

function DocumentActionCard({ item, isShowBorderBottom }: IProps) {
    const { onClickFileAction, selectedDocument } = useYourFiles();
    function getTruncatetext(text: string) {
        const truncateText = text.substring(0, 20);

        if (text.includes('pdf')) {
            return text.length > 20 ? `${truncateText}...pdf` : text;
        }
        if (text.includes('doc')) {
            return text.length > 20 ? `${truncateText}...doc` : text;
        }
        if (text.includes('csv')) {
            return text.length > 20 ? `${truncateText}...csv` : text;
        }
        if (text.includes('jpg')) {
            return text.length > 20 ? `${truncateText}...jpg` : text;
        }
        if (text.includes('jpeg')) {
            return text.length > 20 ? `${truncateText}...jpeg` : text;
        }

        return text.length > 20 ? `${truncateText}...png` : text;
    }

    function renderActionButton(
        icon: JSX.Element,
        text: string
        // onClick: () => void
    ) {
        return (
            <Row
                gap="10px"
                sx={{
                    alignItems: 'center',
                }}
            >
                <>
                    <IconButton
                        style={{
                            padding: '3px',
                        }}
                        // onClick={onClick}
                    >
                        {icon}
                    </IconButton>
                    <Body size={14} weight="light" lineHeight={18}>
                        {text}
                    </Body>
                </>
            </Row>
        );
    }

    return (
        <>
            <Box
                style={{
                    // padding: '16px',
                    background: '#FFFFFF',
                    borderRadius: '16px',
                    ...(isShowBorderBottom && {
                        borderBottom: `1px solid ${COLORS.whiteSmoke}`,
                    }),
                }}
            >
                <Row
                    gap="16px"
                    sx={{
                        alignItems: 'center',
                        p: '16px',
                    }}
                >
                    <>
                        <Box
                            sx={{
                                width: 60,
                                height: 60,
                                position: 'relative',
                            }}
                        >
                            <img
                                src={getFileIcon(item.properties.filename)}
                                alt={'img'}
                                height={'100%'}
                                style={{
                                    borderRadius: '4px',
                                }}
                            />
                            {/* <IconButton
                                style={{
                                    padding: '0px',
                                    position: 'absolute',
                                    bottom: 5,
                                    right: 5,
                                }}
                            >
                                <IconZoom />
                            </IconButton> */}
                        </Box>
                        <Row
                            direction="column"
                            gap="3px"
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            <>
                                <Heading
                                    type="h5"
                                    weight="normal"
                                    size={16}
                                    lineHeight={20}
                                >
                                    {getTruncatetext(item.properties.filename)}
                                </Heading>

                                <Body
                                    color="textGray"
                                    lineHeight={16}
                                    size={12}
                                    weight="light"
                                >
                                    {`Uploaded ${moment(
                                        item.properties.creationtime
                                    ).format('MMM DD')}`}
                                </Body>
                            </>
                        </Row>
                        <Row
                            gap="16px"
                            sx={{
                                alignItems: 'center',
                            }}
                        >
                            <>
                                <IconButton
                                    style={{
                                        padding: '3px',
                                    }}
                                    onClick={() => onClickFileAction(item)}
                                >
                                    <MoreVert />
                                </IconButton>
                            </>
                        </Row>
                    </>
                </Row>
                {selectedDocument && selectedDocument.name === item.name && (
                    <Row direction="column">
                        <>
                            <Box
                                sx={{
                                    borderBottom: `1px solid ${COLORS.lightGrey}`,
                                    p: '16px',
                                }}
                            >
                                {renderActionButton(<IconDelete />, 'Delete')}
                            </Box>
                            <Box
                                style={{
                                    paddingRight: '16px',
                                    paddingLeft: '16px',
                                    paddingTop: '16px',
                                }}
                            >
                                <Box>
                                    <Body weight="bold" size={16}>
                                        More print options
                                    </Body>
                                </Box>
                                <Box
                                    sx={{
                                        borderBottom: `1px solid ${COLORS.lightGrey}`,
                                        paddingBottom: '16px',
                                        paddingTop: '16px',
                                    }}
                                >
                                    {renderActionButton(
                                        <IconPrint />,
                                        'Simple print'
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        paddingBottom: '16px',
                                        paddingTop: '16px',
                                    }}
                                >
                                    {renderActionButton(
                                        <IconPrintPlus />,
                                        'All print options'
                                    )}
                                </Box>
                            </Box>
                        </>
                    </Row>
                )}
            </Box>
        </>
    );
}

export default DocumentActionCard;
