import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import Row from 'src/components/Row';

function LoadingDocumentCard() {
    return (
        <Box
            style={{
                padding: '16px',
                background: '#FFFFFF',
                borderRadius: '16px',
            }}
        >
            <Row
                gap="16px"
                sx={{
                    alignItems: 'center',
                }}
            >
                <>
                    <Box
                        sx={{
                            width: 60,
                            height: 60,
                            position: 'relative',
                        }}
                    >
                        <Skeleton
                            variant="rect"
                            width={60}
                            height={60}
                            style={{ borderRadius: '4px' }}
                        />
                    </Box>
                    <Row
                        direction="column"
                        gap="3px"
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <>
                            <Skeleton
                                variant="text"
                                width="70%"
                                height={20}
                                style={{ borderRadius: '4px' }}
                            />
                            <Skeleton
                                variant="text"
                                width="50%"
                                height={16}
                                style={{ borderRadius: '4px' }}
                            />
                        </>
                    </Row>
                    <Row
                        gap="16px"
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <>
                            <Skeleton variant="circle" width={30} height={30} />
                            <Skeleton variant="circle" width={30} height={30} />
                        </>
                    </Row>
                </>
            </Row>
        </Box>
    );
}

export default LoadingDocumentCard;
