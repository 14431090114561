import { useEffect, useRef } from "react"

const useOutsideClick = (handler: any) => {
    const domNode = useRef<any>()
    useEffect(() => {
        const handleClick = (event: any) => {
            if (domNode.current && !domNode.current.contains(event.target)) {
                handler()
            }
        }
        document.addEventListener('mousedown', handleClick)
        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    })
    return domNode
}

export default useOutsideClick