import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import COLORS from 'src/lib/colors';
import Row from '../Row';
import { Body } from '../UI';

const useStyles = makeStyles((theme) => ({
    container: {
        background: '#FFFFFF',
        borderRadius: '16px',

        margin: '18px 15px 16px',
    },
    recycleButton: {
        alignSelf: 'center',
        backgroundColor: 'black',
        color: 'white',
        lineHeight: '2.5rem',
        borderRadius: '2rem',
        width: '116px',
        height: '40px',
        fontWeight: 500,
        fontSize: '0.95rem',
        letterSpacing: '0',
        userSelect: 'none',
    },
    imgBox: {
        width: 100,
        height: 100,
        position: 'relative',
    },
    img: {
        height: '100%',
    },
    headerText: {
        color: COLORS.textBlack,
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '24px',
    },
    subText: {
        color: COLORS.textBlack,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '18px',
    },
    linkText: {
        color: COLORS.linkText,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '18px',
        cursor: 'pointer',
    },
    optionText: {
        color: COLORS.textBlack,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '18px',
    },
}));

interface IProps {
    setIsOpenBrowseFile: React.Dispatch<React.SetStateAction<boolean>>;
}
const options = ['Option1', 'Option2'];

const SelfServicesPrintingCard = ({ setIsOpenBrowseFile }: IProps) => {
    const classes = useStyles();
    const [showOptions, setShowOptions] = useState<boolean>(false);

    return (
        <div className={classes.container}>
            <Row
                gap="16px"
                direction="column"
                sx={{
                    p: '16px',
                }}
            >
                <>
                    <Row gap="22px">
                        <>
                            <Box className={classes.imgBox}>
                                <img
                                    src={require('../../assets/images/selfServicesPrinting.png')}
                                    height={20}
                                    alt="services"
                                    className={classes.img}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: -7,
                                        left: -7,
                                        bgcolor: COLORS.linkText,
                                        px: '8px',
                                        py: '2px',
                                        borderRadius: '100px',
                                    }}
                                >
                                    <Body
                                        size={10}
                                        weight="bold"
                                        color="textWhite"
                                        lineHeight={13}
                                    >
                                        NEW
                                    </Body>
                                </Box>
                            </Box>
                            <Row direction="column" gap="6px">
                                <>
                                    <Body
                                        size={18}
                                        weight="normal"
                                        color="textBlack"
                                    >
                                        Self-service printing
                                    </Body>
                                    <Body
                                        size={14}
                                        weight="light"
                                        color="textBlack"
                                    >
                                        Easily upload your files and print at
                                        your local Staples store.
                                    </Body>
                                </>
                            </Row>
                        </>
                    </Row>
                    <Row
                        gap="16px"
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <>
                            <ButtonBase
                                className={classes.recycleButton}
                                onClick={() => setIsOpenBrowseFile(true)}
                            >
                                Browser files
                            </ButtonBase>
                        </>
                    </Row>
                </>
            </Row>
            {/* <Row sx={{
                justifyContent: 'space-between',
                pt: '16px',
                px: '16px',
                pb: '13px',
            }}
                onClick={() => setShowOptions(!showOptions)}
            >
                <>
                    <Body size={14}>
                        More print options
                    </Body>
                    <img
                        src={require('../../assets/icons/icon-chevron-down.svg')}
                        height={20}
                        alt=""
                        className={`dropdown-icon ${showOptions ? "rotate" : ""}`}
                    />
                </>
            </Row>
            <AnimatePresence>
                {showOptions && (
                    <motion.div

                        initial={{ top: 0, opacity: 0 }}
                        animate={{ top: '1rem', opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        exit={{ top: 0, opacity: 0 }}
                    >
                        <Row direction='column' gap='5px' sx={{
                            px: '16px',
                            pb: '16px',
                        }}>
                            <>
                                {
                                    options.map(option => (
                                        <Body key={option} size={14}>
                                            {option}
                                        </Body>
                                    ))
                                }
                            </>
                        </Row>
                    </motion.div>
                )}
            </AnimatePresence> */}
        </div>
    );
};

export default SelfServicesPrintingCard;
