import React, { useContext, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonBase,
    Dialog,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import { useEventsReporter, useRouter, useUserInfo } from 'src/hooks';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { addShipmentTracking } from 'src/lib/api-client/shipment-services';
import clsx from 'clsx';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import ShippingInfoContext from 'src/lib/contexts/shipping-info-context';
import { useQuery } from 'react-query';
import { isListEnabled } from 'src/lib/api-client/lists';
import { useShipmentTracking } from 'src/lib/contexts/ShipmentTrackingContext';
import COLORS from '../lib/colors';
import ROUTES from '../lib/routes';
import FullWidthCard from './cards/full-width-card';
import LoadingOverlay from './loading-overlay';
import Brcd from './BarcodeScanner/Brcd';
import { Body } from './UI';
import ScanModal from './shipping-services/components/ScanModal';

const useStyles = makeStyles((theme) => ({
    homeShipping: {
        marginBottom: '20px',
        marginLeft: '16px',
        marginRight: '16px',
    },
    sectionTitle: {
        fontWeight: 700,
        fontSize: '1.125rem',
        marginBottom: '.85rem',
        marginTop: '1.75rem',
        marginLeft: '1.5rem',
    },
    container: {
        padding: '20px 16px 8px',
    },
    cardHeader: {
        padding: '20px 0 0 16px',
        display: 'flex',
        alignItems: 'center',
    },
    cardImage: {
        height: '60px',
        width: 'auto',
        [theme.breakpoints.down(410)]: {
            height: '60px',
        },
    },
    cardBody: {
        display: 'flex',
        padding: '16px 0px',
    },
    description: {
        margin: '5px 12px',
        color: COLORS.secondaryNeutralBlack,
    },
    helperTextDsc: {
        color: '#585858',
        fontSize: '16px',
        lineHeight: '22px',

        [theme.breakpoints.down(400)]: {
            fontSize: '16px',
        },
    },
    learnMore: {
        margin: '16px 0',
        display: 'inline-block',
        color: COLORS.brandCyan,
        fontSize: '14px',
        fontWeight: 500,
        '& span': {
            display: 'block',
        },
        '&:hover': {
            textDecoration: 'none',
        },
    },
    ctaBtn: {
        left: '15px',
        background: COLORS.primaryWhite,
        textTransform: 'none',
        margin: '5px 0px 0 0',
        width: '100%',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            color: COLORS.brandCyan,
            textAlign: 'center',
        },
        // fontWeight: 700,
    },
    ctaBorderBtn: {
        height: '2.5rem',
        borderRadius: '1.885rem',
        border: '1px solid',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '14px',
        width: '100%',
        '&:hover': {
            backgroundColor: COLORS.primaryWhite,
        },
        '&:active': {
            backgroundColor: COLORS.primaryWhite,
        },
        fontFamily: 'StaplesNormsBold',
        margin: '5px 0 0 0px',
    },
    cardFooter: {
        padding: '0 10px',
        borderTop: '1px solid #F2F2F2',
    },
    accordianRoot: {
        '&::before': {
            border: 0,
            background: '#ffffff',
        },
    },
    accordionSummary: {
        minHeight: '30px',
        padding: '0',
        '&.Mui-expanded': {
            minHeight: '30px',
        },
        '& p': {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
        },
        '& MuiAccordionSummary-expandIcon': {
            padding: '8px',
        },
    },
    ctaListItem: {
        padding: '0 15px 0 0',
    },
    ctaList: {
        display: 'flex',
        borderBottom: '1px solid #ccc',
        width: '100%',
        '& img': {
            marginRight: '20px',
            width: '22px',
            height: 'auto',
        },
    },
    ctaBox: {
        display: 'flex',
        alignItems: 'center',
    },
    detailsRoot: {
        flexDirection: 'column',
        padding: '0',
    },
    paper: { borderRadius: '15px', margin: '24px', padding: '10px 0 0 0' },
    dialogTitle: {
        fontSize: '18px',
        color: '#000',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        margin: '10px 0 10px 0px',
    },
    dialogWrapper: {
        padding: '10px 20px 30px 30px',
        textAlign: 'center',
        color: COLORS.secondaryNeutralBlack,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dialogMessage: {
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: 400,
    },
    closeIcon: {
        position: 'absolute',
        top: '-3px',
        right: '10px',
        backgroundColor: 'rgba(0,0,0,0)',
        minWidth: 'initial',
        padding: '0px',
    },
    closeButton: {
        position: 'relative',
        textAlign: 'center',
    },
    anotherPackage: {
        margin: '0px !important',
    },
    successIcon: {
        width: '50px',
        marginBottom: '5px',
    },
    startShipment: {
        height: '40px',
        width: '100%',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '10px',
        '& span': {
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '17px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        '&:hover': {
            opacity: 0.8,
        },
    },
    linkCta: {
        fontWeight: 500,
        marginTop: '15px',
        color: '#086DD2',
        fontSize: '14px',
        lineHeight: '22px',
        marginBottom: '32px',
    },
    learnMoreAlign: {
        color: '#ffffff',
        textDecoration: 'underline',
        fontSize: '14px',
    },
    holidayDisAlign: {
        background: '#CC0000',
        borderRadius: '15px 15px 0px 0px',
        height: '44px',
        margin: '0px 16px',
        display: 'flex',
        alignItems: 'center',
        color: '#ffffff',
        justifyContent: 'space-between',
        padding: '12px 16px',
        width: '100%',
    },
}));
/* eslint-disable max-len */
export function ShippingCard({ handleHolidatShipping }: any) {
    const classes = useStyles();
    const router = useRouter();
    const { isRewardShipperEnable } = useUserInfo();
    const { analyticsCustomEvent } = useEventsReporter();
    const { history, query } = useRouter();
    const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);

    const shippingURL =
        'https://shipping.staples.com/?utm_source=staplesconnect&utm_medium=referral&utm_campaign=app&utm_term=browser';
    const {
        updateFetchSenderInfo,
        updateReviewStage,
        openScanToTrack,
        updateOpenScanToTrack,
        updateShippingTab,
    } = useContext(ShippingInfoContext);

    const {
        handleClose,
        getShipmentTrackingDetails,
        scanMessage,
        scannerData,
        isLoading,
        setScannerData,
        isScanCompleted,
        trackingError,
    } = useShipmentTracking();

    const { data: HolidayShippingEnabled } = useQuery(
        ['HolidayShippingEnabled'],
        () => isListEnabled('HolidayShipping'),
        {
            refetchOnMount: true,
        }
    );
    console.log(
        '🚀 ~ ShippingCard ~ HolidayShippingEnabled:',
        HolidayShippingEnabled
    );

    const fullShippingService = () => {
        router.push({
            pathname: ROUTES.shippingServices,
        });
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'go to full shipping services',
            element_location: 'stores page',
        });
    };
    useEffect(() => {
        if (query?.step === 'scanToTrack') {
            setIsBarcodeScannerOpen(true);
            router.push(ROUTES.stores);
        }
    }, [query]);

    const handleOpenScanToTrack = () => {
        setIsBarcodeScannerOpen(true);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'scan to track',
            element_location: 'stores page',
        });
    };
    const scanToTrack = () => {
        if (
            isRewardShipperEnable &&
            localStorage.getItem('startShipment') !== 'true'
        ) {
            history.push({
                pathname: ROUTES.shippingServices,
                state: true,
                search: '?step=scanToTrack',
            });
            analyticsCustomEvent(eventMetricsMap.shipping, {
                click_text: 'scan to track',
                element_location: 'stores page',
            });
        } else {
            handleOpenScanToTrack();
        }
    };
    const handleSavedLabelShipment = (state: string) => {
        if (state) {
            updateShippingTab(state);
        }
        history.push(ROUTES.shippingServicesLabelShipment);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'labels and tracking',
            element_location: 'stores page',
        });
    };

    // const getShipmentTrackingDetails = async (trackingnumber: any) => {
    //     setIsLoading(true);
    //     const response = await addShipmentTracking(trackingnumber);
    //     setScannerData('');
    //     setIsLoading(false);
    //     if (response?.issuccess) {
    //         setTrackingError(true);
    //         if (response?.status === 'SUCCESS') {
    //             setTrackingError(false);
    //             setIsScanCompleted(true);
    //             setScanMessage({
    //                 title: 'Scan successful!',
    //                 description:
    //                     'Visit Labels and tracking to view its current ' +
    //                     'status or scan another package.',
    //             });
    //         } else if (response?.status === 'Fail') {
    //             setScanMessage({
    //                 title: 'Your package couldn’t be found',
    //                 description:
    //                     'We couldn’t find your package at this time. Please try again later.',
    //             });
    //         } else if (response?.status === 'DUPLICATE_TRACKING') {
    //             setScanMessage({
    //                 title: 'Duplicate label',
    //                 description:
    //                     'This label has been previously scanned ' +
    //                     'and is already in the system.',
    //             });
    //         } else if (response?.status === 'INVALID_TRACKING') {
    //             setScanMessage({
    //                 title: `Your package couldn't be found`,
    //                 description:
    //                     'Make sure you have a UPS label and ' +
    //                     'shipment number to start tracking.',
    //             });
    //         } else if (response?.status === 'NONUS_TRACKING') {
    //             setScanMessage({
    //                 title: 'Want to ship internationally?',
    //                 description:
    //                     'Bring your package to your local Staples store ' +
    //                     'and we’ll ship it anywhere in the world.',
    //             });
    //         } else {
    //             setScanMessage({
    //                 title: 'Label couldn’t be scanned',
    //                 description:
    //                     'We couldn’t scan your label at this time. Please try again later.',
    //             });
    //         }
    //         setIsScanCompleted(true);
    //         setScannerData('');
    //     } else {
    //         setTrackingError(true);
    //         setIsScanCompleted(true);
    //         setScanMessage({
    //             title: 'Your package couldn’t be found',
    //             description:
    //                 'We couldn’t find your package at this time. Please try again later.',
    //         });
    //     }
    // };
    const handleShippingGetQuote = () => {
        history.push({
            pathname: ROUTES.getQuoteShippingInformation,
            search: '?step=getEstimator',
        });
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'get a quote',
            element_location: 'stores page',
        });
    };

    useEffect(() => {
        if (scannerData) {
            getShipmentTrackingDetails(scannerData);
        }
    }, [scannerData]);

    const handleScan = () => {
        setIsBarcodeScannerOpen(true);
    };
    const handleStartShipment = () => {
        updateFetchSenderInfo(true);
        updateReviewStage(false);
        history.push({
            pathname: ROUTES.shippingServices,
            state: true,
            // search: '?step=createShipmentSenderInfo',
        });
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'start a shipment',
            element_location: 'stores page',
        });
    };
    if (isLoading) {
        return (
            <LoadingOverlay
                variant="wordmark"
                theme="transparent-light"
                open={isLoading}
            />
        );
    }
    return (
        <>
            {HolidayShippingEnabled?.isenabled ? (
                <ButtonBase
                    onClick={handleHolidatShipping}
                    style={{ width: '100%' }}
                >
                    <div className={classes.holidayDisAlign}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '14px',
                            }}
                        >
                            <img
                                src={require('src/assets/icons/giftIcon.svg')}
                                alt="giftIcon"
                            />
                            <Body size={16} weight="normal" lineHeight={20}>
                                Get it there by 12/24!
                            </Body>
                        </div>

                        <Body className={classes.learnMoreAlign}>
                            Learn more
                        </Body>
                    </div>
                </ButtonBase>
            ) : null}
            <FullWidthCard
                noPadding
                id="shipping-container"
                style={{
                    borderRadius: HolidayShippingEnabled?.isenabled
                        ? '0px 0px 15px 15px'
                        : '15px',
                }}
                className={classes.homeShipping}
            >
                <div className={classes.container}>
                    <div className={classes.cardHeader}>
                        <img
                            aria-hidden="true"
                            className={classes.cardImage}
                            src={require('src/assets/icons/img_shipping_icon.svg')}
                            alt="start shipping"
                        />
                        <Box>
                            <div className={classes.description}>
                                <Typography className={classes.helperTextDsc}>
                                    <div>
                                        Start a shipment, get a quote and speed
                                        up your time in store.
                                    </div>
                                </Typography>
                            </div>
                        </Box>
                    </div>
                    <div className={classes.cardBody}>
                        <ButtonBase
                            className={classes.ctaBorderBtn}
                            onClick={() => handleStartShipment()}
                        >
                            Start a shipment
                        </ButtonBase>
                        <ButtonBase
                            type="button"
                            className={classes.ctaBtn}
                            onClick={() => scanToTrack()}
                        >
                            <img
                                src={require('src/assets/icons/icon_scan_to_track.svg')}
                                alt="scan to track"
                            />
                            <span style={{ margin: '5px' }}>Scan to track</span>
                        </ButtonBase>
                    </div>
                    <div className={classes.cardFooter}>
                        <Accordion
                            elevation={0}
                            square
                            className={classes.accordianRoot}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionSummary}
                            >
                                <Typography>More actions</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.detailsRoot}>
                                <Grid item className={classes.ctaList}>
                                    <Box className={classes.ctaBox}>
                                        <img
                                            aria-hidden="true"
                                            src={require('../assets/icons/getQuote.svg')}
                                            alt="get a quote"
                                        />
                                        <ButtonBase
                                            className={classes.learnMore}
                                            onClick={handleShippingGetQuote}
                                        >
                                            <span
                                                className={classes.ctaListItem}
                                            >
                                                Get a quote
                                            </span>
                                        </ButtonBase>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    className={classes.ctaList}
                                    style={{ borderBottom: '0' }}
                                >
                                    <Box className={classes.ctaBox}>
                                        <img
                                            aria-hidden="true"
                                            src={require('../assets/icons/labels.svg')}
                                            alt="labels and tracking"
                                        />
                                        <ButtonBase
                                            className={classes.learnMore}
                                            onClick={() =>
                                                handleSavedLabelShipment(
                                                    'create'
                                                )
                                            }
                                        >
                                            <span
                                                className={classes.ctaListItem}
                                            >
                                                Labels and tracking
                                            </span>
                                        </ButtonBase>
                                    </Box>
                                </Grid>
                                {/* <Grid item className={classes.ctaList} style={{ borderBottom: '0' }}>
                                    <Box className={classes.ctaBox}>
                                        <img
                                            src={require("../assets/icons/contacts.svg")}
                                            alt="contacts"
                                        />
                                        <ButtonBase
                                            className={classes.learnMore}
                                            onClick={() => fullShippingService()}
                                        >
                                            <span className={classes.ctaListItem}>Saved contacts</span>
                                        </ButtonBase>
                                    </Box>
                                </Grid> */}
                                <Grid item style={{ textAlign: 'center' }}>
                                    <Box>
                                        <ButtonBase
                                            className={classes.learnMore}
                                            onClick={() =>
                                                fullShippingService()
                                            }
                                        >
                                            <span
                                                className={classes.ctaListItem}
                                                style={{
                                                    fontWeight: '700',
                                                    padding: '0',
                                                }}
                                            >
                                                Go to full shipping services
                                            </span>
                                        </ButtonBase>
                                    </Box>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </FullWidthCard>
            {isBarcodeScannerOpen && (
                <Brcd
                    isScannerOpen={isBarcodeScannerOpen}
                    shippingScan
                    setScannerData={setScannerData}
                    onClose={() => {
                        setIsBarcodeScannerOpen(false);
                        handleClose();
                    }}
                />
            )}
            {isScanCompleted && (
                <ScanModal
                    handleSavedLabelShipment={handleSavedLabelShipment}
                    handleScan={handleScan}
                />
            )}
        </>
    );
}
export default ShippingCard;
