/* eslint-disable max-len */
import React from 'react';

function IconAddDocs() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="63"
            height="70"
            viewBox="0 0 63 70"
            fill="none"
        >
            <path
                d="M56.9314 36.4996C53.2264 32.7946 48.2971 30.7531 43.0544 30.7531C37.8117 30.7531 32.8854 32.7946 29.1774 36.4996C25.4724 40.2046 23.431 45.1339 23.431 50.3766C23.431 52.1837 23.6799 53.9527 24.1485 55.6485H7.90795C5.80795 55.6485 4.10042 53.941 4.10042 51.841V7.90795C4.10042 5.80795 5.80795 4.10042 7.90795 4.10042H41.59C43.69 4.10042 45.3975 5.80795 45.3975 7.90795V26.9456H49.4979V7.90795C49.4979 3.54686 45.951 0 41.59 0H7.90795C3.54686 0 0 3.54686 0 7.90795V51.841C0 56.2021 3.54686 59.749 7.90795 59.749H25.8063C26.6967 61.3833 27.8243 62.9004 29.1774 64.2536C32.8824 67.9586 37.8117 70 43.0544 70C48.2971 70 53.2234 67.9586 56.9314 64.2536C60.6364 60.5485 62.6778 55.6192 62.6778 50.3766C62.6778 45.1339 60.6364 40.2075 56.9314 36.4996ZM54.0318 61.354C51.1 64.2858 47.2017 65.8996 43.0544 65.8996C38.9071 65.8996 35.0088 64.2858 32.077 61.354C29.1452 58.4222 27.5314 54.5238 27.5314 50.3766C27.5314 46.2293 29.1452 42.331 32.077 39.3992C35.0088 36.4674 38.9071 34.8536 43.0544 34.8536C47.2017 34.8536 51.1 36.4674 54.0318 39.3992C56.9636 42.331 58.5774 46.2293 58.5774 50.3766C58.5774 54.5238 56.9636 58.4222 54.0318 61.354Z"
                fill="white"
            />
            <path
                d="M49.8875 48.1594H45.1046V43.3766C45.1046 42.2431 44.1879 41.3264 43.0544 41.3264C41.9209 41.3264 41.0042 42.2431 41.0042 43.3766V48.1594H36.2214C35.0879 48.1594 34.1711 49.0761 34.1711 50.2096C34.1711 51.3431 35.0879 52.2598 36.2214 52.2598H41.0042V57.0427C41.0042 58.1761 41.9209 59.0929 43.0544 59.0929C44.1879 59.0929 45.1046 58.1761 45.1046 57.0427V52.2598H49.8875C51.0209 52.2598 51.9377 51.3431 51.9377 50.2096C51.9377 49.0761 51.0209 48.1594 49.8875 48.1594Z"
                fill="white"
            />
            <path
                d="M34.5226 12.723H22.4996C21.3661 12.723 20.4493 13.6398 20.4493 14.7732C20.4493 15.9067 21.3661 16.8234 22.4996 16.8234H34.5226C35.656 16.8234 36.5728 15.9067 36.5728 14.7732C36.5728 13.6398 35.656 12.723 34.5226 12.723Z"
                fill="white"
            />
            <path
                d="M14.0205 30.2962C12.8871 30.2962 11.9703 31.213 11.9703 32.3464C11.9703 33.4799 12.8871 34.3967 14.0205 34.3967H18.1063C19.2398 34.3967 20.1565 33.4799 20.1565 32.3464C20.1565 31.213 19.2398 30.2962 18.1063 30.2962H14.0205Z"
                fill="white"
            />
            <path
                d="M36.5728 23.5599C36.5728 22.4264 35.6561 21.5096 34.5226 21.5096H14.0205C12.8871 21.5096 11.9703 22.4264 11.9703 23.5599C11.9703 24.6933 12.8871 25.6101 14.0205 25.6101H34.5226C35.6561 25.6101 36.5728 24.6933 36.5728 23.5599Z"
                fill="white"
            />
        </svg>
    );
}

export default IconAddDocs;
