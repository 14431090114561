import { Box } from '@material-ui/core';
import React from 'react';
import Row from 'src/components/Row';
import { Body } from 'src/components/UI';

interface IEmptyStateProps {
    title: string;
    iconComponent: JSX.Element;
}

function FIleEmptyState({ title, iconComponent }: IEmptyStateProps) {
    return (
        <Row
            gap="10px"
            direction="column"
            sx={{
                alignItems: 'center',
                mt: '30px',
            }}
        >
            <>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: '#E6E6E6',
                        width: 120,
                        height: 120,
                        borderRadius: '50%',
                    }}
                >
                    {iconComponent}
                </Box>
                <Body size={16} weight="light" lineHeight={20} color="textGray">
                    {title}
                </Body>
            </>
        </Row>
    );
}

export default FIleEmptyState;
