import { Box, Dialog, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import COLORS from 'src/lib/colors';
import { Heading, Button } from './UI';
import Row from './Row';

const useStyles = makeStyles((theme) => ({
    topHeader: {
        height: 60,
        backgroundColor: COLORS.brandRed,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    leftButton: {
        position: 'absolute',
        left: 10,
        top: 22,
    },
    rightButton: {
        position: 'absolute',
        right: 12,
        top: 20,
    },
    uploadButton: {
        padding: '13px 16px',
        backgroundColor: '#000',
        borderRadius: '100px',
        display: 'flex',
        alignItems: 'center',
    },
    cancelButton: {
        cursor: 'pointer',
    },
}));

interface IDialogBoxProps {
    header: string;
    onBack: () => void;
    children: JSX.Element;
    open: boolean;
    noPedding?: boolean;
    isLeftButton?: boolean;
    isRightButton?: boolean;
}

function DialogBox({
    header,
    children,
    open,
    onBack,
    noPedding,
    isLeftButton,
    isRightButton,
}: IDialogBoxProps) {
    const classes = useStyles();

    return (
        <Dialog open={open} fullScreen>
            <Box
                sx={{
                    height: '100%',
                    position: 'relative',
                }}
            >
                <Box className={classes.topHeader}>
                    {isLeftButton && (
                        <Button
                            variant="text"
                            className={classes.leftButton}
                            fontWeight="bold"
                            onClick={onBack}
                        >
                            <img
                                src={require('../assets/icons/right-arrow.svg')}
                                alt="arrow icon"
                            />
                        </Button>
                    )}
                    <Heading
                        type="h3"
                        weight="bold"
                        size={20}
                        color="textWhite"
                    >
                        {header}
                    </Heading>
                    {isRightButton && (
                        <Button
                            variant="text"
                            className={classes.rightButton}
                            fontWeight="bold"
                            onClick={onBack}
                        >
                            <Close
                                style={{
                                    color: 'white',
                                    fontSize: 20,
                                }}
                            />
                        </Button>
                    )}
                </Box>
                <Row
                    direction="column"
                    sx={{
                        height: 'calc(100% - 60px)',
                        p: noPedding ? '0px' : '16px',
                        bgcolor: COLORS.whiteSmoke,
                    }}
                >
                    {children}
                </Row>
            </Box>
        </Dialog>
    );
}

export default DialogBox;
