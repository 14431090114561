import { Box, BoxProps } from '@material-ui/core'
import React from 'react'

interface IProps extends BoxProps {
    children: JSX.Element;
    direction?: 'row' | 'column';
    gap?: string;
}

const Row = ({ children, direction = 'row', gap, ...rest }: IProps) => {
    const { sx, ...restProps } = rest
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: direction,
            ...(gap && { gap }),
            ...sx,
        }} {...restProps}>
            {children}
        </Box>
    )
}

export default Row