import Quagga, {
    QuaggaJSResultObject,
    QuaggaJSResultObject_CodeResult,
} from '@ericblade/quagga2';
import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'src/components/loading-overlay';
import COLORS from 'src/lib/colors';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        width: '28px',
        zIndex: 100,
        color: 'white',
    },
    scannerContainer: {
        //   position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        backgroundColor: 'black',
        '& > video': {
            height: '100%',
        },
        '& > div:last-child': {
            display: 'none !important',
        },
        //   border: '3px solid blue',
    },
    viewFinder: {
        position: 'absolute',
        height: '25vh',
        width: '80vw',
        border: '5px solid white',
        borderRadius: '40px',
        borderStyle: 'none solid',
    },
    cameraCanvas: {
        position: 'absolute',
        top: 60,
        height: '93%',
        width: '100%',
    },
    dialog: {
        maxWidth: '375px',
        borderRadius: '1rem',
    },
    contentWrapper: {
        padding: '.75rem 1.5rem',
        paddingTop: 0,
    },
    title: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        letterSpacing: '-0.39pt',
        textAlign: 'center',
    },
    desc: {
        fontSize: '.85rem',
        textAlign: 'center',
        margin: '1rem 0',
    },
    buttons: {
        padding: '1rem 0 .75rem',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        [theme.breakpoints.down(420)]: {
            flexDirection: 'column',
        },
    },
    tryAgainBtn: {
        height: '2.5rem',
        padding: '.5rem 1.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        [theme.breakpoints.down(420)]: {
            marginBottom: '.5rem',
        },
    },
    errorDialogCloseButton: {
        marginTop: '1rem',
        marginRight: '1rem',
        width: '28px',
        alignSelf: 'flex-end',
    },
}));

function trimStart(str: string, trimChar: string): string {
    let index = 0;
    for (; index < str.length; index += 1) {
        if (str.charAt(index) !== trimChar) {
            break;
        }
    }
    return str.slice(index);
}

function getMedian(arr: number[]) {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) {
        return arr[half];
    }
    return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(
    decodedCodes: QuaggaJSResultObject_CodeResult['decodedCodes']
) {
    const errors: number[] = decodedCodes
        .filter((x) => x.error !== undefined)
        .map((x) => x.error) as number[];

    const medianOfErrors = getMedian(errors);
    return medianOfErrors;
}

// Verify UPC check digit
function verifyUpcCheckDigit(scannedCode: string): boolean {
    const scannedCheckDigit = Number(scannedCode[scannedCode.length - 1]);
    const code = scannedCode
        .slice(0, scannedCode.length - 1)
        .split('')
        .map(Number);
    const [evenSum, oddSum]: number[] = code.reduce(
        (accumulator: number[], currentValue: number, index) => {
            const newValue = [...accumulator];
            if ((index + 1) % 2 === 0) {
                newValue[0] += currentValue;
            } else {
                newValue[1] += currentValue;
            }
            return newValue;
        },
        [0, 0]
    );
    let calculatedCheckDigit = (oddSum * 3 + evenSum) % 10;
    calculatedCheckDigit =
        calculatedCheckDigit > 0
            ? 10 - calculatedCheckDigit
            : calculatedCheckDigit;
    return calculatedCheckDigit === scannedCheckDigit;
}

interface IProps {
    onDetected: (code: QuaggaJSResultObject) => void;
}

function ScanQrCodeBox({ onDetected }: IProps) {
    const classes = useStyles();
    const scannerRef = useRef<HTMLDivElement | null>(null);
    const [showLoader, setShowLoader] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const { fromPath, setFromPath, end } = useProductBrowseContext();

    const handleOnDetected = useCallback((result: QuaggaJSResultObject) => {
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        const codeType = result.codeResult.format;
        // if Quagga is at least 75% certain that it read correctly, then accept the code.
        if (
            err < 0.25 &&
            result.codeResult.code &&
            (codeType === 'code_128' ||
                verifyUpcCheckDigit(result.codeResult.code))
        ) {
            let verifiedCode =
                codeType === 'code_128'
                    ? result.codeResult.code
                    : trimStart(result.codeResult.code, '0');

            if (codeType === 'upc_a') {
                verifiedCode = `00${verifiedCode}`;
            }

            setShowLoader(true);
            console.log('verifiedCode', verifiedCode);
            if (verifiedCode) {
                Quagga.stop();
                // onClose();
                // setFromPath(ROUTES.productDetailsPage === location.pathname ? fromPath : location.pathname)
                // push(`${ROUTES.productDetailsPage}?barcode=${verifiedCode}&isBarCodeScan=true`,
                //     setPreviousPath(location, fromPath));
                // end();
            }
            console.log({ verifiedCode });
            setShowLoader(false);
        }
    }, []);
    // useEffect(() => {
    //     // Initialize Quagga when the component mounts
    //     if (scannerRef.current) {
    //         Quagga.init(
    //             {
    //                 inputStream: {
    //                     type: 'LiveStream',
    //                     target: scannerRef.current, // Div to show the camera stream
    //                     constraints: {
    //                         facingMode: 'environment', // Use rear camera
    //                     },
    //                 },
    //                 decoder: {
    //                     readers: ['code_128_reader'], // Enable QR code reader
    //                 },
    //             },
    //             (err) => {
    //                 if (err) {
    //                     console.error('Error initializing Quagga:', err);
    //                     return;
    //                 }
    //                 Quagga.start();
    //             }
    //         );

    //         // Add event listener for QR code detection
    //         Quagga.onDetected((data) => {
    //             console.log('Detected QR code:', data);
    //             // if (onDetected) {
    //             //   onDetected(data.codeResult.code);
    //             // }
    //         });
    //     }

    //     // Cleanup Quagga on component unmount
    //     return () => {
    //         Quagga.stop();
    //     };
    // }, []);
    useEffect(() => {
        if (scannerRef?.current) {
            setShowLoader(true);
            console.log('verifiedCode', scannerRef?.current);
            Quagga.init(
                {
                    inputStream: {
                        name: 'Live',
                        type: 'LiveStream',
                        target: scannerRef?.current,
                        constraints: {
                            height: {
                                ideal: 4096,
                            },
                            width: { ideal: 2160 },
                        },
                        area: {
                            // defines rectangle of the detection/localization area
                            top: '37.5%', // top offset
                            right: '10%', // right offset
                            left: '10%', // left offset
                            bottom: '37.5%', // bottom offset
                        },
                        singleChannel: false,
                    },
                    decoder: {
                        readers: ['upc_reader', 'code_128_reader'],
                        debug: {
                            drawBoundingBox: true,
                            showFrequency: true,
                            drawScanline: true,
                            showPattern: true,
                        },
                        multiple: false,
                    },
                    locate: false,
                },
                (err: any) => {
                    setShowLoader(false);
                    if (err) {
                        return;
                    }
                    Quagga.start();
                }
            );

            Quagga.onDetected(handleOnDetected);
        }
        return () => {
            Quagga.offDetected();
            Quagga.stop();
        };
    }, [scannerRef?.current]);
    return (
        <>
            <div className={classes.scannerContainer} ref={scannerRef}>
                <div className={classes.viewFinder} />
                <canvas
                    className={`${classes.cameraCanvas} drawingBuffer`}
                    height="100"
                    width="100"
                />
            </div>
            <LoadingOverlay
                variant="wordmark"
                theme="light"
                open={showLoader}
            />
        </>
    );
}

export default ScanQrCodeBox;
