import React from 'react';
import { Box, Button, ButtonBase, Dialog, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Body, Heading } from 'src/components/UI';
import { useShipmentTracking } from 'src/lib/contexts/ShipmentTrackingContext';
import COLORS from 'src/lib/colors';
import useStyles from '../styles/shipmentServicesStyles';
import scanModalTopImg from '../../../assets/screenshots/scanModal.png';

interface ScanModalProps {
    handleSavedLabelShipment: (value: string) => void;
    handleScan: (value: string) => void;
}

function ScanModal({ handleScan, handleSavedLabelShipment }: ScanModalProps) {
    const classes = useStyles();
    const { handleClose, scanMessage, isScanCompleted, trackingError } =
        useShipmentTracking();

    return (
        <>
            <Dialog
                open={isScanCompleted}
                classes={{
                    paper: classes.paper,
                }}
            >
                {!trackingError && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                    >
                        <img src={scanModalTopImg} alt="img" />
                    </Box>
                )}
                <div className={classes.closeButton}>
                    <Button
                        onClick={handleClose}
                        className={classes.closeIcon}
                        id="close-button"
                    >
                        <img
                            src={require('../../../assets/icons/iconCloseBlack.svg')}
                            alt="close icon"
                        />
                    </Button>
                </div>
                <div className={classes.dialogWrapper}>
                    <Heading
                        type="h3"
                        color="textBlack"
                        size={18}
                        lineHeight={22}
                    >
                        {scanMessage.title}
                    </Heading>
                    {!trackingError && (
                        <img
                            className={classes.successIcon}
                            src={require('../../../assets/icons/tickCirle.svg')}
                            alt="scan successful"
                        />
                    )}
                    <Box
                        sx={{
                            display: 'ruby',
                            alignItems: 'center',
                            marginBottom: '30px',
                        }}
                    >
                        <Typography
                            style={{
                                lineHeight: '18px',
                                fontSize: 14,
                                fontWeight: 400,
                            }}
                        >
                            {scanMessage.description1.length > 0 &&
                                scanMessage.description1}
                            {scanMessage.point.length > 0 && (
                                <span
                                    style={{
                                        color: COLORS.purple,
                                        fontWeight: 700,
                                    }}
                                >
                                    &nbsp; {scanMessage.point} points &nbsp;
                                </span>
                            )}
                        </Typography>
                        <Typography
                            style={{
                                lineHeight: '18px',
                                fontSize: 14,
                                fontWeight: 400,
                                width: '77%',
                            }}
                        >
                            {scanMessage.description}
                        </Typography>
                    </Box>
                    <ButtonBase
                        className={classes.startShipment}
                        onClick={() => {
                            handleSavedLabelShipment('shipped');
                            handleClose();
                        }}
                    >
                        <span>Go to labels and tracking</span>
                    </ButtonBase>

                    <ButtonBase
                        className={clsx(
                            classes.linkCta,
                            classes.anotherPackage
                        )}
                        onClick={() => handleScan('scan_successful_modal')}
                    >
                        <span>Scan another package </span>
                    </ButtonBase>
                </div>
            </Dialog>
        </>
    );
}

export default ScanModal;
