import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconPhoto({
    width = 16,
    height = 16,
    fillColor = 'white',
}: IconInterface) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_1212_1179)">
                <path
                    d="M13.7732 0H2.2268C0.998763 0 0 0.998763 0 2.2268V13.7732C0 15.0012 0.998763 16 2.2268 16H13.7732C15.0012 16 16 15.0012 16 13.7732V2.2268C16 0.998763 15.0012 0 13.7732 0ZM2.2268 1.15464H13.7732C14.3645 1.15464 14.8454 1.63546 14.8454 2.2268V9.89113L13.699 8.74474C12.8948 7.94062 11.5868 7.94062 10.7835 8.74474L10.0709 9.45732L9.56371 9.96454L6.79835 7.19917C5.99423 6.39505 4.68619 6.39505 3.88206 7.19917L1.15464 9.92742V2.2268C1.15464 1.63546 1.63546 1.15464 2.2268 1.15464ZM13.7732 14.8454H2.2268C1.63546 14.8454 1.15464 14.3645 1.15464 13.7732V11.5596L4.69773 8.01649C5.05155 7.66268 5.62722 7.66268 5.98103 8.01649L8.74639 10.7819L8.18639 11.3419C7.96124 11.567 7.96124 11.9332 8.18639 12.1583C8.29938 12.2713 8.44701 12.3274 8.59464 12.3274C8.74227 12.3274 8.8899 12.2713 9.00289 12.1583L10.8866 10.2746L11.6 9.56124C11.9538 9.20742 12.5295 9.20742 12.8833 9.56124L14.8454 11.5241V13.7732C14.8454 14.3645 14.3645 14.8454 13.7732 14.8454Z"
                    fill="white"
                />
                <path
                    d="M9.23962 6.15507C9.92285 6.15507 10.4767 5.6012 10.4767 4.91796C10.4767 4.23472 9.92285 3.68085 9.23962 3.68085C8.55638 3.68085 8.0025 4.23472 8.0025 4.91796C8.0025 5.6012 8.55638 6.15507 9.23962 6.15507Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1212_1179">
                    <rect width={width} height={height} fill={fillColor} />
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconPhoto;
